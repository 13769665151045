@import '@assets/core.style';

@mixin loader {
    content: '';
    display: block;
    animation: cell-loading 2s infinite;
    background-size: 500%;
    background-repeat: no-repeat;
    transition: background 800ms, opacity 800ms;
    border-radius: rem(10px);
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0;
}

.progress-panel {
    border-radius: rem(8px);
    height: 100%;
    filter: drop-shadow(0 rem(4px) rem(90px) rgba(0, 0, 0, 0.2));

    &__header {
        padding: rem(16px);
        border-radius: rem(8px) rem(8px) 0 0;
        height: rem(80px);

        @include themes(
            (
                'dark': (
                    'background-color': $dark-gray-3,
                ),
                'light': (
                    'background': $light-gray-0,
                ),
            )
        );
    }

    &__header-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: rem(18px);
    }

    &__navigation {
        display: flex;
    }

    &__arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(24px);
        height: rem(24px);
        border-radius: 50%;
        margin-left: rem(8px);
        color: #fff;
        cursor: pointer;
        transition: background-color 300ms;

        &:hover {
            @include themes(
                (
                    'dark': (
                        'background-color': $dark-gray-4,
                    ),
                    'light': (
                        'background-color': #d2d8e2,
                    ),
                )
            );
        }
    }

    &__arrow-icon-container {
        padding: rem(2px);
    }

    &__arrow-icon {
        position: relative;
        stroke: currentColor;
        z-index: 2;

        path {
            @include themes(
                (
                    'light': (
                        stroke: $light-gray-90,
                    ),
                )
            );
        }
    }

    &__title {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: rem(20px);
        line-height: rem(20px);
        height: rem(40px);
        overflow: hidden;
        display: flex;
        align-items: center;
        position: relative;

        @include themes(
            (
                'dark': (
                    'color': #d7e3f3,
                ),
                'light': (
                    'color': $light-gray-90,
                ),
            )
        );

        &:before {
            @include loader();

            width: 60%;
            height: rem(20px);
            background-image: linear-gradient(
                135deg,
                transparent 0%,
                rgba($dark-gray-3, 0.4) 50%,
                transparent 60%
            );

            @include themes(
                (
                    'dark': (
                        'background-color': $dark-gray-2,
                    ),
                    'light': (
                        'background-color': $light-gray-20,
                    ),
                )
            );
        }

        &--loading {
            &:before {
                opacity: 1;
                pointer-events: none;
            }
        }
    }

    &__sup-title {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: rem(14px);
        line-height: rem(14px);
        height: rem(18px);
        position: relative;

        @include themes(
            (
                'dark': (
                    'color': rgba(#97adca, 0.7),
                ),
                'light': (
                    'color': $light-gray-40,
                ),
            )
        );

        &:before {
            @include loader();

            width: 40%;
            height: rem(14px);
            background-image: linear-gradient(
                135deg,
                transparent 0%,
                rgba($dark-gray-3, 0.4) 50%,
                transparent 60%
            );

            @include themes(
                (
                    'dark': (
                        'background-color': $dark-gray-2,
                    ),
                    'light': (
                        'background-color': $light-gray-20,
                    ),
                )
            );
        }

        &--loading {
            &:before {
                opacity: 1;
                pointer-events: none;
            }
        }
    }

    &__content {
        padding: rem(16px);
        border-radius: 0 0 rem(8px) rem(8px);
        height: calc(100% - rem(80px));
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include themes(
            (
                'dark': (
                    'background-color': $dark-gray-2,
                ),
                'light': (
                    'background': #fff,
                    'box-shadow': 0 rem(8px) rem(20px) rgba(176, 195, 208, 0.24),
                ),
            )
        );
    }
}

.progress-panel-item {
    height: rem(32px);
    position: relative;

    &:before {
        @include loader();

        width: 100%;
        height: rem(25px);
        top: rem(10px);
        background-image: linear-gradient(
            135deg,
            transparent 0%,
            rgba($dark-gray-4, 0.4) 50%,
            transparent 60%
        );

        @include themes(
            (
                'dark': (
                    'background-color': $dark-gray-3,
                ),
                'light': (
                    'background-color': $light-gray-10,
                ),
            )
        );
    }

    &--loading {
        &:before {
            opacity: 1;
            pointer-events: none;
        }
    }

    &__top {
        display: flex;
        justify-content: space-between;
        margin-bottom: rem(8px);
        line-height: rem(18px);
    }

    &__title {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: rem(14px);

        @include themes(
            (
                'dark': (
                    'color': rgba(#97adca, 0.7),
                ),
                'light': (
                    'color': $light-gray-90,
                ),
            )
        );
    }

    &__percents {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: rem(12px);

        @include themes(
            (
                'dark': (
                    'color': #97adca,
                ),
                'light': (
                    'color': $light-gray-70,
                ),
            )
        );
    }

    &__bar-wrap {
        width: 100%;
        height: rem(6px);
        border-radius: rem(8px);

        @include themes(
            (
                'dark': (
                    'background-color': #1e2734,
                ),
                'light': (
                    'background-color': #edf1f8,
                ),
            )
        );
    }

    &__bar {
        height: 100%;
        background: linear-gradient(90deg, $dark-red-1 0%, #bd67e6 100.22%);
        border-radius: rem(4px);
        transition: width 300ms;
    }
}

.progress-circle {
    position: absolute;
    z-index: 1;

    @include themes(
        (
            'dark': (
                'background-color': $dark-gray-3,
            ),
            'light': (
                'background-color': $light-gray-0,
            ),
        )
    );
}

@include timer('.progress-circle', 5, rem(24px), $dark-gray-5);
