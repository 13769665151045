@import '@styles';

.RestaurantsMenuPopup {
    $gap: 12px;
    $header: rem(90px);

    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    height: 55vh;

    @include themes(
        (
            'dark': (
                'color': $body-text,
            ),
            'light': (
                'color': $dark-gray-1,
            ),
        )
    );

    &__content {
        height: calc(100% - #{$header} - #{rem($gap)});
        margin-top: rem($gap);
    }

    &__group {
        & + & {
            margin-top: rem(36px);
        }
    }

    &__header-row {
        display: flex;
        justify-content: space-between;

        &.has-divider {
            border-bottom: rem(1px) solid;

            @include themes(
                (
                    'dark': (
                        'border-color': #fff,
                    ),
                    'light': (
                        'border-color': $dark-gray-6,
                    ),
                )
            );
        }
    }

    &__header {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 1;
        height: $header;
        margin-top: rem(-$gap);
    }

    &__list {
        &.has-gap {
            padding-top: rem($gap * 2);
        }
    }

    &__item {
        font-size: rem(16px);
        line-height: 1.2;
        padding: rem($gap);
        cursor: pointer;
        border-bottom: rem(1px) solid $dark-gray-6;

        &:hover,
        &.is-selected {
            background-color: $dark-blue-1;
            color: $body-text;
        }
    }

    &__search {
        margin-top: rem($gap);
        width: 100%;
        max-width: 55%;
        height: rem(40px);

        & > .search {
            display: flex;
            height: 100%;
            align-items: center;
        }
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__no-data {
        padding: rem($gap);
    }

    &__manager {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}
