@import '@assets/core.style';

.confirmationPopup-wr {
    position: relative;
    height: 100vh;
}

.confirmationPopup {
    width: rem(550px);
    z-index: 101;
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: rem(3px);

    @include themes(
        (
            'dark': (
                'background': #1d202a,
            ),
            'light': (
                'background': #fafafa,
            ),
        )
    );

    &__header {
        text-align: right;
        padding-right: rem(20px);

        @include themes(
            (
                'dark': (
                    'background': #0d0f15,
                ),
                'light': (
                    'background': #fff,
                ),
            )
        );
    }

    &__close-popup {
        cursor: pointer;

        @include themes(
            (
                'dark': (
                    'fill': #fff,
                ),
                'light': (
                    'fill': #666,
                ),
            )
        );
    }

    &__content {
        padding-top: rem(50px);
        font-family: 'Work Sans', sans-serif;
        text-align: center;
        font-style: normal;
        font-weight: 400;
        font-size: rem(22px);
        line-height: rem(18px);

        @include themes(
            (
                'light': (
                    'color': rgba(#3a414c, 0.7),
                ),
            )
        );
    }

    &__btn-container {
        width: rem(350px);
        margin: auto;
        display: flex;
        justify-content: space-between;
        padding-top: rem(50px);
        padding-bottom: rem(50px);

        .Button {
            @include themes(
                (
                    'light': (
                        'color': rgba(#3a414c, 0.7),
                    ),
                )
            );

            &:hover {
                @include themes(
                    (
                        'light': (
                            'color': #fff,
                        ),
                    )
                );
            }

            &--blue {
                @include themes(
                    (
                        'light': (
                            'color': #fff,
                        ),
                    )
                );
            }
        }
    }

    &__ok {
        width: rem(130px);
        height: rem(30px);
        background-color: $dark-blue-1;
        cursor: pointer;
        transition: background-color 0.1s linear;

        &:hover {
            background: #1477ff;
        }
    }
}
