@import '@styles';

.sidebar-option {
    $root: &;

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: rem(8px) rem(16px);
        cursor: pointer;
        height: rem(43px);
        position: relative;

        @include themes(
            (
                'dark': (
                    'background-color': #2e3e56,
                ),
                'light': (
                    'background-color': $dark-blue-1,
                ),
            )
        );

        &--active {
            background-color: #4794ff;
        }
    }

    &__title {
        color: #fff;
        font-size: rem(16px);
        font-family: 'Nunito', sans-serif;
        height: rem(24px);
        font-weight: 600;
        font-style: normal;
        width: rem(170px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__title-input {
        color: #fff;
        border-bottom: rem(1px) solid;
        background: none;
        font-size: rem(16px);
        font-weight: 600;
        line-height: rem(24px);
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        height: rem(24px);
        box-sizing: border-box;

        &--is-master {
            border-bottom: none;
        }

        &::placeholder {
            font-size: rem(16px);
            font-weight: 600;
            line-height: rem(24px);
            font-family: 'Nunito', sans-serif;
            font-style: normal;
            @include themes(
                (
                    'dark': (
                        'color': darken(#fff, 20%),
                    ),
                    'light': (
                        'color': #fff,
                    ),
                )
            );
        }
    }

    &__btns {
        display: flex;
        align-items: center;
    }

    &__btn {
        display: flex;
        margin-left: rem(8px);
        padding: rem(4px);
        cursor: pointer;

        svg {
            width: rem(20px);
        }

        path {
            transition: stroke 300ms;
        }
    }

    &__arrow {
        transition: transform 300ms;

        &--opened {
            transform: rotate(180deg);
        }

        path {
            transition: stroke 300ms, fill 300ms;
        }
    }

    &__inner {
        display: flex;
        flex-wrap: wrap;
        padding: rem(8px);
        overflow: hidden;
        transition: height 300ms, opacity 300ms;

        &--collapsed {
            height: 0;
            padding: 0;
        }

        &:after {
            content: '';
            flex: auto;
        }
    }

    &__no-data-msg {
        width: 100%;
        height: rem(30px);
        display: flex;
        align-items: center;
        justify-content: center;

        @include themes(
            (
                'light': (
                    'color': $dark-gray-0,
                ),
            )
        );
    }
}
