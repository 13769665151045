@import '@styles';

.multiple-input {
    position: relative;
    width: 100%;
    border-radius: rem(2px);
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;

    $root: &;

    &__word-only {
        font-weight: 400;
        font-size: rem(14px);
        line-height: rem(18px);
        color: #6aa8ff;
        border-radius: rem(30px);
        background: rgba($dark-blue-1, 0.1);
        margin-left: rem(8px);
        padding: rem(1px) rem(6px);
        margin-top: rem(8px);
    }

    &__word-container {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    &--small {
        #{$root}__error {
            top: rem(-12px);
            font-size: rem(10px);
        }

        #{$root}__scrollbar-outer {
            height: rem(120px);

            &-readOnly {
                min-height: rem(140px);
            }
        }
    }

    &--medium {
        #{$root}__scrollbar-outer {
            height: rem(160px);

            &-readOnly {
                min-height: rem(180px);
            }
        }
    }

    &__field {
        width: 100%;
        height: rem(40px);
        border-radius: rem(8px);
        margin-bottom: rem(8px);
        padding: rem(8px);
        outline: none;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: rem(14px);
        line-height: rem(24px);
        transition: color 150ms ease, $base-background-transition;
        background-color: #fff;
        color: $light-gray-70;

        @include themes(
            (
                'dark': (
                    'border': 0,
                ),
                'light': (
                    'border': rem(2px) solid #b6b6b6,
                ),
            )
        );

        &::placeholder {
            transition: $base-background-transition;

            @include themes(
                (
                    'dark': (
                        color: $dark-gray-5,
                    ),
                    'light': (
                        color: $light-gray-40,
                    ),
                )
            );
        }

        &--error {
            border: rem(1px) solid #c50d0d;
        }

        &--disabled {
            opacity: 0.5;
            cursor: not-allowed;

            &:hover {
                opacity: 1;
            }
        }
    }

    &__field-icon {
        position: absolute;
        top: rem(20px);
        right: rem(10px);
        color: $light-gray-70;
        transform: translateY(-50%);
        width: rem(20px);
        height: rem(20px);
    }

    &__clear {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: rem(20px);
        right: rem(8px);
        transform: translateY(-50%);
        cursor: pointer;
        width: rem(15px);
        height: rem(15px);

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: rem(12px);
            height: rem(2px);
            background-color: #fff;
        }

        &:before {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }

        &--visible {
            opacity: 1;
            visibility: visible;
        }
    }

    &__scrollbar-outer {
        width: 100%;
        height: rem(190px);
    }

    &__word {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: rem(4px) rem(24px) rem(4px) rem(8px);
        font-size: rem(14px);
        line-height: rem(16px);
        min-height: 25px;
        border-radius: rem(8px);
        transition: color 150ms ease, background-color 150ms ease;
        margin-bottom: rem(4px);

        @include themes(
            (
                'dark': (
                    'color': #fff,
                    'background-color': #2e3c4f,
                ),
                'light': (
                    'background-color': rgba(#2e3c4f, 0.1),
                    'color': #000,
                ),
            )
        );

        &:hover {
            #{$root}__word-remove {
                opacity: 1;
            }

            @include themes(
                (
                    'dark': (
                        'background-color': #4794ff,
                        'color': #fff,
                    ),
                    'light': (
                        'background-color': #4794ff,
                        'color': #fff,
                    ),
                )
            );
        }

        &--has-info {
            @include themes(
                (
                    'dark': (
                        'color': #fff,
                    ),
                )
            );
        }
    }

    &__word-remove {
        position: absolute;
        top: 50%;
        right: 0;
        height: rem(25px);
        width: rem(25px);
        opacity: 0;
        transform: translateY(-50%);
        transition: background-color 150ms ease, opacity 300ms;
        cursor: pointer;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            height: rem(2px);
            width: rem(14px);
            border-radius: rem(3px);
            transform-origin: center;
            background-color: #fff;
        }

        &:before {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }

        &:hover {
            &:before {
                transform: translate(-50%, -50%) rotate(135deg);
                transition: transform 300ms;
            }

            &:after {
                transform: translate(-50%, -50%) rotate(45deg);
                transition: transform 300ms;
            }
        }
    }

    &__error {
        position: absolute;
        left: rem(-16px);
        top: rem(-25px);
        font-size: rem(12px);
        color: #f55353;
        font-family: 'Work Sans', sans-serif;
        white-space: nowrap;
        backface-visibility: hidden;
        line-height: 1;
    }

    &__words {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        &--readOnly {
            #{$root}__word {
                padding: rem(3px) rem(6px);

                &-remove {
                    display: none;
                }
            }
        }
    }

    &__label {
        position: relative;
        display: block;
    }

    &__counter {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        font-size: rem(14px);
        line-height: rem(20px);
        color: $dark-gray-9;
        padding: 0 rem(8px) rem(5px);
        transition: transform 250ms ease;
        transform: translateY(-100%);
    }
}
