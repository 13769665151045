@import '@assets/core.style';

.Tooltip {
    position: fixed;
    z-index: 99;
    white-space: pre-wrap;
    display: inline-block;
    max-width: rem(380px);
    transform: translate(-50%, -100%);
    padding: rem(12px) rem(10px);
    //box-shadow: rem(0px) rem(10px) rem(90px) rgba(0, 0, 0, 0.35);
    border-radius: rem(3px);
    font-family: 'Work Sans', sans-serif;
    text-align: center;

    @include themes(
        (
            'dark': (
                'background': #283547,
            ),
            'light': (
                'background-color': #fff,
            ),
        )
    );

    &:after {
        content: '';
        display: block;
        width: rem(16px);
        height: rem(16px);
        position: absolute;
        bottom: rem(-4px);
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        z-index: 1;

        @include themes(
            (
                'dark': (
                    'background': #283547,
                ),
                'light': (
                    'background-color': #fff,
                ),
            )
        );
    }

    &__content {
        position: relative;
        z-index: 2;
    }

    &--last {
        &:after {
            left: 88%;
        }
    }

    &__title {
        font-weight: 500;
        font-size: rem(16px);
        line-height: rem(18px);
        padding-bottom: rem(8px);

        @include themes(
            (
                'dark': (
                    'color': #ffffff,
                ),
                'light': (
                    'color': $light-gray-90,
                ),
            )
        );

        &--message {
            width: rem(300px);
        }
    }

    &__description {
        font-weight: 400;
        font-size: rem(14px);
        line-height: rem(18px);

        @include themes(
            (
                'dark': (
                    'color': rgba(#ffffff, 0.7),
                ),
                'light': (
                    'color': $light-gray-70,
                ),
            )
        );
    }
}
