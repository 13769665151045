@import '@assets/core.style';

.AutoWidthInput {
    $root: &;

    position: relative;
    padding: 0 rem(3px);
    display: inline-flex;
    font-family: 'Poppins', sans-serif;
    font-size: rem(18px);

    span {
        padding-right: rem(5px);

        svg {
            height: rem(14px);
        }
    }

    @include themes(
        (
            'dark': (
                'color': #fff,
                'border-bottom': rem(1px) solid #fff,
            ),
            'light': (
                'color': #3a414c,
                'border-bottom': rem(1px) solid #3a414c,
            ),
        )
    );

    @media all and (max-width: 1440px) {
        font-size: rem(14px);
    }

    &__real {
        width: 100%;
        min-width: 1px;
        height: 100%;
        font-size: inherit;
        position: absolute;
        top: 0;
        padding: 0;
        left: 0;
        right: 0;
        background-color: transparent;
        border: 0;
        outline: none;
        z-index: 2;
        font-family: inherit;
        text-align: left;

        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': $light-gray-90,
                ),
            )
        );
    }

    &__fake {
        width: auto;
        margin: 0 auto;
        position: relative;
        display: block;

        &--left {
            padding-left: rem(3px);

            @include themes(
                (
                    'light': (
                        'color': $light-gray-90,
                    ),
                )
            );
        }

        &--right {
            padding-right: rem(3px);
            color: transparent;
        }
    }

    &--highlighted {
        @include themes(
            (
                'dark': (
                    'border-bottom': rem(2px) solid $dark-blue-3,
                ),
                'light': (
                    'border-bottom': rem(2px) solid $dark-blue-3,
                ),
            )
        );
    }
}
