@use '@styles' as *;

.FoodProfileTable {
    $cell: rem(130px);
    $head: rem(60px);
    $root: &;

    position: relative;
    line-height: 0;
    padding-left: rem(16px);
    padding-right: rem(16px);
    ::-webkit-scrollbar {
        min-width: 1px;
        min-height: 1px;
    }


    &__head {
        display: inline-flex;
        height: $head;
        flex-wrap: nowrap;
        line-height: 1.4;

        #{$root}__cell {
            font-weight: 700;
            font-size: rem(14px);
            text-align: center;
            justify-content: center;
            white-space: nowrap;
            height: 100%;
        }
    }

    &__columns {
        display: flex;
        height: 50%;

        & > #{$root}__cell {
            width: $cell;
        }
    }

    &__cell {
        width: $cell;
        min-width: $cell;
        height: 100%;

        &.is-larger {
            min-width: calc(#{$cell} + #{rem(10px)});
        }

        &--sticky {
            position: sticky;
            z-index: 3;
            left: 0;
            top: 0;
        }

        &--colspan {
            display: flex;
            height: 100%;
            width: auto;

            & > #{$root}__cell {
                width: $cell;
            }
        }

        &--group {
            $columns: 3;

            display: flex;
            height: 100%;
            width: auto;

            & > #{$root}__cell {
                width: auto;
                height: 100%;
            }
        }
    }

    &__body {
        line-height: 1;
        min-width: 100%;
        display: inline-flex;
        flex-flow: column nowrap;
    }

    &__row {
        display: inline-flex;
        flex-wrap: nowrap;
        min-width: 100%;
        height: rem(50px);
    }
}
