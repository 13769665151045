@import '@assets/core.style';

.dropdown {
    position: absolute;
    top: rem(65px);
    left: 0;
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    height: rem(280px);
    padding: rem(25px);
    font-family: 'Work Sans', sans-serif;
    opacity: 0;
    visibility: hidden;
    z-index: 3;
    border-radius: rem(8px);

    $root: &;

    @include themes(
        (
            'dark': (
                'background-color': #171e28,
            ),
            'light': (
                'background-color': $light-gray-0,
            ),
        )
    );

    &__arrow {
        position: absolute;
        top: 0;
        left: rem(50px);
        height: rem(18px);
        width: rem(18px);
        border-radius: rem(2px);
        transform: translateY(-50%) rotate(45deg);
        transform-origin: center;
        pointer-events: none;

        @include themes(
            (
                'dark': (
                    'background-color': #171e28,
                ),
                'light': (
                    'background-color': $light-gray-0,
                ),
            )
        );
    }

    &--ethnic {
        top: rem(90px);

        @media all and (max-width: 1400px) {
            top: rem(110px);
        }
    }

    &__item {
        display: flex;
        align-items: center;
        width: calc(100% / 4);
        height: calc(100% / 7);

        &--bold {
            font-weight: 700;
        }
    }

    &__category {
        &:nth-last-child(n + 2) {
            &:after {
                content: '/';
                display: inline-block;
                padding: 0 rem(10px);
            }
        }
    }

    &-enter,
    &-exit-done {
        opacity: 0;
        visibility: hidden;
    }

    &-enter-active {
        opacity: 1;
        visibility: visible;
        transition: opacity 300ms ease;
    }

    &-enter-done,
    &-exit {
        opacity: 1;
        visibility: visible;
    }

    &-exit-active {
        opacity: 0;
        transition: opacity 300ms ease;
    }

    .checkbox-input {
        &:hover {
            .checkbox-input__label {
                @include themes(
                    (
                        'dark': (
                            'color': white,
                        ),
                        'light': (
                            'color': $dark-blue-1,
                        ),
                    )
                );
            }
        }
    }
}

.menu-items-dropdown {
    &-enter,
    &-exit-done {
        opacity: 0;
        visibility: hidden;
    }

    &-enter-active {
        opacity: 1;
        visibility: visible;
        transition: opacity 300ms 300ms ease;
    }

    &-enter-done,
    &-exit {
        opacity: 1;
        visibility: visible;
    }

    &-exit-active {
        opacity: 0;
        transition: opacity 300ms ease;
    }
}
