@use 'sass:math';

@import '@styles';

.AdminContainer {
    $border-color: $dark-gray-6;
    $root: &;

    padding: rem(80px) 0 0;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: rem(16px);
    line-height: rem(24px);
    letter-spacing: 0.012em;

    &--no-pt {
        padding-top: 0;
    }

    &__error {
        position: absolute;
        font-size: rem(12px);
        color: #f55353;
        left: 0;
        bottom: rem(-20px);
        z-index: 1;
    }

    &__wrapper {
        width: calc(100% - #{percentage(math.div(409, 1920))});
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        height: calc(100vh - rem(80px));
    }

    &__top-title {
        width: 100%;
        height: rem(80px);
        padding: rem(8px) rem(24px);
        transition: background-color 300ms linear, border-color 300ms linear;

        @include themes(
            (
                'dark': (
                    'background-color': #232d3b,
                ),
                'light': (
                    'background-color': #fff,
                ),
            )
        );

        &.is-fixed {
            position: sticky;
            top: rem(70px);
            z-index: $admin-loader-index - 1;
            border-bottom: rem(1px) solid;

            @include themes(
                (
                    'dark': (
                        'border-color': $border-color,
                    ),
                    'light': (
                        'border-color': $light-gray-20,
                    ),
                )
            );
        }
    }

    &__title {
        padding: rem(16px) 0;
        font-family: 'Poppins', sans-serif;
        font-size: rem(24px);
        line-height: rem(32px);
        display: flex;
        align-items: center;
        transition: color 300ms linear;

        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': $dark-gray-0,
                ),
            )
        );
    }

    &__title-info {
        margin-left: rem(15px);
        font-size: 0.6em;
        font-weight: 400;
        display: flex;
        align-items: center;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }

    &__top-name {
        width: rem(376px);
        margin-top: auto;
        margin-bottom: rem(8px);
    }

    &__input-wrap {
        display: block;
        position: relative;
    }

    &__input {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 0 rem(8px);
        font-size: rem(16px);
        line-height: 1.25;
        border-bottom: rem(1px) solid;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        white-space: nowrap;
        transition: border-color 300ms linear;

        @include themes(
            (
                'dark': (
                    'color': #fff,
                    'border-color': #4a6282,
                ),
                'light': (
                    'color': $dark-gray-0,
                    'border-color': $light-gray-20,
                ),
            )
        );

        &:-internal-autofill-selected {
            box-shadow: inset 0 0 0 rem(200px) #fff;
        }

        &::placeholder {
            color: $dark-gray-9;
        }
    }

    &__input-label {
        display: block;
        font-size: rem(16px);
        line-height: rem(16px);
        color: #788ba3;
        pointer-events: none;
        position: absolute;
        left: 0;
        top: 0;
        transition: all 300ms ease;

        &.is-upper {
            transform: translateX(-10%) translateY(-120%) scale(0.8);
        }

        &.is-required {
            &:after {
                content: '*';
                color: $dark-red-1;
            }
        }
    }

    &__btns {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn {
            margin-left: rem(32px);

            &--link {
                @include themes(
                    (
                        'dark': (
                            'color': #fff,
                        ),
                        'light': (
                            'color': $dark-gray-0,
                        ),
                    )
                );

                &:hover {
                    color: $dark-blue-1;
                }
            }
        }
    }

    &__sidebar {
        min-width: rem(328px);
        width: percentage(math.div(409, 1920));
        height: 100%;
    }

    &__btn--disabled {
        pointer-events: none;

        @include themes(
            (
                'dark': (
                    'color': #4a6282,
                    'background-color': #283547 !important,
                ),
                'light': (
                    'color': #fff,
                    'background-color': rgba($dark-blue-1, 0.8),
                ),
            )
        );
    }

    // keywords
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__btn {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem(40px);
        height: rem(40px);
        margin-left: rem(16px);
        border-radius: rem(48px);
        fill: #fff;
        transition: background-color 300ms;
        cursor: pointer;
        user-select: none;

        svg {
            path,
            rect {
                &[stroke='white'] {
                    @include themes(
                        (
                            'light': (
                                stroke: $dark-gray-0,
                            ),
                        )
                    );
                }

                &[fill='white'] {
                    @include themes(
                        (
                            'light': (
                                fill: $dark-gray-0,
                            ),
                        )
                    );
                }
            }
        }

        &:hover {
            background-color: #fafcff;

            path {
                fill: #131921;

                &[stroke='white'] {
                    stroke: #131921;
                }
            }
        }

        &--inverse-hover {
            &:hover {
                path {
                    fill: #fafcff;
                }
            }
        }

        &--circle {
            @include themes(
                (
                    'dark': (
                        border: 2px solid #fff,
                    ),
                    'light': (
                        border: 2px solid #131921,
                    ),
                )
            );
        }
    }

    &__btn--add {
        display: flex;
        text-decoration: none;
        justify-content: center;
        align-items: center;
        width: rem(176px);
        height: rem(40px);
        margin-left: rem(16px);
        background-color: #4794ff;
        border-radius: rem(48px);
        text-transform: uppercase;
        text-align: center;
        color: #fff;
        transition: background-color 300ms;
        cursor: pointer;

        &:hover {
            background-color: #25b7a5;
        }
    }

    &__btn--medium {
        width: rem(220px);
    }

    &__content {
        padding: rem(16px) 0;
    }

    &__main {
        $background: $dark-gray-2;
        $border-color: $dark-gray-6;

        position: relative;
        transition: background-color 300ms linear;

        @include themes(
            (
                'dark': (
                    'background': $background,
                ),
                'light': (
                    'background': #fff,
                ),
            )
        );
    }

    &__row {
        padding: rem(8px) rem(24px);
        display: flex;
        flex-direction: column;

        &--right {
            align-items: flex-end;
        }

        &.has-divider {
            border-top: rem(1px) solid $dark-gray-6;
            padding-top: rem(16px);
            padding-bottom: rem(16px);
        }

        &--pb {
            padding-bottom: rem(24px);
        }
    }

    &__row-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 300;
        user-select: none;
        @include themes(
            (
                'dark': (
                    'color': white,
                ),
                'light': (
                    'color': black,
                ),
            )
        );

        b {
            font-weight: 900;
        }
    }

    &__row-header-part {
        display: flex;
        align-items: center;
    }

    &__row-header-content {
        &.is-error {
            color: #f55353;
            font-weight: 600;
        }
    }

    &__row-header-button {
        width: rem(40px);
        height: rem(40px);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: rem(4px);
        margin-left: rem(20px);

        * {
            @include themes(
                (
                    'dark': (
                        'stroke': white,
                    ),
                    'light': (
                        'stroke': black,
                    ),
                )
            );
        }

        &:hover {
            box-shadow: 0 0 5px 0 #000;
        }
    }

    &__top-actions {
        display: flex;
        font-weight: 400;
        align-items: center;
        position: relative;
        top: rem(16px);

        &-item {
            & + & {
                margin-left: rem(40px);
            }
        }
    }

    &__button {
        font-size: rem(14px);
        min-width: rem(136px);
        height: rem(32px);
    }

    &__section {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    &__section-title {
        display: flex;
        align-items: center;
        padding-bottom: rem(24px);
    }

    &__header-column {
        &--search {
            width: rem(320px);
            height: rem(40px);

            .search-field {
                max-width: 100%;
                min-width: 100%;
            }
        }

        &--actions {
            display: flex;
            align-items: center;
        }
    }

    &__column {
        $column-width-fluid: 36%;
        $column-width-fixed: 360px;
        $column-gap: 5%;

        width: $column-width-fluid;
        max-width: $column-width-fluid;
        font-size: rem(16px);
        line-height: 1.5;
        letter-spacing: 0.012em;
        color: #fff;
        padding-right: $column-gap;

        &--limit-z-index {
            position: relative;
            z-index: 2;
        }

        &--fixed {
            width: rem($column-width-fixed);
            max-width: 50%;
        }

        &--selects {
            height: rem(40px);
        }

        &--fluid {
            max-width: calc(100% - #{$column-width-fluid});
            width: auto;
            flex: 1;
            padding-right: 0;
        }

        &--block {
            max-width: 100%;
            width: rem($column-width-fixed * 2);
            padding-right: $column-gap;
            margin-top: rem(30px);

            &:first-child {
                margin-top: 0;
            }
        }
    }

    &__checkboxes-group-item {
        width: 33%;
        margin-bottom: rem(15px);
        font-size: rem(14px);

        @include mq('lg') {
            width: 50%;
        }
    }

    &__checkboxes-group {
        $group: &;

        display: flex;
        flex-wrap: wrap;
        width: 100%;

        &--small {
            padding: rem(10px) 25% rem(10px) rem(24px);

            @include mq('lg') {
                padding-right: rem(24px);
            }

            @include themes(
                (
                    'dark': (
                        'background': $dark-gray-3,
                    ),
                    'light': (
                        'background': $light-gray-10,
                    ),
                )
            );
        }

        &--small & {
            &-item {
                width: 16%;
                margin-top: rem(8px);
                margin-bottom: rem(8px);
            }
        }
    }

    &__select {
        .Select {
            &--inherited {
                font-size: rem(14px);

                @include themes(
                    (
                        'dark': (
                            'background': #2e3e56,
                        ),
                        'light': (
                            'background': $light-gray-20,
                        ),
                    )
                );

                .Select__input {
                    cursor: pointer;

                    &--opened {
                        @include themes(
                            (
                                'dark': (
                                    'background': #1f2835,
                                ),
                                'light': (
                                    'background': $light-gray-20,
                                ),
                            )
                        );
                    }

                    &:after {
                        right: rem(13px);
                    }
                }
            }
        }
    }
}
