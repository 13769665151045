@import '@styles';

.download-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(20px);
    width: rem(20px);
    border-radius: 50%;
    transition: background-color 300ms, border-color 300ms;
    background: transparent;
    fill: #fff;
    cursor: pointer;

    @include themes(
        (
            'light': (
                'border-color': $light-gray-90,
            ),
        )
    );

    svg {
        path {
            @include themes(
                (
                    'light': (
                        'stroke': $light-gray-90,
                    ),
                )
            );
        }
    }

    &:hover {
        background: $dark-blue-3;
        border-color: $dark-blue-3;

        svg {
            path {
                @include themes(
                    (
                        'light': (
                            'stroke': #fff,
                        ),
                    )
                );
            }
        }
    }
}
