@use '@datassential/platform-ui-lib/lib/style/constants' as *;
@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@use 'sass:math';
@import '@styles';

$files-width: 80px;

.Reports {
    max-width: percentage(math.div(1800, 1920));
    margin: 0 auto;
    padding: rem(10px) 0 2vh;
    font-family: 'Nunito', sans-serif;

    @media all and (max-width: 1280px) {
        max-width: 96%;
    }

    &__title {
        margin-bottom: rem(16px);
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: rem(48px);
        line-height: rem(56px);
        transition: color 300ms;

        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': $light-gray-90,
                ),
            )
        );
    }

    &__columns {
        display: flex;
        justify-content: space-between;
    }

    &__column-header {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: rem(16px);
        padding-right: rem($files-width + 16px);
        z-index: 3;
        position: relative;
    }

    &__column-title {
        text-align: center;
        text-transform: uppercase;
        font-family: 'Nunito', sans-serif;
        font-size: rem(20px);
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: color 500ms;
        position: relative;

        .NewTooltip {
            text-transform: none;
            width: rem(400px);
            text-align: left;
            margin-top: rem(16px);
            padding: rem(8px);
        }

        @include themes(
            (
                'dark': (
                    'color': $dark-gray-8,
                ),
                'light': (
                    'color': $light-gray-70,
                ),
            )
        );

        &:hover {
            @include themes(
                (
                    'dark': (
                        'color': #ffffff,
                    ),
                    'light': (
                        'color': $light-gray-90,
                    ),
                )
            );

            .NewTooltip {
                display: block;
            }
        }
    }

    &__column-title-icon {
        position: absolute;
        left: 100%;
        top: 0;
        transform: translateY(-50%);
        width: rem(16px);
        height: rem(16px);

        svg {
            width: 100%;
            height: 100%;
        }

        * {
            @include themes(
                (
                    'dark': (
                        'stroke': $dark-gray-8,
                    ),
                    'light': (
                        'stroke': $light-gray-70,
                    ),
                )
            );
        }
    }

    &__column {
        width: 48%;
    }

    &__placeholder {
        width: 100%;
        height: rem(150px);
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: rem($files-width + 16px);

        @include themes(
            (
                'dark': (
                    'color': $dark-gray-8,
                ),
                'light': (
                    'color': $light-gray-70,
                ),
            )
        );
    }

    &__table {
        height: calc(
            100vh - #{$platform-header-height} - #{$platform-navigation-height} -
                #{rem(230px)}
        );
    }
}

.Report {
    $row-height: rem(60px);
    $row-v-padding: rem(12px);
    $row-h-padding: rem(16px);
    $pages-width: rem(186px);
    $gap: rem(32px);

    position: relative;
    display: flex;
    align-items: center;
    height: $row-height;
    padding: $row-v-padding $row-h-padding;
    border-bottom: rem(1px) solid;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: rem(14px);
    line-height: rem(16px);
    transition: background-color 500ms, color 500ms, border-color 500ms;
    cursor: pointer;

    $root: &;

    @include themes(
        (
            'dark': (
                'color': $dark-gray-8,
                'border-color': #34455c,
            ),
            'light': (
                'color': $light-gray-70,
                'border-color': $light-gray-20,
            ),
        )
    );

    &:last-child {
        border: none;
    }

    &:hover {
        z-index: 2;
        @include themes(
            (
                'dark': (
                    'background-color': $dark-gray-3,
                    'color': #fff,
                ),
                'light': (
                    'background-color': #fff,
                    'color': $light-gray-90,
                ),
            )
        );

        #{$root}__hover {
            opacity: 1;
            top: calc(100% - #{$row-v-padding});
        }

        #{$root}__files {
            visibility: visible;
        }
    }

    &__title {
        width: calc(100% - #{rem($files-width)});
        font-size: rem(18px);
        line-height: rem(18px);
        text-align: center;

        @include themes(
            (
                'light': (
                    'color': $light-gray-90,
                ),
            )
        );
    }

    &__pages {
        width: $pages-width;
        min-width: rem(126px);
        padding: 0 rem(32px);
        text-align: center;
        font-size: rem(16px);

        @media all and (max-width: 1440px) {
            width: percentage(math.div(200, 1920));
        }
    }

    &__description {
        padding-left: $gap;
        font-size: rem(16px);
        overflow: hidden;
        width: calc(100% - #{rem($files-width)});

        &--collapsed {
            @include multilines-ellipsis(2);
        }
    }

    &__files {
        width: rem($files-width);
        margin-left: auto;
        text-align: right;
        font-size: 0;
        transition: opacity 500ms;
    }

    &__file {
        position: relative;
        margin-left: rem(24px);
        cursor: pointer;
        transition: opacity 500ms;

        &:hover {
            #{$root}__icon {
                opacity: 1;
            }
        }

        &:first-child {
            margin-left: 0;
        }
    }

    &__icon {
        display: inline-block;
        vertical-align: middle;
        opacity: 0.5;
        transition: opacity 300ms;

        * {
            @include themes(
                (
                    'light': (
                        fill: $dark-gray-0,
                    ),
                )
            );
        }
    }

    &__hover {
        opacity: 0;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: auto;
        pointer-events: none;
        padding: 0 $row-h-padding $row-v-padding;
        transition: opacity 500ms, top 500ms;

        @include themes(
            (
                'dark': (
                    'background-color': $dark-gray-3,
                    'color': #fff,
                ),
                'light': (
                    'background-color': #fff,
                    'color': $light-gray-90,
                ),
            )
        );
    }
}
