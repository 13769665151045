@import '@assets/core.style';

.Button {
    background-color: rgba(#636363, 0.2);
    color: #fff;
    font-size: rem(16px);
    width: rem(130px);
    height: rem(30px);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 300ms ease, color 300ms ease;
    cursor: pointer;
    border-radius: rem(2px);
    user-select: none;

    &--small {
        width: rem(90px);
    }

    @media all and (max-width: 1440px) {
        font-size: rem(12px);
        width: rem(100px);
        height: rem(24px);
    }

    &:hover {
        background-color: $dark-blue-1;
    }

    &:disabled {
        color: rgba(#fff, 0.2);
        pointer-events: none;
    }

    &--rounded {
        border-radius: rem(50px);
    }

    &--blue {
        background-color: $dark-blue-1;

        &:hover {
            background-color: #1477ff;
        }

        &:disabled {
            @include themes(
                (
                    'dark': (
                        'background-color': #283547,
                    ),
                    'light': (
                        'background-color': rgba($dark-blue-1, 0.8),
                    ),
                )
            );
        }
    }

    &--white {
        background: #fff;
        color: #000;

        &:disabled {
            background: rgba(#fff, 0.2);
            color: rgba(#000, 0.2);
        }

        &:hover {
            background-color: #25b7a5;
            color: #fff;
        }
    }

    &--red {
        background-color: #f55353;

        &:hover {
            background-color: #1477ff;
        }
    }

    &--transparent {
        background-color: transparent;
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: rem(16px);
        line-height: rem(24px);
        text-align: center;
        letter-spacing: 0.012em;
        transition: transform 300ms;

        @include themes(
            (
                'dark': (
                    color: #fff,
                ),
                'light': (
                    color: $dark-gray-0,
                ),
            )
        );

        &:hover {
            background-color: transparent;
            transform: scale(1.1);
        }
    }

    &__admin {
        width: rem(176px);
        height: rem(40px);
        border-radius: rem(48px);
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: rem(16px);
        line-height: rem(24px);
        text-decoration: none;
        position: relative;
        z-index: 1;

        @include themes(
            (
                'dark': (
                    'background': #363b4d,
                    'color': #4a6282,
                ),
                'light': (
                    'background': rgba($dark-blue-1, 0.5),
                    'color': #fff,
                ),
            )
        );

        @include mq('lg') {
            width: rem(100px);
        }

        &:hover {
            background: #25b7a5;
            color: #fff;
        }
    }

    &--bordered {
        background: none;
        border: rem(1px) solid;
        padding: rem(8px) rem(24px);
        width: auto;
        height: auto;

        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': $dark-gray-0,
                ),
            )
        );

        &:hover {
            @include themes(
                (
                    'dark': (
                        'background': #fff,
                        'color': $dark-gray-0,
                    ),
                    'light': (
                        'background': $dark-gray-0,
                        'color': #fff,
                    ),
                )
            );
        }
    }

    &--fluid {
        width: 100%;
        height: 100%;
        font-size: inherit;
    }
}
