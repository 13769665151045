@import '@styles';

.ChartTooltip {
    $root: &;
    $muted: #7e8690;
    $cell: #232d3b;
    $cell-light: #fff;
    $head: #283547;
    $head-light: darken(#fff, 5%);
    $radius: rem(4px);

    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    min-width: rem(50px);
    padding: 0;
    color: white;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: rem(12px);
    box-shadow: 0 rem(4px) rem(30px) rgba(0, 0, 0, 0.16);
    pointer-events: none;
    transform: translate(-50%, -50%);
    border-radius: $radius;

    @include themes(
        (
            'dark': (
                'background': $cell,
            ),
            'light': (
                'background': $cell-light,
            ),
        )
    );

    &--left {
        transform: translate(calc(-100% - rem(35px)), -50%);

        &:before {
            transform: translate(50%, -50%) rotate(-45deg);
            right: 0;
        }
    }

    &--right {
        transform: translate(rem(35px), -50%);

        &:before {
            left: 0;
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }

    &__container {
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        left: 0;
    }

    &.is-hidden {
        display: none;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        border-radius: rem(3px);
        width: rem(12px);
        height: rem(12px);
        transition: transform 150ms ease;

        @include themes(
            (
                'dark': (
                    'background-color': $cell,
                ),
                'light': (
                    'background': $cell-light,
                ),
            )
        );
    }

    &__group {
        &--fluid {
            flex: 1;
        }

        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': #000,
                ),
            )
        );

        &--muted {
            @include themes(
                (
                    'dark': (
                        'color': $muted,
                    ),
                    'light': (
                        'color': $muted,
                    ),
                )
            );
        }
    }

    &__table {
        display: flex;
    }

    &__head,
    &__row {
        display: flex;
    }

    &__head {
        height: rem(24px);
        font-weight: 600;
        text-transform: uppercase;
        position: relative;
        z-index: 1;
        @include themes(
            (
                'dark': (
                    'background-color': $head,
                ),
                'light': (
                    'background': $head-light,
                ),
            )
        );

        #{$root}__cell {
            height: 100%;
            justify-content: center;
        }

        #{$root}__group--fluid & {
            border-top-left-radius: $radius;
        }

        #{$root}__group:last-child & {
            border-top-right-radius: $radius;
        }
    }

    &__row,
    &__footer {
        @include themes(
            (
                'dark': (
                    'background': $cell,
                ),
                'light': (
                    'background': $cell-light,
                ),
            )
        );

        #{$root}__group--fluid & {
            border-bottom-left-radius: $radius;
        }
        #{$root}__group:last-child & {
            border-bottom-right-radius: $radius;
        }
    }

    &__cell {
        $size: rem(50px);

        padding: rem(8px);
        height: rem(30px);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex: 1;

        &--head,
        &--fluid {
            width: auto;
            max-width: none;
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        font-size: rem(8px);
        line-height: 1.4;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        padding: rem(4px) 0;
        height: rem(14px);
    }

    &__footer-label {
        flex: 1;
        padding: 0 rem(8px);
        @include themes(
            (
                'dark': (
                    'color': #566f92,
                ),
                'light': (
                    'color': lighten(#000, 5%),
                ),
            )
        );

        &.is-muted {
            @include themes(
                (
                    'dark': (
                        'color': $muted,
                    ),
                    'light': (
                        'color': $muted,
                    ),
                )
            );
        }
    }

    &__colors {
        $size: rem(16px);

        width: $size;
        height: $size;
        min-width: $size;
        min-height: $size;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: rem(4px);
    }

    &__name {
        white-space: nowrap;
    }

    &__sub-title {
        font-size: rem(11px);
        text-transform: lowercase;
        white-space: nowrap;
        font-weight: 400;
    }
}

.TooltipPoint {
    $size: rem(16px);

    width: $size;
    height: $size;
    position: relative;

    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
    }

    &:before {
        width: rem(8px);
        height: rem(8px);
        background: currentColor;
    }

    &:after {
        width: $size;
        height: $size;
        background: currentColor;
        opacity: 0.2;
    }
}
