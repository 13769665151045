@import '@assets/core.style';

.headerCenterMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: 'Work Sans', sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    font-size: rem(20px);
    line-height: rem(18px);
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding-left: rem(320px);
    padding-right: rem(320px);
    $root: &;

    &:hover {
        #{$root}__item {
            transform: translateY(0);
        }
    }

    @media all and (max-width: 1440px) {
        min-width: fit-content;
    }

    &__active {
        #{$root}__item {
            transform: translateY(0);
        }
    }

    &__item {
        transition: color 300ms;
        text-decoration: none;
        padding: 0 rem(20px);
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        transform: translateY(rem(-100px));
        white-space: nowrap;

        $item: &;

        &.is-wrap {
            white-space: normal;
            text-align: center;
        }

        @include mq('xls') {
            padding: 0 rem(10px);
        }

        @include themes(
            (
                'dark': (
                    color: rgba(#fff, 0.7),
                ),
                'light': (
                    color: $light-gray-90,
                ),
            )
        );

        &:hover {
            &:not(#{$item}--active) {
                @include themes(
                    (
                        'dark': (
                            'color': #fff,
                        ),
                        'light': (
                            'color': $dark-blue-1,
                        ),
                    )
                );
            }
        }

        &--active {
            cursor: default;

            @include themes(
                (
                    'dark': (
                        color: $dark-blue-1,
                    ),
                    'light': (
                        color: $dark-blue-1,
                    ),
                )
            );

            &:hover {
                color: $dark-blue-1;
            }

            &:before {
                background: $dark-blue-1;
            }
        }

        @for $i from 0 to 10 {
            &:nth-child(#{1 + $i}) {
                transition: transform 300ms #{$i * 100ms} ease, color 300ms;
            }
        }
    }

    &__search {
        width: rem(40px);
        min-width: rem(40px);
        height: rem(40px);
        margin: 0 rem(20px);
        padding: 0;
        justify-content: center;
        background: linear-gradient(90deg, #f55353, #bd67e6);
        background-size: 200% 200%;
        border-radius: 50%;
        color: #fff;
        transition: transform 300ms, color 300ms;
        animation: bgChange 3s ease infinite;
    }

    &__search-icon {
        width: rem(24px);
        height: rem(24px);

        @include themes(
            (
                'dark': (
                    'stroke': rgba(#fff, 0.7),
                ),
                'light': (
                    'stroke': #fff,
                ),
            )
        );

        path {
            stroke: inherit;
            stroke-width: 1px;
        }
    }
}
