@import '@assets/core.style';

.search {
    font-family: 'Poppins', sans-serif;
    position: relative;
    border-bottom: rem(1px) solid;

    @include themes(
        (
            'dark': (
                'border-color': $dark-gray-6,
            ),
            'light': (
                'border-color': $light-gray-40,
            ),
        )
    );

    &__label {
        display: block;
        width: 100%;
    }

    &__icon {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        @include themes(
            (
                'dark': (
                    'stroke': $dark-gray-6,
                ),
                'light': (
                    'stroke': $light-gray-40,
                ),
            )
        );

        path {
            stroke: inherit;
        }
    }

    &__input {
        width: 100%;
        font-family: Nunito, sans-serif;
        background-color: transparent;
        border: 0;
        outline: none;
        font-weight: 600;
        font-size: rem(16px);
        line-height: rem(24px);
        letter-spacing: 0.012em;

        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': $light-gray-90,
                ),
            )
        );

        &::placeholder {
            @include themes(
                (
                    'dark': (
                        'color': $dark-gray-5,
                    ),
                    'light': (
                        'color': $light-gray-40,
                    ),
                )
            );
        }
    }

    &__clear {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: rem(20px);
        height: rem(20px);
        cursor: pointer;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            height: rem(2px);
            width: rem(12px);

            @include themes(
                (
                    'dark': (
                        'background-color': $dark-blue-1,
                    ),
                    'light': (
                        'background-color': $light-gray-70,
                    ),
                )
            );
        }

        &:before {
            transform: translate(-50%, -50%) rotate(-45deg);
        }

        &:after {
            transform: translate(-50%, -50%) rotate(45deg);
        }
    }
}
