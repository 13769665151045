@import '@assets/core.style';

.KeywordNameCell {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    height: 100%;
    align-items: center;
    padding-left: rem(65px);

    &--small-padding {
        padding-left: rem(60px);
    }


    &__chart {
        position: absolute;
        top: 50%;
        height: rem(40px);
        width: rem(30px);
        transform: translateY(-50%);
        left: rem(24px);
        cursor: pointer;
        z-index: 3;
        transition: background-color 300ms;

        &:hover {
            @include themes(
                (
                    'dark': (
                        'background-color': $dark-gray-5,
                    ),
                    'light': (
                        'background-color': $light-gray-10,
                    ),
                )
            );
        }

        canvas {
            position: absolute;
            top: 10%;
            left: 0;
            max-width: 100%;
            max-height: 80%;
        }
    }

    &__text {
        position: relative;
        pointer-events: none;
        text-align: left;
        padding-right: rem(42px);

        &.is-light {
            font-weight: 300;
        }

        &.tag-label {
            &:after {
                margin-bottom: rem(1px);
            }
        }
    }

    &__text-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 2;
        left: 0;
        right: rem(90px);
    }

    &__circle {
        width: rem(30px);
        //height: 100%;
        position: absolute;
        left: rem(24px);
        z-index: 3;
        transition: background-color 300ms;

        &:hover {
            background: $dark-gray-5;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            width: rem(10px);
            height: rem(10px);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: currentColor;
            border-radius: 50%;
        }
    }

    &:hover {
        .keyword-settings-btn,
        .remove-btn {
            opacity: 1;
            visibility: visible;
        }

        .table__text {
            @include themes(
                (
                    'light': (
                        'color': $dark-blue-1,
                    ),
                )
            );
        }
    }
}

.dot-list {
    display: flex;
    align-items: center;
    position: absolute;
    right: rem(55px);

    &__item {
        width: rem(18px);
        height: rem(18px);
        cursor: pointer;
        position: relative;
        transition: $base-background-transition;

        &:after {
            content: '';
            width: rem(6px);
            height: rem(6px);
            position: absolute;
            left: 50%;
            top: 50%;
            border-radius: 50%;
            z-index: 200;
            transform: translate(-50%, -50%);
            transition: $base-background-transition;

            @include themes(
                (
                    'dark': (
                        'background-color': rgba(white, 0.2),
                    ),
                    'light': (
                        'background': #3a414c,
                        'opacity': 0.2,
                    ),
                )
            );
        }

        &:hover {
            &:after {
                @include themes(
                    (
                        'dark': (
                            'background-color': rgba(white, 0.5),
                        ),
                        'light': (
                            'background-color': #3a414c,
                            'opacity': 0.5,
                        ),
                    )
                );
            }
        }

        &--active {
            pointer-events: none;

            &:after {
                @include themes(
                    (
                        'dark': (
                            'background-color': $dark-blue-1,
                        ),
                        'light': (
                            'background-color': $dark-blue-1,
                            'opacity': 1,
                        ),
                    )
                );
            }

            &:before {
                content: '';
                display: block;
                position: absolute;
                border: rem(1px) solid $dark-blue-1;
                width: rem(14px);
                height: rem(14px);
                left: 50%;
                top: 50%;
                border-radius: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.remove-btn {
    width: rem(30px);
    height: 100%;
    position: absolute;
    font-size: rem(20px);
    line-height: 1;
    right: rem(40px);
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    cursor: pointer;
    visibility: hidden;
    backface-visibility: hidden;
    transform-origin: center;
    transition: background-color 300ms, opacity 150ms ease;
    z-index: 3;

    @include themes(
        (
            'dark': (
                'background': $dark-gray-3,
                'color': rgba(255, 255, 255, 0.7),
            ),
            'light': (
                'background-color': $light-gray-10,
                'color': $light-gray-90,
            ),
        )
    );

    &:hover {
        @include themes(
            (
                'dark': (
                    'background': $dark-gray-4,
                ),
                'light': (
                    'background-color': $light-gray-10,
                    'color': $light-gray-90,
                ),
            )
        );
    }

    &__cross {
        &:before,
        &:after {
            content: '';
            display: block;
            width: rem(13px);
            height: rem(2px);
            position: absolute;
            top: 50%;
            left: 50%;

            @include themes(
                (
                    'dark': (
                        'background-color': #fff,
                    ),
                    'light': (
                        'background-color': $light-gray-90,
                    ),
                )
            );
        }

        &:before {
            transform: translate(-50%, -50%) rotate(-45deg);
        }

        &:after {
            transform: translate(-50%, -50%) rotate(45deg);
        }
    }
}
