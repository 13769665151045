@import '@assets/core.style';

.MultipleInputWordExtensions {
    display: flex;
    position: absolute;
    width: rem(465px);
    transform: translate(-50%, rem(5px));
    pointer-events: none;
    transition: top 0ms, left 0ms;

    @include themes(
        (
            'dark': (
                'background-color': #242734,
                'box-shadow': 0 rem(10px) rem(90px) rgba(0, 0, 0, 0.35),
                'border-radius': rem(3px),
            ),
            'light': (
                'background-color': #fff,
                'box-shadow': 0 rem(8px) rem(20px) rgba(176, 195, 208, 0.24),
                'border-radius': rem(8px),
            ),
        )
    );

    &__include,
    &__exclude {
        width: 50%;

        @include themes(
            (
                'light': (
                    'background': #fafafa,
                ),
            )
        );
    }

    &__include {
        padding: rem(8px) rem(15px) rem(8px) rem(25px);
    }

    &__exclude {
        padding: rem(8px) rem(25px) rem(8px) rem(15px);
    }

    &__title {
        //text-align: center;
        font-size: rem(14px);
        line-height: rem(16px);
        font-weight: 500;

        &--green {
            color: #27ae60;
        }

        &--red {
            color: #c3272f;
        }

        &--yellow {
            color: #f2c94c;
        }
    }

    &__words {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        height: rem(150px);
        border-radius: rem(2px);
        margin: rem(10px) 0;

        @include themes(
            (
                'dark': (
                    'background-color': #232d3b,
                ),
                'light': (
                    'background-color': $light-gray-10,
                ),
            )
        );
    }

    &__word {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: rem(4px) rem(8px);
        font-family: 'Nunito', sans-serif;
        font-weight: 600;
        font-size: rem(14px);
        line-height: rem(16px);
        transition: color 150ms ease;

        @include themes(
            (
                'dark': (
                    color: #fff,
                ),
                'light': (
                    color: $light-gray-90,
                ),
            )
        );
    }
}
