@use '@datassential/platform-ui-lib/lib/style/constants' as *;
@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@import '@assets/core.style';

.FoodProfile {
    padding-bottom: rem(100px);
    font-family: 'Work Sans', sans-serif;

    &__header {
        position: sticky;
        display: flex;
        justify-content: space-between;
        width: 100%;
        left: 0;
        z-index: 80;
        top: calc(#{$platform-navigation-height} + #{$platform-header-height});

        @include themes(
            (
                'dark': (
                    'background-color': $dark-gray-0,
                ),
                'light': (
                    'background-color': $light-gray-10,
                ),
            )
        );
    }

    &__menu-penetration {
        max-width: rem(1400px);
        width: 100%;
        margin: auto;
        height: rem(350px);
    }

    &__wrap {
        display: flex;
        height: 100%;
        padding-bottom: rem(24px);
    }

    &__content {
        min-height: 100vh;
    }

    &__bg-wrapper {
        padding-bottom: rem(16px);
        border-radius: rem(8px);
        padding-top: rem(24px);

        @include themes(
            (
                'dark': (
                    'background': #232d3b,
                ),
                'light': (
                    'background-color': #fff,
                    'box-shadow': 0 rem(8px) rem(20px) rgba(176, 195, 208, 0.24),
                ),
            )
        );
    }

    &__chart {
        position: relative;
        width: calc(100% - rem(319px));
        margin-right: rem(24px);
    }

    &__menu-title {
        padding-bottom: rem(16px);
        padding-left: rem(20px);
        font-weight: 600;
        font-size: rem(48px);
        line-height: rem(56px);
        text-transform: uppercase;

        @include themes(
            (
                'light': (
                    'color': #0d0f15,
                ),
            )
        );
    }

    &__menu-subtitle {
        text-align: center;
        font-size: rem(18px);
        line-height: rem(18px);
        font-weight: 400;
        padding-bottom: rem(60px);

        @include themes(
            (
                'dark': (
                    'color': rgba(#fff, 0.7),
                ),
                'light': (
                    'color': rgba(#0d0f15, 0.7),
                ),
            )
        );
    }

    &__title {
        font-weight: 500;
        font-size: rem(24px);
        line-height: rem(32px);
        padding-left: rem(20px);
        text-transform: uppercase;
        border-bottom: rem(1px) solid;
        padding-bottom: rem(8px);
        margin-bottom: rem(16px);
        display: flex;
        align-items: flex-end;

        @include themes(
            (
                'dark': (
                    'border-color': #4a6282,
                    'color': $dark-orange-1,
                ),
                'light': (
                    'color': $light-gray-90,
                    'border-color': $light-gray-20,
                ),
            )
        );
    }

    &__sub-title {
        font-size: rem(12px);
        line-height: rem(15px);
        display: flex;
        align-items: center;
        padding-left: rem(10px);
        text-transform: none;
        transform: translateY(#{rem(-3px)});

        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': $dark-gray-1,
                ),
            )
        );

        a {
            @include themes(
                (
                    'light': (
                        'filter': brightness(0) saturate(100%),
                    ),
                )
            );
        }
    }

    &__horizontalBarCharts-container {
        display: flex;
        justify-content: space-around;
        margin-top: rem(60px);
        padding-bottom: rem(50px);
        position: relative;
        padding-left: rem(8px);
        padding-right: rem(8px);
    }

    &__table {
        margin-bottom: rem(24px);
    }

    &__flavor {
        padding-bottom: rem(60px);
    }

    &__mac-stage-container {
        width: rem(304px);
        height: rem(45px);
        padding-top: rem(27px);
    }

    &__mac-stage-list {
        margin: auto;
        height: rem(1px);
        width: 80%;
        position: relative;

        @include themes(
            (
                'dark': (
                    'background': #4a6282,
                ),
                'light': (
                    'background': #d2d8e2,
                ),
            )
        );
    }

    &__mac-stage-title {
        position: absolute;
        font-weight: 600;
        font-size: rem(12px);
        line-height: rem(16px);
        bottom: rem(-30px);
        transform: translateX(-50%);

        &-Inception {
            left: 0;
        }

        &-Adoption {
            left: 33.3%;
        }

        &-Proliferation {
            left: 66.6%;
        }

        &-Ubiquity {
            left: 100%;
        }
    }

    &__mac-stage {
        display: inline-block;
        width: rem(8px);
        height: rem(8px);
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);

        @include themes(
            (
                'dark': (
                    'background': #d7e3f3,
                ),
                'light': (
                    'background': #1a2027,
                ),
            )
        );

        &-Inception {
            left: 0;
        }

        &-Adoption {
            left: 33.3%;
        }

        &-Proliferation {
            left: 66.6%;
        }

        &-Ubiquity {
            left: 100%;
        }

        &--active {
            width: rem(24px);
            height: rem(24px);
            border-radius: 50%;

            @include themes(
                (
                    'dark': (
                        'background': #f55353,
                    ),
                    'light': (
                        'background': #f55353,
                    ),
                )
            );
        }
    }
}
