@import '@assets/core.style';

.profile-item {
    height: rem(40px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: rem(2px);
    padding-left: rem(10px);
    transition: background-color 200ms linear;

    $root: &;

    @include themes(
        (
            'dark': (
                'background': #283547,
            ),
            'light': (
                'background': $light-gray-10,
            ),
        )
    );

    &:hover {
        #{$root}__val {
            @include themes(
                (
                    'dark': (
                        color: $dark-gray-7,
                    ),
                    'light': (
                        color: $dark-blue-3,
                    ),
                )
            );
        }
    }

    &__title {
        text-transform: uppercase;
        width: 70%;

        &.is-light {
            font-weight: 300;
        }

        &.tag-label {
            &:after {
                margin-bottom: rem(1px);
            }
        }

        b {
            font-weight: 900;

            @include themes(
                (
                    'dark': (
                        'color': orange,
                    ),
                    'light': (
                        'color': $dark-blue-1,
                    ),
                )
            );
        }
    }

    &__title,
    &__value {
        font-size: rem(14px);
        line-height: rem(16px);
        transition: color 200ms linear;
        text-decoration: none;

        @include themes(
            (
                'dark': (
                    'color': #ffffff,
                ),
                'light': (
                    'color': $light-gray-90,
                ),
            )
        );
    }

    &__value {
        height: 100%;
        width: rem(64px);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color 200ms linear;

        @include themes(
            (
                'dark': (
                    'background': $dark-gray-4,
                ),
                'light': (
                    'background': $light-gray-20,
                ),
            )
        );
    }
}
