@import '@styles';

.CircularProgressBar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }

    &__label {
        font-size: rem(16);
        font-weight: 500;
        display: flex;
    }
}
