@import '@styles';

.restaurantActivityList {
    &__container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-content: stretch;
        width: 100%;
        margin: auto;
        padding-left: rem(16px);
        padding-right: rem(16px);
    }

    &__item {
        margin-top: rem(16px);
        width: 49%;
    }
}
