@import '@styles';

.Tag {
    position: relative;
    display: flex;
    align-items: center;
    padding: rem(6px) rem(10px);
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: rem(14px);
    line-height: rem(16px);
    min-height: rem(28px);
    border-radius: rem(8px);
    transition: color 150ms ease, background-color 150ms ease;
    margin-right: rem(8px);
    margin-top: rem(8px);
    user-select: none;
    color: #000;
    background-color: #ffffff;
    border: rem(1px) solid transparent;

    @include themes(
        (
            'light': (
                'border-color': rgba(#555, 0.2),
            ),
        )
    );

    &.is-p-right {
        padding-right: rem(32px);
    }

    &.is-active {
        background-color: #4794ff;
        color: #fff;
        border-color: transparent;
    }

    &__remove-btn {
        position: absolute;
        top: 50%;
        right: rem(4px);
        height: rem(25px);
        width: rem(25px);
        transform: translateY(-50%);
        transition: background-color 150ms ease, opacity 300ms;
        cursor: pointer;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            height: rem(2px);
            width: rem(14px);
            border-radius: rem(3px);
            transform-origin: center;
            background-color: #fff;
        }

        &:before {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }

        &:hover {
            &:before {
                transform: translate(-50%, -50%) rotate(135deg);
                transition: transform 300ms;
            }

            &:after {
                transform: translate(-50%, -50%) rotate(45deg);
                transition: transform 300ms;
            }
        }
    }
}
