@import '@styles';

.PopupAddChildReport {
    &__header {
        display: flex;
        align-items: center;
        margin-top: rem(-15px);
        margin-bottom: rem(30px);
    }

    &__filters-info,
    &__actions {
        display: flex;
    }

    &__search {
        margin-left: auto;
    }

    &__filters-info {
        font-family: 'Nunito', sans-serif;
        font-size: rem(16px);
    }

    &__counter {
        color: $dark-gray-9;
        font-weight: 600;
        margin-right: rem(15px);
        cursor: default;
    }

    &__clear {
        color: $dark-blue-3;
        cursor: pointer;
        user-select: none;

        &:hover {
            @include themes(
                (
                    'dark': (
                        'color': #fff,
                    ),
                    'light': (
                        'color': $dark-gray-0,
                    ),
                )
            );
        }
    }
}
