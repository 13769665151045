@import '@assets/core.style';

.horizontalBarCharts {
    $root: &;

    &__wrap {
        @include themes(
            (
                'dark': (
                    'background': $dark-gray-1,
                    'border-top-left-radius': rem(8px),
                    'border-top-right-radius': rem(8px),
                ),
                'light': (
                    'background-color': $light-gray-10,
                    'border-radius': rem(8px),
                ),
            )
        );
    }

    &__full {
        position: absolute;
        width: 98.7%;
        min-height: rem(370px);
        left: rem(10px);
        right: rem(10px);
        top: 0;

        @include themes(
            (
                'dark': (
                    'background': $dark-gray-1,
                ),
                'light': (
                    'background-color': $light-gray-10,
                ),
            )
        );

        #{$root}__wrap {
            display: flex;
            justify-content: space-around;
        }

        #{$root}__title {
            background: $dark-gray-1;
        }
    }

    &__col {
        width: 32%;
    }

    &__box {
        width: calc(33.333% - rem(16px));
        border-radius: rem(8px);

        @include themes(
            (
                'dark': (
                    'background': $dark-gray-1,
                ),
                'light': (
                    'background-color': $light-gray-10,
                ),
            )
        );
    }

    &__btn {
        height: rem(50px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom-left-radius: rem(8px);
        border-bottom-right-radius: rem(8px);
        text-decoration: underline;
        transition: color 200ms linear;
        cursor: pointer;

        @include themes(
            (
                'dark': (
                    'background': $dark-gray-1,
                    'color': #9fa5ad,
                ),
                'light': (
                    'background-color': $light-gray-10,
                    'color': $dark-blue-3,
                ),
            )
        );

        &:hover {
            @include themes(
                (
                    'dark': (
                        'color': #fff,
                    ),
                    'light': (
                        'color': $dark-green-1,
                    ),
                )
            );
        }
    }

    &__title {
        height: rem(45px);
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: rem(16px);
        padding-left: rem(5px);
        line-height: rem(19px);
        transition: background-color 200ms linear, color 200ms linear;

        @include themes(
            (
                'dark': (
                    'background': #232d3b,
                ),
                'light': (
                    'background': #fff,
                    'color': #0d0f15,
                ),
            )
        );
    }
}

.appears-horizontal-bar {
    &-enter {
        opacity: 0;
        transform: translateY(rem(30px));

        &-active {
            opacity: 1;
            transform: translateY(0);
            transition: all 600ms;
        }
    }

    &-exit {
        opacity: 1;
        transform: translateY(0);

        &-active {
            opacity: 0;
            transition: all 600ms;
            transform: translateY(rem(30px));
        }
    }
}
