@import '@styles';

.MenuExamplesPopup {
    height: 80vh;
    width: 90vw;
    position: fixed;
    top: 10vh;
    left: 5vw;
    z-index: 100;
    display: flex;
    flex-direction: column;

    @include themes(
        (
            'dark': (
                'background': $dark-gray-1,
            ),
            'light': (
                'background': #ffffff,
                'box-shadow': 0 rem(8px) rem(20px) rgba(176, 195, 208, 0.24),
                'border-radius': rem(8px),
            ),
        )
    );

    @include media-max(1040px) {
        width: 96vw;
        left: 2vw;
    }

    &__content {
        height: 100%;
    }

    &__currency {
        padding-right: rem(5px);

        svg {
            height: rem(11px);
        }
    }

    &__header {
        width: 100%;
        height: rem(40px);
    }

    &__table {
        height: calc(100% - rem(40px));
    }

    &__rows {
        position: relative;
    }

    &__loader {
        position: absolute;
        height: rem(1000px);
        bottom: 0;
        left: 0;
        width: 100%;
        pointer-events: none;
    }

    &__row {
        display: flex;
        height: 100%;
    }

    &__cell-inner {
        position: relative;
        display: inline-flex;
        padding: 0 rem(25px);
    }

    &__column {
        &:nth-child(1) {
            width: 20%;
        }

        &:nth-child(2) {
            width: 55%;
        }

        &:nth-child(3) {
            width: 10%;
            padding-left: rem(25px);
        }

        &:nth-child(4) {
            width: 15%;
        }

        @include media-max(1040px) {
            &:nth-child(2) {
                width: 52%;
            }

            &:nth-child(3) {
                width: 8%;
            }

            &:nth-child(4) {
                width: 20%;
            }
        }

        &.Cell--head {
            @include themes(
                (
                    'light': (
                        'background-color': $light-gray-20,
                    ),
                )
            );
        }
    }

    &__text {
        width: 100%;
    }

    &__icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: rem(2px);

        path,
        rect {
            @include themes(
                (
                    'light': (
                        'fill': $light-gray-90,
                    ),
                )
            );
        }

        &--right {
            left: auto;
            right: rem(2px);
        }
    }

    &__options {
        position: absolute;
        right: 0;
        top: rem(-40px);
        width: rem(40px);
        height: rem(40px);
        transition: width 300ms, background 300ms;
        background-color: transparent;
        overflow: hidden;

        &--opened {
            width: 100%;
            background-color: $dark-gray-4;
        }
    }

    &__search-btn {
        height: rem(40px);
        width: rem(40px);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 300ms;
        fill: #fff;

        &:hover {
            background: $dark-gray-5;
        }
    }

    &__close-icon {
        position: absolute;
        z-index: 2234;
        top: 50%;
        transform: translateY(-40%);
        right: 0;

        @include themes(
            (
                'dark': (
                    'stroke': #fff,
                ),
                'light': (
                    'stroke': $light-gray-90,
                ),
            )
        );

        & > svg {
            path {
                stroke: inherit;
            }
        }
    }

    &__download {
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem(20px);
        width: rem(20px);
        // border: rem(2px) solid;
        border-radius: 50%;
        transition: background-color 300ms, border-color 300ms;
        background: transparent;
        fill: #fff;
        cursor: pointer;
        position: relative;
        top: rem(10px);

        svg {
            path {
                &:first-child {
                    @include themes(
                        (
                            'dark': (
                                'stroke': #fff,
                            ),
                            'light': (
                                'stroke': $light-gray-90,
                            ),
                        )
                    );
                }
            }
        }

        &:hover {
            background: $dark-blue-3;

            svg {
                path {
                    &:first-child {
                        stroke: $dark-blue-3;
                    }
                }
            }
        }
    }

    &__actions {
        top: rem(-60px);
        position: absolute;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__search {
        position: relative;
        width: rem(330px);
    }

    &__not-found {
        position: absolute;
        z-index: 121212;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: rem(32px);
        line-height: rem(40px);

        @include themes(
            (
                'dark': (
                    'color': #4a6282,
                    'background': #232d3b,
                ),
                'light': (
                    'background-color': #fff,
                    'box-shadow': 0 rem(8px) rem(20px) rgba(176, 195, 208, 0.24),
                    'border-radius': rem(8px),
                    'color': $light-gray-20,
                ),
            )
        );
    }

    &__input {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 600;
        background-color: transparent;
        border: 0;
        height: rem(40px);
        font-size: rem(16px);
        outline: none;
        border-bottom: rem(1px) solid;
        width: 100%;
        line-height: rem(24px);
        letter-spacing: 0.012em;

        @include themes(
            (
                'dark': (
                    'color': $body-text,
                    'border-color': #fff,
                ),
                'light': (
                    'border-color': $light-gray-90,
                    'color': $light-gray-90,
                ),
            )
        );

        &::placeholder {
            @include themes(
                (
                    'dark': (
                        'color': rgba(white, 0.75),
                    ),
                    'light': (
                        'color': $light-gray-90,
                    ),
                )
            );
        }
    }

    &__cell-wrapper {
        position: relative;
        padding-left: rem(24px);
    }
}
