$base_title_color: #b8c6d8;
$base_subtitle_color: #788ba3;

@import '@assets/core.style.scss';

.Settings {
    height: calc(100vh - rem(160px));
    padding: rem(24px);

    &__scrollbar-wrapper {
        //height: calc(100vh - rem(180px));
        height: 100%;
    }

    &__more-tools {
        display: flex;
        padding-bottom: rem(16px);
    }

    &__checkbox-container {
        margin-right: rem(45px);
    }
}

.settings-section {
    position: relative;

    &--bg {
        display: flex;
        align-items: center;
        padding: rem(8px);
        background-color: #40546f;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
    }

    &__top {
        display: flex;
        align-items: center;
        padding-bottom: rem(24px);

        &--border {
            border-bottom: rem(1px) solid #4a6282;
        }
    }

    &__hint {
        padding-left: rem(10px);
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: rem(16px);
        line-height: rem(24px);
        color: #788ba3;
    }

    &__inner {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding-bottom: rem(16px);

        &--justify {
            justify-content: space-between;
            padding-bottom: 0;
        }

        &-wr {
            display: flex;

            & > div {
                &:first-child {
                    padding-right: rem(20px);
                }
            }
        }
    }

    &__left {
        display: flex;
        align-items: center;
    }

    &__right {
        margin-left: auto;
        padding: rem(8px);
    }

    &__remove-icon {
        position: absolute;
        top: 50%;
        right: rem(50px);
        margin-top: rem(-15px);
        width: rem(30px);
        height: rem(30px);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__checkbox {
        padding-right: rem(45px);
    }

    &__data-container {
        width: rem(320px);
        border-bottom: rem(1px) solid #4a6282;
    }
}

.settings-keyword {
    width: 30%;
    padding: 0 0 rem(8px);
    font-weight: 600;
    font-size: rem(16px);
    line-height: rem(24px);
    letter-spacing: 0.012em;
    color: #fff;
    margin: 0 1.5%;

    &:first-child {
        padding-left: 0;
    }

    &__title {
        margin-bottom: rem(14px);
        color: #b8c6d8;
    }

    &__input {
        background-color: #2e3e56;

        &::placeholder {
            color: #788ba3;
        }

        input {
            width: 100%;
            height: rem(40px);
            padding: rem(8px) rem(12px);
            font-size: rem(16px);
            line-height: rem(24px);
            @include themes(
                (
                    'dark': (
                        'color': #fff,
                    ),
                    'light': (
                        'color': $dark-gray-0,
                    ),
                )
            );
        }
    }

    &__container {
        width: 100%;
        height: rem(200px);
        padding: rem(4px) 0;
        overflow-y: auto;
        background-color: #283547;
        border-radius: 0 0 rem(2px) rem(2px);
        text-transform: lowercase;

        span {
            display: flex;
            padding: rem(4px) rem(8px);
        }
    }
}

.settings-textarea {
    width: 49%;
    margin-top: rem(30px);

    &__title {
        padding-bottom: rem(8px);
        color: #b8c6d8;
    }

    &__input {
        position: relative;
    }

    &__autocomplete {
        top: rem(70px);
    }

    textarea {
        width: 100%;
        padding: rem(8px) rem(12px);
        border: rem(1px) solid #4a6282;
        border-radius: rem(4px);
        font-size: rem(16px);
        line-height: rem(24px);
        resize: none;

        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': $dark-gray-0,
                ),
            )
        );

        &::placeholder {
            color: #788ba3;
        }
    }
}

.settings-input {
    width: rem(472px);
    padding: 0 rem(24px) rem(24px) 0;

    &__title {
        padding-bottom: rem(8px);
        color: #b8c6d8;
    }

    input {
        width: 100%;
        padding: rem(8px) 0;
        border-bottom: rem(1px) solid #4a6282;
        text-transform: uppercase;
        font-size: rem(16px);
        line-height: rem(24px);

        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': $dark-gray-0,
                ),
            )
        );

        &::placeholder {
            text-transform: none;
            color: #788ba3;
        }
    }
}

.settings-date {
    width: rem(320px);
    padding: 0 rem(24px) rem(24px) 0;

    &__title {
        padding-bottom: rem(8px);
        color: #b8c6d8;
    }

    input {
        width: 100%;
        padding: rem(8px) 0;
        border-bottom: rem(1px) solid #4a6282;
        font-size: rem(16px);
        line-height: rem(24px);
        color: #fff;

        &::placeholder {
            color: #788ba3;
        }
    }
}

.settings-add-images {
    width: 100%;

    $root: &;

    &:hover {
        #{$root}__title-in-box,
        #{$root}__subtitle-in-box {
            @include themes(
                (
                    'dark': (
                        'color': lighten($base_title_color, 10%),
                    ),
                    'light': (
                        'color': lighten($dark-gray-0, 10%),
                    ),
                )
            );
        }
    }

    &__title {
        padding-bottom: rem(8px);

        @include themes(
            (
                'dark': (
                    'color': $base_title_color,
                ),
                'light': (
                    'color': $dark-gray-0,
                ),
            )
        );
    }

    &__subtitle {
        font-weight: 400;
        font-size: rem(14px);
        line-height: rem(16px);
        color: $base_subtitle_color;

        &--red {
            color: #f55353;
            font-weight: 700;
        }
    }

    &__heading {
        padding-bottom: rem(12px);
        color: #fff;
    }

    &__content {
        padding: rem(24px) 0;
    }

    &__drag-drop {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        height: rem(168px);
        background-color: #1f2835;
        border: rem(1px) dashed #4a6282;
        border-radius: rem(8px);
        cursor: pointer;
    }

    &__text {
        text-align: center;
    }

    &__images {
        position: relative;
        display: flex;
    }

    &__image {
        width: rem(168px);
        height: rem(168px);
        margin-right: rem(16px);
        border-radius: rem(8px);

        img {
            width: auto;
            height: 100%;
            border-radius: rem(8px);
        }
    }

    &__add-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(168px);
        height: rem(168px);
        border: rem(1px) dashed #4a6282;
        border-radius: rem(8px);
        cursor: pointer;

        @include themes(
            (
                'dark': (
                    'background': #1f2835,
                ),
                'light': (
                    'background': $light-gray-20,
                ),
            )
        );
    }
}
