.TrackHorizontal {
    height: 6px;
    position: absolute;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.1);
}

.ThumbHorizontal {
    background: #40546f;
    border-radius: 4px;

    &:hover {
        cursor: pointer;
    }
}