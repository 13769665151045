@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@use 'sass:math';
@import '@styles';
//variables

// column's width
$first_col_width: 25%;
$first_col_width_collapsed: 40%;
$default-column: (100% - $first_col_width) / 8;
$default-column-collapsed: (100% - $first_col_width_collapsed) / 5;
$default-column-wide: $default-column * 2;
$default-column-collapsed-wide: $default-column-collapsed * 2;
$default-column-wider: $default-column * 3;
$default-column-collapsed-wider: $default-column-collapsed * 3;

.TopTrendsTable {
    position: relative;
    font-family: 'Work Sans', sans-serif;
    font-size: rem(16px);
    box-shadow: 0 rem(10px) rem(90px) rgba(0, 0, 0, 0.35);
    height: calc(100vh - #{rem(380px)});

    $root: &;

    &__selected {
        &-info {
            color: #4794ff;
            font-family: 'Nunito', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: rem(14px);
            line-height: rem(16px);
            cursor: pointer;
            position: relative;
            height: rem(30px);
            display: flex;
            align-items: center;

            &:hover {
                #{$root}__selected-keywords-list {
                    display: block;
                }
            }
        }

        &-clear-btn {
            font-size: rem(14px);
            line-height: rem(16px);
            cursor: pointer;
            font-family: 'Poppins', sans-serif;
            opacity: 0.8;

            @include themes(
                (
                    'dark': (
                        'color': #fff,
                    ),
                    'light': (
                        'color': $light-gray-70,
                    ),
                )
            );

            &:hover {
                opacity: 0.6;
            }
        }
    }

    &__loader {
        position: absolute;
        height: rem(1000px);
        bottom: 0;
        left: 0;
        width: 100%;
        pointer-events: none;
    }

    &__rows {
        position: relative;
    }

    &__copy-table-btn {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 rem(8px);
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: rem(14px);
        line-height: rem(18px);
        color: #d7e3f3;
        z-index: 2;
        height: rem(28);
        display: flex;
        align-items: center;
        transition: opacity 300ms;
    }

    &__tag-label {
        font-size: rem(16px);
    }

    &__header {
        display: flex;
        height: rem(55px);
    }

    &__table {
        height: calc(100% - #{rem(55px)});
    }

    &__row {
        display: flex;

        &:hover {
            .Cell {
                @include themes(
                    (
                        'dark': (
                            'background': $dark-gray-4,
                        ),
                        'light': (
                            'background-color': $light-gray-0,
                        ),
                    )
                );
            }
        }
    }

    &__infinite-loader {
        &::-webkit-scrollbar {
            width: rem(5px);
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(#000000, 0.4);
            border-radius: rem(3px);
        }
    }

    &__action-icon {
        $icon: &;

        transition: fill 0.3s linear;
        opacity: 0.2;
        width: 100%;
        position: relative;
        pointer-events: none;
        display: flex;
        justify-content: center;
        cursor: pointer;

        svg {
            width: auto;
            max-width: 100%;
        }

        * {
            transition: fill 100ms;
            @include themes(
                (
                    'dark': (
                        'fill': #fff,
                    ),
                    'light': (
                        'fill': rgba(#000, 0.3),
                    ),
                )
            );
        }

        &.is-active {
            opacity: 1;
            pointer-events: all;
        }

        &:hover {
            * {
                fill: #4794ff;
            }

            #{$icon}-tooltip {
                opacity: 1;
            }
        }

        &-tooltip {
            position: absolute;
            top: calc(100% + #{rem(10px)});
            left: 50%;
            transform: translateX(-50%);
            border-radius: rem(4px);
            white-space: nowrap;
            opacity: 0;
            pointer-events: none;
            z-index: 2;
            padding: rem(5px) rem(10px);
            font-size: rem(14px);
            line-height: rem(16px);
            font-family: 'Nunito', sans-serif;
            @include themes(
                (
                    'dark': (
                        'color': #fff,
                        'background-color': #283547,
                    ),
                    'light': (
                        'color': $dark-gray-1,
                        'background-color': $light-gray-20,
                    ),
                )
            );

            &:before {
                content: '';
                display: block;
                position: absolute;
                bottom: 100%;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 0;
                border-width: rem(5px) rem(5px);
                border-color: transparent;
                border-style: solid;
                @include themes(
                    (
                        'dark': (
                            'border-bottom-color': #283547,
                        ),
                        'light': (
                            'border-bottom-color': $light-gray-20,
                        ),
                    )
                );
            }
        }
    }

    &__glowing {
        cursor: pointer;

        @include themes(
            (
                'light': (
                    'color': $dark-blue-3,
                ),
            )
        );

        &:hover {
            text-shadow: 0 0 rem(8px) rgba(#fff, 0.9);

            @include themes(
                (
                    'light': (
                        'background-color': rgba($dark-blue-1, 0.1),
                    ),
                )
            );
        }
    }

    &__keyword-title {
        &.is-light {
            font-weight: 300;
        }

        &.tag-label {
            &:after {
                margin-top: rem(2px);
            }
        }
    }

    &__column {
        width: $default-column;

        #{$root}--collapsed & {
            width: $default-column-collapsed;
        }

        &:first-child {
            width: $first_col_width;
            display: flex;
            justify-content: space-between;

            #{$root}--collapsed & {
                width: $first_col_width_collapsed;
            }
        }

        &--wide {
            width: $default-column-wide;

            #{$root}--collapsed & {
                width: $default-column-collapsed-wide;
            }
        }

        &--wider {
            width: $default-column-wider;

            #{$root}--collapsed & {
                width: $default-column-collapsed-wider;
            }
        }

        &.Cell--head {
            @include themes(
                (
                    'light': (
                        'background-color': $light-gray-20,
                    ),
                )
            );
        }
    }

    &__checkbox {
        margin: 0;
        opacity: 0;
        height: 100%;
        width: 100%;
        pointer-events: none;
    }

    &__checkbox-container {
        width: 0;
        height: 100%;
        position: relative;
        overflow: hidden;
        transition: width 300ms;

        &--showing {
            animation: slide-in-right 290ms forwards;
        }

        &--hiding {
            animation: slide-in-right 290ms ease-in forwards reverse;
        }

        &--visible {
            width: rem(20px);
        }
    }

    &__checkbox-icon {
        position: absolute;
        left: 0;
        top: 50%;
        pointer-events: none;
        width: rem(14px);
        height: rem(14px);
        margin-top: rem(-7px);
        border-radius: rem(2px);
        border: rem(1px) solid $dark-gray-6;

        svg {
            top: 50%;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            visibility: hidden;
        }

        &--checked {
            background: $dark-green-3;
            border: rem(1px) solid $dark-green-3;

            svg {
                visibility: visible;
            }
        }
    }

    &__btn-drill,
    &__btn-menu {
        cursor: pointer;
    }

    &__cell-inner {
        position: relative;
    }

    &__icon {
        position: absolute;
        right: calc(100% + #{rem(8px)});
        top: 50%;
        transform: translateY(-50%);
        left: rem(-18px);

        path,
        rect {
            @include themes(
                (
                    'light': (
                        'fill': $light-gray-90,
                    ),
                )
            );
        }
    }

    &__switcher {
        font-size: rem(14px);
        font-weight: 500;
        color: gray;
        text-transform: uppercase;
        cursor: pointer;
        white-space: nowrap;
        transition: color 300ms;

        &--on {
            @include themes(
                (
                    'dark': (
                        'color': #fff,
                    ),
                    'light': (
                        'color': $dark-blue-3,
                    ),
                )
            );
        }
    }

    &__sensor {
        height: rem(500px);
        position: absolute;
        bottom: 0;
        left: 0;
        pointer-events: none;
    }

    &__actions {
        display: flex;
        padding-right: rem(20px);

        @include media-max(1400px) {
            padding-right: rem(10px);
        }
    }

    &__action {
        width: rem(30px);
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-max(1400px) {
            width: rem(20px);
        }
    }

    &__flavor-permission-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        right: rem(10px);
        height: 100%;
        max-height: 100%;
        width: calc(#{$default-column-wider} - #{rem(10px)});

        #{$root}--collapsed & {
            width: calc(#{$default-column-collapsed-wider} - #{rem(10px)});
        }
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slide-in-right {
    from {
        width: 0;
    }

    to {
        width: rem(20px);
    }
}

.TTTComplexCell {
    display: flex;
    flex-direction: column;

    &__row {
        width: 100%;
        height: rem(27.5px);
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;
    }

    &__cell {
        width: 50%;
    }

    &__dd-item {
        @include themes(
            (
                'light': (
                    'background-color': #fff,
                ),
            )
        );

        &.Cell--hv {
            &:hover {
                @include themes(
                    (
                        'light': (
                            'background-color': $dark-blue-1,
                            'color': #fff,
                        ),
                    )
                );
            }
        }
    }

    &__dd-icon {
        position: absolute;
        left: 110%;
        transform: rotate(-90deg);
        transition: transform 300ms;

        &--opened {
            transform: rotate(0deg);
        }

        path {
            @include themes(
                (
                    'light': (
                        'stroke': $light-gray-90,
                    ),
                )
            );
        }
    }

    &__drop-down {
        position: absolute;
        top: rem(27.5px);
        width: 100%;
        left: 0;
        z-index: 2;
        display: flex;
        flex-direction: column;

        @include themes(
            (
                'light': (
                    'box-shadow': 0 rem(8px) rem(20px) rgba(176, 195, 208, 0.24),
                ),
            )
        );
    }
}
