@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@import '@styles';

.StatesSelect {
    $dd_v_pad: 24px;
    $dd_h_pad: 32px;

    &__thumb {
        padding: 0 rem(32px);
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
    }

    &__thumb-icon {
        margin-left: rem(10px);
        width: rem(16px);
        height: rem(16px);
        color: currentColor;
        transform: rotate(0deg);

        &.is-rotated {
            transform: rotate(180deg);
        }
    }

    &__dropdown {
        border-radius: rem(8px);
        margin-top: rem(24px);

        @include themes(
            (
                'dark': (
                    'background': $dark-gray-3,
                ),
                'light': (
                    'background': $light-gray-0,
                ),
            )
        );
    }

    &__action-button {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    &__dropdown-header,
    &__dropdown-footer {
        display: flex;
        justify-content: flex-end;
    }

    &__dropdown-header {
        padding: rem($dd_v_pad) rem($dd_h_pad);
    }

    &__dropdown-footer {
        border-radius: 0 0 rem(8px) rem(8px);
        padding: rem(16px) rem($dd_h_pad);
        @include themes(
            (
                'dark': (
                    'background': $dark-gray-2,
                ),
                'light': (
                    'background': $light-gray-10,
                ),
            )
        );
    }

    &__dropdown-body {
        padding: 0 rem($dd_h_pad) rem(40px);
    }

    &__dropdown-section {
        padding: rem(20px);
        display: flex;
        border-radius: rem(8px);

        @include themes(
            (
                'dark': (
                    'background': $dark-gray-0,
                ),
                'light': (
                    'background': white,
                ),
            )
        );
    }

    &__dropdown-col {
        flex: 0 0 20%;
        display: flex;
        flex-direction: column;

        &.col-4 {
            flex: 0 0 25%;
        }

        &.col-3 {
            flex: 0 0 33.3%;
        }
    }

    &__dropdown-item {
        display: flex;
        padding: rem(8px) 0;
    }

    &__button {
        margin-left: rem(20px);

        &.naked {
            @include themes(
                (
                    'dark': (
                        'color': white,
                    ),
                    'light': (
                        'color': $dark-gray-1,
                    ),
                )
            );
        }
    }
}
