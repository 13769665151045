@import '@styles';

.ImageList {
    &__file-img {
        height: rem(165px);
        min-width: rem(60px);
        margin-right: rem(10px);
        border-radius: rem(10px);
    }

    &__file-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    &__file-item {
        height: rem(168px);
        position: relative;

        $root: &;

        &:hover {
            #{$root}-remove {
                opacity: 1;
            }
        }

        &-remove {
            position: absolute;
            top: 7%;
            right: rem(20px);
            height: rem(25px);
            width: rem(25px);
            transition: background-color 150ms ease, opacity 300ms;
            cursor: pointer;
            opacity: 0;

            &:hover {
                transition: transform 200ms ease;
                transform: rotate(90deg);
            }

            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                height: rem(2px);
                width: rem(25px);
                border-radius: rem(3px);
                transform-origin: center;
                background-color: #4794ff;
                transition: background-color 150ms ease;
            }

            &:before {
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &:after {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }
    }
}
