@import '@assets/core.style';

.horizontal-bar {
    white-space: nowrap;
    justify-content: space-between;
    display: flex;
    border-bottom: rem(1px) solid;

    @include themes(
        (
            'dark': (
                border-color: #283547,
            ),
            'light': (
                border-color: $light-gray-20,
            ),
        )
    );

    &--active {
        width: 32%;
        margin-right: rem(10px);
    }

    &__title {
        vertical-align: middle;
        font-weight: 400;
        font-size: rem(14px);
        padding-left: rem(15px);
        line-height: rem(16px);
        transition: color 200ms linear;
        display: flex;
        align-items: center;
        width: 30%;
        white-space: normal;

        @include themes(
            (
                'dark': (
                    'color': #d7e3f3,
                ),
                'light': (
                    'color': $light-gray-90,
                ),
            )
        );
    }

    &__value {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: rem(14px);
        line-height: rem(16px);
        transition: color 200ms linear;
        width: 15%;

        @include themes(
            (
                'dark': (
                    'color': rgba(#d7e3f3, 0.7),
                ),
                'light': (
                    'color': rgba($light-gray-70, 0.7),
                ),
            )
        );
    }

    &__element-wrap {
        width: 55%;
        display: flex;
        align-items: center;
        height: rem(50px);
        padding-left: rem(20px);
    }

    &__element {
        margin-top: rem(10px);
        margin-bottom: rem(10px);
        vertical-align: middle;
        height: rem(24px);
        display: block;
        border-radius: rem(4px);
        transition: width 1s ease-out, background 0.4s linear;

        @include themes(
            (
                'dark': (
                    'background':
                        linear-gradient(
                            270deg,
                            #34455c 0%,
                            rgba(52, 69, 92, 0) 100%
                        ),
                ),
                'light': (
                    'background':
                        linear-gradient(
                            270deg,
                            #7988b8 0%,
                            rgba(121, 136, 184, 0) 100%
                        ),
                ),
            )
        );
    }
}
