@use 'sass:math';

.SelectedCountryPopup {
    $columnsInRow: 5;

    position: relative;

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        $size: math.div(100%, $columnsInRow);

        min-width: $size;
        max-width: $size;
    }
}
