@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@import '@assets/core.style';

.Page {
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    position: relative;
    $root: &;

    &__wrapper {
        max-width: rem(1400px);
        margin: 0 auto;
        padding: rem(70px) rem(30px) rem(50px);
        box-sizing: content-box;

        #{$root}:not(.is-admin-route) & {
            padding-top: rem(20px);
            padding-bottom: rem(30px);
        }
    }

    &__no-wrapper {
        width: 100%;
    }
}
