@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@import '@styles';

.byo-table-container {
    width: 100%;
    position: relative;
    max-width: 100%;
    height: rem(290px); //header+footer+rows+trackX+padding for tooltip
    margin: 0 auto;
    font-family: 'Work Sans', sans-serif;
    color: #fff;
    transition: transform 500ms ease, $base-background-transition;
    z-index: 1;
    max-height: calc(100vh - #{rem(280px)});


    @include themes(
        (
            'dark': (
                'background': #1d202a,
            ),
            'light': (
                'background': #fff,
                'box-shadow': 0 rem(8px) rem(20px) rgba(176, 195, 208, 0.24),
                'border-radius': rem(8px),
            ),
        )
    );

    &__scrollbar {
        & .content {
            background: #1d202a;
        }
    }
}

.ScrollbarThumb {
    @include themes(
        (
            'dark': (
                'background-color': rgba(255, 255, 255, 0.4) !important,
            ),
            'light': (
                'background-color': rgba(0, 0, 0, 0.1) !important,
            ),
        )
    );
}

.table-settings {
    display: flex;
    user-select: none;

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem(20px);
        height: rem(20px);
        margin-right: rem(14px);
        border-radius: rem(1px);
        font-size: rem(16px);
        transition: opacity 300ms ease, color 300ms ease;
        cursor: default;

        $root: &;

        @include themes(
            (
                'dark': (
                    'color': #b8c6d8,
                    'opacity': 0.5,
                ),
                'light': (
                    'color': $light-gray-90,
                    'opacity': 1,
                ),
            )
        );

        &:hover {
            &:not(#{$root}--active) {
                cursor: pointer;
                opacity: 1;
            }
        }

        &--active {
            opacity: 1;

            @include themes(
                (
                    'dark': (
                        'color': #fff,
                    ),
                    'light': (
                        'color': $dark-blue-3,
                    ),
                )
            );
        }

        &--disabled {
            pointer-events: none;
            opacity: 0.5;
        }
    }
}
