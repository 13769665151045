@import '@styles';

.BarChart {
    height: 100%;
    display: flex;
    align-items: flex-end;
    position: relative;
    overflow: hidden;

    &__btn {
        position: absolute;
        padding: rem(8px) rem(15px);
        right: rem(5px);
        bottom: rem(5px);
        z-index: 10;
    }
}
