@import '@styles';

.SelectionList {
    display: inline-flex;
    position: relative;

    $root: &;
    $item_height: 40px;

    &__thumbnail {
        text-decoration: underline;
        cursor: pointer;
    }

    &__dropdown {
        position: absolute;
        top: 100%;
        opacity: 0;
        pointer-events: none;
        display: flex;
        flex-direction: column;
        min-width: rem(200px);
        border-radius: rem(4px);
        overflow: hidden;
        z-index: 2;
        transition: opacity 300ms, pointer-events 0s 1000ms;

        @include themes(
            (
                'dark': (
                    'background': $dark-gray-3,
                    'color': #fff,
                    'box-shadow': 0 rem(4px) rem(10px) 0 rgba(#000, 0.3),
                ),
                'light': (
                    'background': $light-gray-10,
                    'color': #000,
                    'box-shadow': 0 rem(4px) rem(10px) 0 rgba(#fff, 0.3),
                ),
            )
        );

        #{$root}__thumbnail:hover ~ &,
        &:hover {
            opacity: 1;
            pointer-events: all;
            transition: opacity 300ms, pointer-events 0s 0s;
        }

        #{$root}.pos-right &,
        & {
            right: rem(-30px);
        }

        #{$root}.pos-center & {
            left: 50%;
            transform: translateX(-50%);
        }

        #{$root}.pos-left & {
            left: rem(-30px);
        }

        &.is-fixed-height {
            height: rem(5.5 * $item_height);
        }
    }

    &__sizer {
        opacity: 0;
        pointer-events: none;
        padding-right: rem(10px);
    }

    &__scroller {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &__item {
        min-height: rem($item_height);
        position: relative;
        display: flex;
        align-items: center;
        border-bottom: rem(1px) solid;
        padding: rem(5px) rem(30px) rem(5px) rem(10px);
        background-color: transparent;
        transition: backgroud-color 300ms;
        font-size: rem(12px);
        line-height: rem(12px);
        white-space: nowrap;
        @include themes(
            (
                'dark': (
                    'border-color': rgba(#fff, 0.4),
                ),
                'light': (
                    'border-color': rgba(#000, 0.4),
                ),
            )
        );

        &:hover {
            @include themes(
                (
                    'dark': (
                        'background-color': rgba(#fff, 0.1),
                    ),
                    'light': (
                        'background-color': rgba(#000, 0.1),
                    ),
                )
            );
        }

        &:last-child {
            border-bottom: 0;
        }
    }

    &__remove-icon {
        position: absolute;
        top: 50%;
        right: rem(5px);
        transform: translateY(-50%);
        height: rem(20px);
        width: rem(20px);
        cursor: pointer;
        opacity: 0.5;
        transition: opacity 300ms;

        &:hover {
            opacity: 1;
        }

        &:before,
        &:after {
            $length: 10px;
            $thickness: 2px;

            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: rem(-0.5 * $length);
            margin-left: rem(-0.5 * $thickness);
            height: rem($length);
            width: rem($thickness);
            z-index: 2;
            transform-origin: 50% 50%;
            @include themes(
                (
                    'dark': (
                        'background-color': #fff,
                    ),
                    'light': (
                        'background-color': #000,
                    ),
                )
            );
        }

        &:before {
            transform: rotate(-45deg);
        }

        &:after {
            transform: rotate(45deg);
        }
    }
}
