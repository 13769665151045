@import '@assets/core.style';

.radio-input {
    $root: &;

    margin-bottom: rem(16px);

    &--blue {
        #{$root}__radio {
            input {
                &:checked {
                    & + span {
                        &:before {
                            border-color: $dark-blue-1;
                        }

                        &:after {
                            background-color: $dark-blue-1;
                        }
                    }
                }
            }
        }
    }

    &__description {
        font-size: rem(12px);

        @include themes(
            (
                'dark': (
                    'color': $dark-gray-9,
                ),
                'light': (
                    'color': $light-gray-70,
                ),
            )
        );
    }

    &__radio {
        display: block;
        cursor: pointer;

        @include themes(
            (
                'light': (
                    'color': #3a414c,
                    'opacity': 0.7,
                ),
            )
        );

        input {
            display: none;

            & + span {
                position: relative;
                display: block;
                padding-left: rem(15px);
                line-height: rem(24px);

                @include themes(
                    (
                        'dark': (
                            color: $dark-gray-8,
                        ),
                        'light': (
                            color: $dark-gray-0,
                        ),
                    )
                );

                &.has-custom-icons {
                    display: flex;
                    align-items: center;
                }

                &:not(:empty) {
                    padding-left: rem(24px);
                }

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    display: block;
                    width: rem(16px);
                    height: rem(16px);
                    margin-top: rem(-8px);
                    border-radius: 50%;
                }

                &:before {
                    border: rem(1px) solid $dark-gray-6;
                    transition: background 0.3s ease,
                        transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
                }

                &:after {
                    transform: scale(0.78);
                    transition: transform 0.6s
                        cubic-bezier(0.175, 0.885, 0.32, 1.4);
                }
            }

            &:checked {
                & + span {
                    &:before {
                        transform: scale(1);
                        border: rem(1px) solid $dark-green-3;
                    }

                    &:after {
                        transform: scale(0.6);
                        transition: transform 0.3s ease;
                        background-color: $dark-green-3;
                    }
                }
            }
        }
    }

    &__mark {
        @include themes(
            (
                'light': (
                    color: #888,
                ),
            )
        );
    }
}
