@import '@styles';

.sidebar {
    width: 100%;
    height: 100%;
    border-right: rem(1px) solid;

    @include themes(
        (
            'dark': (
                'background-color': #232d3b,
                'border-color': #2e3e56,
            ),
            'light': (
                'background-color': $light-gray-10,
                'border-color': $light-gray-20,
            ),
        )
    );

    &__btns {
        display: flex;
        align-items: center;

        button {
            &:not(:last-child) {
                transition: transform 100ms linear;

                &:hover {
                    transform: scale(1.1);
                    cursor: pointer;
                }
            }
        }
    }

    &__content {
        //height: calc(100% - rem(80px));
        height: 100%;
        overflow-y: auto;

        .Flag {
            @include themes(
                (
                    'light': (
                        color: $dark-gray-0,
                    ),
                )
            );
        }
    }

    &__empty {
        display: flex;
        justify-content: center;
        align-self: center;
        width: 100%;
        height: 100%;
        padding: rem(32px);
        text-align: center;
        font-size: rem(32px);
        line-height: rem(40px);
        color: #4a6282;
    }
}

.add-option {
    padding: rem(12px) rem(16px);
    background-color: #40546f;

    &__input {
        width: calc(100% - rem(60px));
    }
}
