@import '@assets/core.style';

.tableInfo {
    color: rgba(#fff, 0.5);
    font-family: 'work sans', sans-serif;

    &--collapsed {
        width: 76%;
    }

    &__info {
        font-size: rem(24px);
        line-height: 1.5;
        transform: translateY(0);
        transition: transform 300ms, opacity 300ms;

        @include themes(
            (
                'light': (
                    'color': $light-gray-90,
                ),
            )
        );

        &--loading {
            opacity: 0;
        }
    }

    &__count {
        font-weight: 900;
    }

    &__count,
    &__drilled {
        color: $dark-blue-1;
    }

    &__drilled-list {
        min-height: rem(40px);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    &__drilled {
        font-weight: 400;
    }

    &__drilled-item {
        color: $dark-blue-1;
        position: relative;
        padding: rem(3px) rem(17px) rem(3px) rem(7px);
        margin-top: rem(5px);
        margin-bottom: rem(5px);
        border-radius: rem(13px);
        font-size: rem(14px);
        height: rem(26px);
        display: flex;
        align-items: center;
        font-weight: 500;
        transition: background 300ms;
        cursor: pointer;

        @include themes(
            (
                'dark': (
                    'background': $dark-gray-3,
                ),
                'light': (
                    'background-color': rgba($dark-blue-1, 0.1),
                ),
            )
        );

        &:hover {
            background: $dark-gray-4;
        }

        &:not(:first-child) {
            margin-left: rem(10px);
        }
    }

    &__icon-container {
        position: absolute;
        top: 50%;
        right: rem(5px);
        transform: translateY(-50%);
        width: rem(8px);
        height: rem(8px);
        display: block;
        cursor: pointer;

        &:after,
        &:before {
            content: '';
            height: rem(1px);
            width: 100%;
            background-color: $dark-blue-1;
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            transform-origin: 50% 50%;
        }

        &:before {
            transform: translateY(-50%) rotate(45deg);
        }

        &:after {
            transform: translateY(-50%) rotate(130deg);
        }
    }
}
