@import '@assets/core.style';

.menu-items {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: 'Nunito', sans-serif;
    animation: appear 300ms ease-in;

    $root: &;

    &__header {
        position: relative;
        display: flex;
        align-items: flex-start;
        height: rem(100px);
        transition: transform 300ms ease;
        flex: 1 0 auto;

        &--center {
            transform: translateY(#{rem(180px)});
        }
    }

    &__content {
        height: rem(300px);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &__col {
        width: 20%;
        display: flex;
        flex-wrap: wrap;
    }

    &__input-header {
        width: 100%;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: rem(16px);
        line-height: rem(24px);
        margin-bottom: rem(16px);

        @include themes(
            (
                'dark': (
                    color: #d7e3f3,
                ),
                'light': (
                    color: $light-gray-90,
                ),
            )
        );

        @media all and (max-width: rem(1440px)) {
            font-size: rem(14px);
            line-height: rem(14px);
        }
    }

    &__checkbox-container {
        position: relative;
        display: flex;
        align-items: center;
    }

    &__arrow {
        width: rem(7.5px);
        height: rem(12px);
        margin-left: rem(12px);
        transform-origin: center;
        transition: transform 300ms ease;
    }

    &__text {
        width: 100%;
        margin-top: rem(8px);
        font-size: rem(14px);
        line-height: rem(16px);

        @include themes(
            (
                'dark': (
                    color: $dark-gray-10,
                ),
                'light': (
                    color: $light-gray-40,
                ),
            )
        );

        @media all and (max-width: 1440px) {
            font-size: rem(12px);
            line-height: rem(14px);
        }

        &--selected {
            @include themes(
                (
                    'dark': (
                        'color': #fff,
                    ),
                )
            );
        }
    }

    &__additional-filters {
        font-size: rem(14px);
        line-height: rem(24px);
        color: rgba(#fff, 0.5);

        @media all and (max-width: 1440px) {
            font-size: rem(14px);
            line-height: rem(16px);
        }

        button {
            margin-bottom: rem(8px);
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: rem(18px);
            line-height: rem(14px);
            text-decoration: underline;
            cursor: pointer;

            @include themes(
                (
                    'dark': (
                        color: #b8c6d8,
                    ),
                    'light': (
                        color: $dark-blue-1,
                    ),
                )
            );

            @media all and (max-width: 1440px) {
                font-size: rem(14px);
                line-height: rem(16px);
            }
        }
    }

    &__pre-footer {
        position: relative;

        &-title {
            font-weight: 500;
            font-size: rem(16px);
            line-height: rem(18px);
            padding-bottom: rem(8px);

            @include themes(
                (
                    'dark': (
                        'color': #ffffff,
                    ),
                    'light': (
                        'color': $light-gray-90,
                    ),
                )
            );

            &--message {
                width: rem(300px);
            }
        }

        &-description {
            font-weight: 400;
            font-size: rem(14px);
            line-height: rem(18px);

            @include themes(
                (
                    'dark': (
                        'color': rgba(#ffffff, 0.7),
                    ),
                    'light': (
                        'color': $light-gray-70,
                    ),
                )
            );
        }
    }

    .checkbox-input__arrow {
        margin-left: rem(16px);
    }

    &--is-full {
        margin-bottom: rem(36px);

        #{$root}__footer {
            padding-right: 0;
        }

        #{$root}__header {
            height: auto;
        }

        #{$root}__sub-header {
            border-bottom: none;
        }

        #{$root}__checkbox-container {
            .checkbox-input {
                &__arrow {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}
