@import '@styles';

.LineEllipsis {
    position: relative;
    width: 100%;

    $root: &;

    &:hover {
        #{$root}__expanded {
            opacity: 1;
            pointer-events: auto;

            &--hidden {
                opacity: 0;
                pointer-events: none;
            }
        }
    }

    &__expanded {
        width: 100%;
        opacity: 0;
        top: rem(-8px);
        left: rem(-8px);
        position: absolute;
        z-index: 1;
        pointer-events: none;
        border-radius: rem(2px);
        box-sizing: content-box;
        padding: rem(8px);
        transition: opacity 300ms;

        @include themes(
            (
                'dark': (
                    'background': darken(#232d3b, 5%),
                    'box-shadow': 0 rem(4px) rem(10px) rgba(#000, 0.4),
                    'color': #fff,
                ),
                'light': (
                    'background': darken(#fff, 5%),
                    'box-shadow': 0 rem(4px) rem(10px) rgba(#fff, 0.4),
                    'color': #000,
                ),
            )
        );

        &:after {
            content: '';
            display: block;
            position: fixed;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            pointer-events: none;
            //background: rgba(#000, 0.7);
            //backdrop-filter: blur(rem(2px));
        }
    }
}
