@import '@styles';

.auto-complete {
    position: absolute;
    top: rem(45px);
    left: 0;
    right: 0;
    height: rem(210px);
    border-radius: 0 0 rem(3px) rem(3px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: rem(20px);
    z-index: 101;
    filter: drop-shadow(0 rem(4px) rem(10px) rgba(0, 0, 0, 0.1));

    @include themes(
        (
            'dark': (
                'background': #171e28,
            ),
            'light': (
                'background': $light-gray-0,
            ),
        )
    );

    &__word {
        padding: 0 rem(10px);
        width: 100%;
        text-align: left;
        background-color: transparent;
        border: 0;
        outline: none;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        transition: background 300ms ease, color 300ms ease;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 300;

        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': #000,
                ),
            )
        );

        .filters-auto-complete & {
            padding: rem(4px) rem(8px);
            font-size: rem(14px);
            line-height: rem(16px);

            @media all and (max-width: 1440px) {
                font-size: rem(12px);
            }
        }

        .small-auto-complete & {
            font-size: rem(14px);
            line-height: rem(18px);
            padding: rem(5px) rem(5px) 0;
        }

        &:hover,
        &--active {
            @include themes(
                (
                    'dark': (
                        'background': $dark-gray-4,
                    ),
                    'light': (
                        'background': $light-gray-10,
                    ),
                )
            );
        }

        &:last-child {
            margin-bottom: 0;

            .small-auto-complete & {
                padding-bottom: rem(5px);
            }
        }

        &--white {
            font-weight: 600;
        }
    }

    &-enter,
    &-exit-done {
        opacity: 0;
    }

    &-enter-active {
        transition: opacity 300ms ease;
        opacity: 1;
    }

    &-enter-done,
    &-exit {
        opacity: 1;
    }

    &-exit-active {
        transition: opacity 300ms ease;
        opacity: 0;
    }
}

.filters-auto-complete {
    border-top: rem(1px) solid;
    height: calc(100% - rem(40px));
    top: rem(40px);
    padding: rem(4px) 0;

    @include themes(
        (
            'dark': (
                'border-color': #363b4e,
            ),
            'light': (
                'border-color': $light-gray-20,
            ),
        )
    );
}

.small-auto-complete {
    top: rem(40px);
    height: rem(130px);
    padding: rem(8px);
    border-radius: 0 0 rem(4px) rem(4px);
}
