@import '@assets/core.style';

.ColorModeRadiobtn {
    $root: &;
    $trf-origin: 0% 0%;
    $trs-time: 400ms;

    display: block;
    position: relative;
    user-select: none;
    width: rem(32px);
    height: 100%;
    border-radius: rem(10px);
    transition: all $trs-time linear;

    &__input {
        position: absolute;
        visibility: hidden;
        pointer-events: none;
    }

    &__icon {
        position: absolute;
        right: rem(2px);
        top: rem(2px);
        height: rem(12px);
        width: rem(12px);
        background: $light-gray-100;
        border-radius: 50%;
        transform: translateX(0);
        transition: all $trs-time linear;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            background: #fff;
            width: 100%;
            height: 100%;
            transform-origin: $trf-origin;
            transform: scale(1) translateY(-70%) translateX(-40%);
            border-radius: 50%;
            transition: all $trs-time linear;
        }
    }

    &--dark {
        background: #fff;
    }

    &--light {
        background: $light-gray-100;

        #{$root}__icon {
            background: #fff;
            transform: translateX(calc(-100% - rem(3px)));

            &:after {
                transform-origin: $trf-origin;
                transform: scale(0) translateY(-70%) translateX(-40%);
                background: $light-gray-100;
            }
        }
    }
}
