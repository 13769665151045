@import '@styles';

.DropZone {
    &__container {
        display: flex;
        width: 100%;
        min-height: rem(190px);
        border: rem(1px) dashed #4a6282;
        border-radius: rem(8px);
        cursor: pointer;
        padding: rem(10px);

        @include themes(
            (
                'dark': (
                    'background': #1f2835,
                ),
                'light': (
                    'background': $light-gray-10,
                ),
            )
        );

        &:focus {
            outline: none;
        }
    }

    &__content {
        display: flex;
    }

    &__file-list {
        display: flex;
    }

    &__btn-add {
        width: 100%;
        height: rem(190px);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &:focus {
            outline: none;
        }
    }
}
