@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@import '@assets/core.style';

.InstantCharts {
    position: relative;
    padding-top: rem(16px);
    height: calc(100vh - #{rem(200px)});

    &__header {
        display: flex;
        justify-content: space-between;
        height: rem(50px);
    }

    &__board-title {
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: rem(24px);
        line-height: rem(32px);
        transition: color 300ms ease;

        @include themes(
            (
                'light': (
                    'color': $light-gray-90,
                ),
            )
        );
    }

    &__btn {
        width: rem(96px);
        margin-bottom: rem(16px);
        padding: rem(5px) rem(10px);
        background-color: #4794ff;
        color: #fff;
        border-radius: rem(4px);
        border: none;
        font-family: 'Nunito', sans-serif;
        font-size: rem(16px);
        line-height: rem(22px);
        text-align: center;
        cursor: pointer;
    }
}
