@import '@assets/core.style';

.bar {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    transition: all 0.3s linear;
    transform-origin: top center;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &__currency {
        padding-right: rem(3px);
    }

    &__elem {
        background: #fff;
        border-radius: rem(4px);
        transition: height 300ms linear;
        height: 0;
    }

    &__value {
        height: rem(40px);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: $base-background-transition;

        @include themes(
            (
                'light': (
                    'color': #0e1015,
                    'opacity': 0.7,
                ),
            )
        );
    }

    &__title {
        height: rem(40px);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: $base-background-transition;

        @include themes(
            (
                'light': (
                    'color': #0e1015,
                    'opacity': 0.7,
                ),
            )
        );
    }
}
