@import '@styles';

.saveByoSettingsPopup {
    height: 100vh;
    position: relative;

    $root: &;

    &__inner {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: rem(608px);
        width: 100%;
        margin: auto;
        padding: rem(24px);
        box-shadow: 0 rem(4px) rem(20px) rgba(0, 0, 0, 0.1);
        border-radius: rem(8px);
        font-family: 'Work Sans', sans-serif;
        color: #fff;
        transform: translate(-50%, -50%);
        z-index: 101;

        @include themes(
            (
                'dark': (
                    'background': $dark-gray-2,
                ),
                'light': (
                    'background': #fff,
                ),
            )
        );
    }

    &__btn {
        margin-left: auto;
    }

    &__report_name {
        position: relative;
        margin-top: rem(8px);
        padding: rem(8px) rem(16px) rem(8px) 0;
        border-bottom: rem(1px) solid;

        @include themes(
            (
                'dark': (
                    'border-color': $dark-gray-6,
                ),
                'light': (
                    'border-color': $light-gray-20,
                ),
            )
        );
    }

    &__name-input {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        caret-color: #fff;
        font-family: 'Nunito', sans-serif;
        font-size: rem(20px);
        line-height: rem(28px);

        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': $light-gray-90,
                ),
            )
        );

        &::placeholder {
            @include themes(
                (
                    'dark': (
                        'color': $dark-gray-6,
                    ),
                    'light': (
                        'color': $light-gray-40,
                    ),
                )
            );
        }

        &:focus {
            outline: none;

            @include themes(
                (
                    'light': (
                        'color': rgba(#3a414c, 0.7),
                    ),
                )
            );
        }
    }

    &__name-label {
        position: absolute;
        top: rem(8px);
        left: 0;
        font-size: rem(16px);
        line-height: rem(28px);
        color: $dark-gray-6;
        transition: transform 300ms, font-size 300ms, color 300ms;
        pointer-events: none;

        #{$root}__input:focus + &,
        #{$root}__name-input--filled + & {
            transform: translate(0, rem(-18px));
            font-size: rem(12px);
            line-height: rem(16px);
            color: $dark-gray-5;
        }
    }

    &__report-description {
        position: relative;
        margin: rem(24px) 0;
    }

    &__description-label {
        position: absolute;
        top: rem(8px);
        left: rem(12px);
        font-size: rem(16px);
        line-height: rem(24px);
        color: $dark-gray-6;
        transition: transform 300ms, font-size 300ms, color 300ms;
        pointer-events: none;

        #{$root}__descr-input:focus + &,
        #{$root}__descr-input--filled + & {
            transform: translate(rem(-12px), rem(-28px));
            font-size: rem(12px);
            line-height: rem(16px);
            color: $dark-gray-5;
        }
    }

    &__descr-input {
        width: 100%;
        height: rem(120px);
        border: rem(1px) solid;
        padding: rem(8px) rem(12px);
        background: transparent;
        resize: none;
        caret-color: #fff;
        font-family: 'Nunito', sans-serif;
        font-size: rem(16px);
        line-height: rem(24px);

        @include themes(
            (
                'dark': (
                    'color': #fff,
                    'border-color': $dark-gray-6,
                ),
                'light': (
                    'color': $light-gray-40,
                    'border-color': $light-gray-20,
                ),
            )
        );

        &::placeholder {
            font-family: 'Nunito', sans-serif;

            @include themes(
                (
                    'dark': (
                        'color': $dark-gray-6,
                    ),
                    'light': (
                        'color': $light-gray-40,
                    ),
                )
            );
        }

        &:focus {
            outline: none;

            @include themes(
                (
                    'light': (
                        'caret-color': rgba(#3a414c, 0.7),
                    ),
                )
            );
        }
    }

    &__radio-wr {
        display: flex;
        align-items: baseline;

        &--center {
            align-items: center;
        }
    }

    &__subtitle {
        width: rem(160px);
        text-align: right;
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: rem(16px);
        line-height: rem(24px);
        letter-spacing: 0.012em;

        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': $light-gray-90,
                ),
            )
        );
    }

    &__btns {
        padding-left: rem(20px);

        .radio-input__radio {
            input {
                & + span {
                    @include themes(
                        (
                            'light': (
                                'font-weight': 600,
                                'color': $light-gray-90,
                            ),
                        )
                    );
                }
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: rem(48px);

        .Button {
            font-family: 'Nunito', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: rem(16px);
            line-height: rem(32px);
            text-align: center;
            letter-spacing: 0.012em;
        }
    }

    &__radio-input-wrapper {
        margin-bottom: rem(12px);

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__checkbox-container {
        position: relative;

        .radio-input {
            position: absolute;
            top: 0;
            left: 0;
            margin: 0;
            opacity: 0;
            z-index: 3;

            &__radio {
                input {
                    & + span {
                        &:before,
                        &:after {
                            top: 0;
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }

    &__checkbox-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(16px);
        height: rem(16px);
        border-radius: rem(2px);
        border: rem(1px) solid $dark-gray-6;
        transition: background-color 300ms;

        svg {
            opacity: 0;
            stroke: #fff;
            transition: opacity 300ms;
        }

        &--checked {
            background-color: $dark-green-3;

            svg {
                opacity: 1;
            }
        }
    }

    &__btn-cancel {
        height: rem(32px);
        background-color: transparent;
        transition: transform 300ms;

        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': $dark-gray-0,
                ),
            )
        );

        &:hover {
            background-color: transparent;
            transform: scale(1.1);
        }
    }

    &__btn-apply {
        width: rem(96px);
        height: rem(32px);
        background-color: $dark-blue-1;
        border-radius: rem(48px);
        transition: background-color 300ms;

        &:hover {
            background-color: $dark-green-3;
        }
    }
}
