@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@import '@styles';

.tiles-container {
    position: relative;
    width: 100%;
    perspective: rem(3000px);
    overflow: hidden;
    height: calc(100vh - #{rem(250px)});
}
