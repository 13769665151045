@import '@assets/core.style';

.overlay {
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 100;
    transition: background 300ms;

    @include themes(
        (
            'dark': (
                'background-color': rgba(0, 0, 0, 0.9),
            ),
            'light': (
                'background-color': rgba(237, 241, 248, 0.95),
            ),
        )
    );
}
