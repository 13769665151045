@import '@styles';

.RegionalPenetration {
    justify-content: space-between;
    align-items: center;
    padding-bottom: rem(50px);

    $root: &;

    &__info {
        display: flex;
        justify-content: space-between;
        max-width: rem(990px);
        width: 100%;
        margin: auto;
        padding-left: rem(10px);
        padding-right: rem(10px);
    }

    &__wrap {
        display: flex;
        flex-direction: column;
        max-width: rem(1220px);
        width: 100%;
        margin: auto;
    }

    &__map {
        display: flex;
        justify-content: center;
        min-height: rem(503px);

        img {
            width: rem(870px);
        }
    }

    &__info-row {
        display: flex;
        align-items: center;
        margin-bottom: rem(40px);

        #{$root}__circle {
            display: inline-block;
            width: rem(48px);
            height: rem(48px);
            border-radius: 50%;
            background: #939dc2;
            margin-right: rem(24px);

            @include themes(
                (
                    'light': (
                        'border': rem(1px) solid #fff,
                    ),
                )
            );

            & ~ span {
                $font: 'Nunito', sans-serif;

                font-style: normal;
                font-size: rem(16px);
                line-height: rem(20px);

                @include themes(
                    (
                        'dark': (
                            'font-weight': normal,
                        ),
                        'light': (
                            'font-family': $font,
                            'font-weight': 600,
                            'letter-spacing': 0.012em,
                            'color': $light-gray-90,
                        ),
                    )
                );
            }
        }

        &--west {
            #{$root}__circle {
                @include themes(
                    (
                        'dark': (
                            'background': #283547,
                        ),
                        'light': (
                            'background-color': $light-gray-30,
                        ),
                    )
                );
            }
        }

        &--midwest {
            #{$root}__circle {
                @include themes(
                    (
                        'dark': (
                            'background': #363b4d,
                        ),
                        'light': (
                            'background-color': $light-gray-20,
                        ),
                    )
                );
            }
        }

        &--south {
            #{$root}__circle {
                @include themes(
                    (
                        'dark': (
                            'background': #686f8b,
                        ),
                        'light': (
                            'background-color': $light-gray-10,
                        ),
                    )
                );
            }
        }

        &--east {
            #{$root}__circle {
                @include themes(
                    (
                        'dark': (
                            'background': #939dc2,
                        ),
                        'light': (
                            'background-color': $light-gray-40,
                        ),
                    )
                );
            }
        }
    }
}
