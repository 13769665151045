@import '@assets/core.style';

.dropdown-menu {
    position: relative;
    border-radius: rem(3px);
    overflow: hidden;
    z-index: 99;

    $root: &;

    @include themes(
        (
            'dark': (
                'box-shadow': 0 rem(10px) rem(90px) rgba(0, 0, 0, 0.35),
            ),
            'light': (
                'box-shadow': 0 rem(4px) rem(20px) rgba(0, 0, 0, 0.2),
            ),
        )
    );

    &--wide {
        #{$root}__item {
            padding: 0 rem(95px);
        }
    }

    &--top {
        transform: translate(-50%, -100%);
        box-shadow: 0 rem(4px) rem(30px) rgba(0, 0, 0, 0.16);
        border-radius: rem(8px);

        & #{$root}__item {
            height: rem(40px);
            background-color: $dark-gray-2;
            font-size: rem(16px);
        }
    }

    &__item {
        $trs-light: color 300ms linear, $base-background-transition;

        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: rem(35px);
        padding: 0 rem(35px);
        border-bottom: rem(1px) solid;
        font-family: 'Nunito', sans-serif;
        font-size: rem(14px);
        cursor: pointer;
        transition: background-color 300ms ease;

        @include themes(
            (
                'dark': (
                    'background-color': #212430,
                    'border-color': rgba(0, 0, 0, 0.3),
                    'color': #fff,
                ),
                'light': (
                    'background': #fff,
                    'border-color': $light-gray-10,
                    'color': $light-gray-90,
                    'transition': $trs-light,
                ),
            )
        );

        &:last-child {
            border-bottom: 0;
        }

        &:hover {
            @include themes(
                (
                    'dark': (
                        'background-color': $dark-blue-1,
                    ),
                    'light': (
                        'background-color': $dark-blue-1,
                        'color': #fff,
                    ),
                )
            );
        }
    }
}
