@use 'sass:math';
@import '@styles';

$border_style: rem(1px) solid $dark-gray-4;

@keyframes cell-loading {
    from {
        background-position: 100% 0;
    }

    to {
        background-position: -50% 0;
    }
}

.Cell {
    padding: 0 rem(20px);
    display: flex;
    align-items: center;
    border-bottom: $border_style;
    width: 100%;
    position: relative;
    height: rem(50px);
    font-size: rem(16px);
    transition: background-color 300ms;
    background: $dark-gray-2;
    color: $body-text;

    $root: &;

    @include themes(
        (
            'light': (
                'background-color': #fff,
                'color': $light-gray-70,
                'border-color': $light-gray-10,
            ),
        )
    );

    b {
        font-weight: 900;

        @include themes(
            (
                'dark': (
                    'color': orange,
                ),
                'light': (
                    'color': $dark-blue-1,
                ),
            )
        );
    }

    i {
        @include themes(
            (
                'dark': (
                    'color': orange,
                ),
                'light': (
                    'color': $dark-blue-1,
                ),
            )
        );
    }

    &--dsbl {
        pointer-events: none;
    }

    &--high {
        height: rem(60px);
    }

    &--lighter {
        color: rgba($body-text, 0.6);
    }

    &--bold {
        color: #fff;
        font-weight: 500;
    }

    &--hv {
        &:hover {
            cursor: pointer;

            @include themes(
                (
                    'dark': (
                        'background': $dark-gray-4,
                    ),
                    'light': (
                        'background-color': $light-gray-0,
                    ),
                )
            );
        }
    }

    &--load {
        &:before {
            content: '';
            display: block;
            width: 80%;
            height: 40%;
            background-image: linear-gradient(
                135deg,
                transparent 0%,
                rgba($dark-gray-4, 0.4) 50%,
                transparent 60%
            );
            animation: cell-loading 2s infinite;
            background-size: 500%;
            background-repeat: no-repeat;
            transition: background 300ms;
            border-radius: rem(5px);

            @include themes(
                (
                    'dark': (
                        'background-color': $dark-gray-3,
                    ),
                    'light': (
                        'background-color': $light-gray-10,
                    ),
                )
            );
        }
    }

    &:after {
        display: block;
        position: absolute;
        bottom: rem(-1px);
        width: rem(24px);
        height: rem(1px);

        @include themes(
            (
                'dark': (
                    'background': $dark-gray-2,
                ),
                'light': (
                    'background-color': #fff,
                ),
            )
        );
    }

    &--first {
        &:after {
            content: '';
            left: 0;
        }
    }

    &--last {
        &:after {
            content: '';
            right: 0;
        }
    }

    &--justify {
        justify-content: space-between;
        //padding-right: 0;
    }

    &--head {
        border-bottom-color: transparent;
        height: 100%;
        font-size: rem(16px);
        line-height: 1;

        @include themes(
            (
                'dark': (
                    'background': $dark-gray-3,
                    'font-weight': 700,
                ),
                'light': (
                    'background-color': $light-gray-20,
                    'font-weight': 500,
                    'color': $light-gray-90,
                    'border': transparent,
                ),
            )
        );

        path {
            &[stroke] {
                @include themes(
                    (
                        'light': (
                            'stroke': $light-gray-90,
                        ),
                    )
                );
            }

            &[fill] {
                @include themes(
                    (
                        'light': (
                            'fill': $light-gray-90,
                        ),
                    )
                );
            }
        }

        @media all and (max-width: $root-responsive-mq) {
            font-size: rem(14px);
        }

        @media all and (max-width: 1600px) {
            font-size: rem(12px);
        }

        &#{$root}--hv {
            &:hover {
                @include themes(
                    (
                        'dark': (
                            'background': $dark-gray-4,
                        ),
                        'light': (
                            'background-color': $light-gray-10,
                        ),
                    )
                );
            }
        }
    }

    &--header {
        @include themes(
            (
                'dark': (
                    'background': $dark-gray-4,
                ),
                'light': (
                    'background-color': $light-gray-40,
                    'border': transparent,
                ),
            )
        );

        &#{$root}--hv {
            &:hover {
                background: $dark-gray-5;
            }
        }
    }

    &--header40 {
        background: rgba($dark-gray-4, 0.4);

        &#{$root}--hv {
            &:hover {
                background: $dark-gray-4;
            }
        }
    }

    &--headest {
        @include themes(
            (
                'dark': (
                    'background': $dark-gray-5,
                ),
                'light': (
                    'background-color': $light-gray-30,
                    'border': transparent,
                ),
            )
        );

        &#{$root}--hv {
            &:hover {
                background: $dark-gray-6;
            }
        }
    }

    &--hl {
        @include themes(
            (
                'dark': (
                    'background': $dark-gray-3,
                    'border-bottom-color': $dark-gray-4,
                ),
                'light': (
                    'background-color': $light-gray-10,
                    'border-bottom-color': $light-gray-10,
                ),
            )
        );

        &#{$root}--hlhv {
            &:hover {
                cursor: pointer;

                @include themes(
                    (
                        'dark': (
                            'background': $dark-gray-3,
                            'text-shadow': 0 0 rem(8px) rgba(#fff, 0.9),
                        ),
                        'light': (
                            'background-color': $light-gray-10,
                            'color': $dark-blue-1,
                            'text-shadow': 0 0 rem(10px) rgba(71, 148, 255, 0.4),
                        ),
                    )
                );
            }
        }

        &:after {
            content: none;
        }
    }

    &--hl2 {
        background: $dark-gray-3;
        border-bottom-color: $dark-gray-4;

        &#{$root}--hv {
            &:hover {
                background: $dark-gray-4;
                border-bottom-color: $dark-gray-5;
            }
        }

        &:after {
            content: none;
        }
    }

    &--a {
        &#{$root}--hv {
            &:hover {
                background: $dark-gray-4;
            }
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            height: calc(100% + rem(1px));
            width: rem(3px);
            background: $dark-red-1;
        }
    }

    &--white {
        color: #fff;
    }

    &--gray {
        color: rgba(#d7e3f3, 0.7);
    }

    &--no-bd {
        border-bottom: 0;
    }

    &--no-p {
        padding: 0;
    }

    &--no-pl {
        padding-left: 0;
    }

    &--no-pr {
        padding-right: 0;
    }

    &--center {
        justify-content: center;
        text-align: center;
    }

    &--right {
        justify-content: flex-end;
        text-align: right;
    }

    &--bd-right {
        border-right: $border_style;
    }

    &--bdb-light {
        border-bottom: rem(1px) solid rgba($dark-gray-3, 0.5);
    }

    &--darken {
        @include themes(
            (
                'dark': (
                    'box-shadow': inset 0 0 0 rem(300px) $darken,
                ),
                'light': (
                    'box-shadow': inset 0 0 0 rem(300px)
                        rgba($light-gray-10, 0.5),
                ),
            )
        );

        &#{$root}--head {
            @include themes(
                (
                    'dark': (
                        'border-bottom-color': $darken,
                    ),
                    'light': (
                        'border-bottom-color': rgba($light-gray-10, 0.5),
                    ),
                )
            );
        }

        &:after {
            @include themes(
                (
                    'dark': (
                        'box-shadow': inset 0 0 0 rem(300px) $darken,
                    ),
                    'light': (
                        'box-shadow': inset 0 0 0 rem(300px)
                            rgba($light-gray-10, 0.5),
                    ),
                )
            );
        }
    }

    &--dt {
        color: rgba($body-text, 0.5);
    }

    &--fs-small {
        font-size: rem(14px);
        line-height: rem(16px);
        font-weight: 600;
    }

    &--fw-normal {
        font-weight: 400;
    }

    .Flag {
        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': $dark-gray-0,
                ),
            )
        );
    }
}
