@import '@assets/core.style';

.keyword-menu {
    position: absolute;
    border-radius: rem(8px);
    overflow: hidden;

    @include themes(
        (
            'dark': (
                'background-color': $dark-gray-2,
                'box-shadow': 0 rem(4px) rem(20px) rgba(0, 0, 0, 0.25),
            ),
            'light': (
                'background': #ffffff,
                'box-shadow': 0 rem(4px) rem(20px) rgba(0, 0, 0, 0.08),
            ),
        )
    );

    &__item {
        display: flex;
        align-items: center;
        width: 100%;
        height: rem(40px);
        border-bottom: rem(1px) solid;
        padding: 0 rem(10px);
        text-align: left;
        text-decoration: none;
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: rem(16px);
        line-height: rem(24px);
        cursor: pointer;
        transition: background-color 150ms ease;

        @include themes(
            (
                'dark': (
                    'border-color': $dark-gray-6,
                    'color': $dark-gray-8,
                ),
                'light': (
                    'border-color': $light-gray-20,
                    'color': $light-gray-70,
                ),
            )
        );

        * {
            @include themes(
                (
                    'dark': (
                        'fill': $dark-gray-8,
                    ),
                    'light': (
                        'fill': $light-gray-70,
                    ),
                )
            );
        }

        a {
            text-decoration: none;
            color: $dark-gray-8;
        }

        &:hover {
            background-color: $dark-blue-1;
            color: #ffffff;

            * {
                fill: #ffffff;
            }

            a {
                color: #ffffff;
            }
        }

        &:last-child {
            border-bottom: 0;
        }
    }
}
