@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@import '@assets/core.style';

.TopTrends {
    padding: rem(20px) rem(60px) rem(80px);
    position: relative;

    $root: &;
    @media all and (max-width: 1440px) {
        padding: rem(20px) rem(20px);
    }

    &__row {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: rem(10px);

        &--mh {
            min-height: rem(90px);
        }
    }

    &__col {
        position: relative;
        display: flex;
        align-items: center;

        &--mh {
            min-height: rem(40px);
        }

        & > * {
            margin-right: rem(40px);
        }

        &--right {
            width: rem(450px);
            justify-content: flex-end;

            & > * {
                margin-right: 0;
                margin-left: rem(20px);
            }
        }
    }

    &__search {
        width: 100%;
    }

    &__category-button {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: rem(4px) rem(23px);
        height: rem(32px);
        background: $dark-blue-1;
        color: #fff;
        border-radius: rem(16px);
        font-family: 'Nunito', sans-serif;
        font-size: rem(16px);
        line-height: rem(24px);
        font-weight: 700;
        cursor: pointer;
        transition: background-color 300ms;

        &:hover {
            background: $dark-green-1;
        }

        svg {
            height: 100%;
            margin-left: rem(5px);
            fill: #fff;
        }
    }

    &__categories-popup {
        position: absolute;
        right: 0;
        top: calc(100% + #{rem(5px)});
        z-index: 3;
    }

    &__select-settings-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: rem(10px);

        @include themes(
            (
                'dark': (
                    'color': rgba(#fff, 0.7),
                ),
                'light': (
                    'color': $light-gray-30,
                ),
            )
        );
    }

    &__select-btn {
        cursor: pointer;
        padding: 0 rem(10px);

        &:hover {
            @include themes(
                (
                    'dark': (
                        'color': #fff,
                    ),
                    'light': (
                        'color': $light-gray-90,
                    ),
                )
            );
        }

        &--active {
            cursor: default;

            @include themes(
                (
                    'dark': (
                        'color': #fff,
                    ),
                    'light': (
                        'color': $light-gray-90,
                    ),
                )
            );

            &:hover {
                cursor: default;
            }
        }
    }

    &__sidebar {
        width: rem(400px);

        @media all and (max-width: 1440px) {
            width: rem(320px);
        }

        @media all and (max-width: 1200px) {
            width: rem(280px);
        }
    }

    &__table {
        width: calc(100% - #{rem(450px)});
        position: relative;
        padding-top: rem(44px);

        @media all and (max-width: 1440px) {
            width: calc(100% - #{rem(340px)});
        }

        @media all and (max-width: 1200px) {
            width: calc(100% - #{rem(300px)});
        }
    }

    &__table-info {
        width: 100%;

        &__btn-copy-table {
            display: inline-block;
            z-index: 1;
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: rem(14px);
            line-height: rem(18px);
            cursor: pointer;
            opacity: 0;
            transition: opacity 300ms;
            position: absolute;
            right: rem(5px);
            bottom: rem(5px);

            @include themes(
                (
                    'dark': (
                        'color': #d7e3f3,
                    ),
                    'light': (
                        'color': $light-gray-40,
                    ),
                )
            );

            .TopTrends__table:hover & {
                opacity: 0.5;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    &__info {
        font-family: 'Work Sans', sans-serif;
        font-size: rem(22px);
        line-height: rem(33px);
    }

    &__checkbox-bar {
        display: flex;
        justify-content: space-between;
        @media all and (max-width: 1440px) {
            flex-wrap: wrap;
        }
    }

    &__button {
        white-space: nowrap;
        height: rem(32px);
        padding: 0 rem(25px);
        background: $dark-green-3;
        box-sizing: border-box;
        border-radius: rem(16px);
        font-size: rem(16px);
        line-height: rem(24px);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-family: 'Nunito', sans-serif;
        font-weight: 700;
        transition: opacity 300ms, transform 300ms;
        text-decoration: none;
        transform-origin: 100% 50%;
        animation: fade-in 300ms;

        &:hover {
            transform: scale(1.05);
        }

        &--disabled {
            pointer-events: none;
            opacity: 0.3;
        }
    }
}

.TopTrendsSidebar {
    position: relative;
    font-family: 'Work Sans', sans-serif;
    padding-top: rem(18px);
    //padding-top: rem(50px);

    &__radio-input,
    &__radio {
        position: relative;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }

    &__btns-container {
        border: rem(1px) solid $dark-blue-1;
        border-radius: rem(3px);
    }

    &__btn {
        padding: rem(5px) rem(10px);
        cursor: pointer;
        transition: background 0.3s linear;

        @include themes(
            (
                'dark': (
                    'color': rgba(255, 255, 255, 0.5),
                ),
                'light': (
                    'color': #3a414c,
                ),
            )
        );

        &--checked {
            background-color: $dark-blue-1;

            @include themes(
                (
                    'dark': (
                        'color': #fff,
                    ),
                    'light': (
                        'color': #fff,
                    ),
                )
            );
        }
    }

    &__label {
        color: rgba(255, 255, 255, 0.5);
    }
}
