@use '@datassential/platform-ui-lib/lib/style/constants.scss' as *;
@import '@assets/core.style';

.checkbox-input {
    $root: &;

    display: flex;
    align-items: center;
    transition: opacity 300ms;
    color: $light-gray-70;

    &.is-underlined {
        text-decoration: underline;
    }

    &.is-disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    &__wrapper {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
    }

    &__arrow {
        margin-left: auto;
        transform-origin: center;
        transition: transform 150ms ease;
        cursor: pointer;
        height: 100%;

        @include themes(
            (
                'dark': (
                    'stroke': $dark-gray-6,
                ),
                'light': (
                    'stroke': #3a414c,
                ),
            )
        );

        &--down {
            transform: rotate(90deg);
            pointer-events: none;

            @include themes(
                (
                    'dark': (
                        'stroke': #fff,
                    ),
                    'light': (
                        'stroke': #3a414c,
                    ),
                )
            );
        }

        path {
            stroke: inherit;
        }
    }

    &__arrow-icon {
        height: 100%;
    }

    &__fake-check {
        $size: rem(20px);

        display: flex;
        width: $size;
        height: $size;
        min-width: $size;
        min-height: $size;
        align-items: center;
        justify-content: center;
        border-radius: rem(5px);
        border: rem(2px) solid;
        margin-right: rem(10px);
        cursor: pointer;
        transition: background 300ms, border-color 300ms;
        position: relative;
        overflow: hidden;

        @include themes(
            (
                'dark': (
                    'border-color': $dark-gray-6,
                ),
                'light': (
                    'border-color': $light-gray-70,
                ),
            )
        );

        #{$root}.is-checked &:not(.is-partial) {
            &:not(.is-radio) {
                @include themes(
                    (
                        'dark': (
                            'border-width': 0,
                            'background-color': #fff,
                        ),
                        'light': (
                            'background-color': transparent,
                        ),
                    )
                );
            }
        }

        #{$root}__wrapper:hover & {
            @include themes(
                (
                    'dark': (
                        'border-color': #fff,
                    ),
                    'light': (
                        'border-color': $light-gray-70,
                    ),
                )
            );
        }

        &.is-partial {
            #{$root}.is-checked & {
                @include themes(
                    (
                        'dark': (
                            'border-color': #fff,
                        ),
                        'light': (
                            'border-color': $light-gray-70,
                        ),
                    )
                );
            }
        }

        &.is-blue {
            #{$root}.is-checked &:not(.is-partial) {
                &:not(.is-radio) {
                    border-width: 0;
                    @include themes(
                        (
                            'dark': (
                                'background-color': $platform-color-main,
                            ),
                            'light': (
                                'background-color': $platform-color-main,
                            ),
                        )
                    );
                }
            }
        }

        &.is-radio {
            border-radius: 50%;
            @include themes(
                (
                    'dark': (
                        'background': transparent,
                    ),
                    'light': (
                        'background': transparent,
                    ),
                )
            );

            #{$root}.is-checked & {
                @include themes(
                    (
                        'dark': (
                            'border-color': #fff,
                        ),
                        'light': (
                            'border-color': $light-gray-70,
                        ),
                    )
                );
            }
        }
    }

    &__fake-check-small {
        $size: 8px;

        display: inline-block;
        width: rem($size);
        height: rem($size);
        border-radius: rem(2px);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;

        @include themes(
            (
                'dark': (
                    'background': #fff,
                ),
                'light': (
                    'background': $light-gray-70,
                ),
            )
        );

        &.is-blue {
            background: $platform-color-main;
        }

        &.is-radio {
            $size: 10px;

            width: rem($size);
            height: rem($size);
            border-radius: 50%;

            @include themes(
                (
                    'dark': (
                        'background': #fff,
                    ),
                    'light': (
                        'background': #fff,
                    ),
                )
            );
        }

        #{$root}.is-checked & {
            opacity: 1;
        }
    }

    &__real-check {
        opacity: 0;
        visibility: hidden;
        width: 0;
        height: 0;
        padding: 0;
        margin: 0;
        transform: scale(0);
        position: absolute;
    }

    &__icon {
        opacity: 0;
        transition: opacity 150ms;
        width: rem(20px);
        height: rem(20px);
        border-radius: rem(5px);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #000;

        #{$root}.is-checked & {
            opacity: 1;
        }
    }

    &__label {
        display: flex;
        align-items: center;
        text-align: left;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: rem(16px);
        line-height: rem(24px);
        opacity: 0.4;
        transition: opacity 150ms, $base-background-transition;
        cursor: pointer;
        -webkit-font-smoothing: antialiased;

        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': $light-gray-90,
                ),
            )
        );

        #{$root}__wrapper:hover & {
            opacity: 0.8;
        }

        #{$root}.is-checked & {
            opacity: 1;
        }

        #{$root}.is-small & {
            font-size: rem(14px);

            @media all and (max-width: 1440px) {
                font-size: rem(13px);
            }
        }

        #{$root}.is-medium & {
            font-size: rem(16px);

            @media all and (max-width: 1440px) {
                font-size: rem(14px);
            }
        }

        #{$root}.is-bold & {
            font-weight: 600;
        }

        #{$root}.is-inherited #{$root} & {
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
            font-family: inherit;
            line-height: inherit;
            opacity: 1;
            padding-right: rem(15px);

            &:last-child {
                padding-right: 0;
            }
        }

        &-cut {
            display: block;
            width: rem(150px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &--has-modifier {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                left: rem(-8px);
                top: rem(-4px);
                z-index: 1;
                width: rem(8px);
                height: rem(8px);
                background: $dark-orange-1;
                border-radius: 50%;
            }
        }

        &--no-hover {
            &:hover {
                opacity: 0.5;
            }
        }

        @media all and (max-width: 1440px) {
            font-size: rem(14px);
        }
    }

    &__not-visible {
        display: none;
    }
}
