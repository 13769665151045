@import '@styles';

$logo_width: 180px;

.page-header {
    position: fixed;
    top: rem(-60px);
    left: 0;
    right: 0;
    height: rem(70px);
    display: flex;
    align-items: center;
    padding: rem(15px);
    padding-right: rem(24px);
    z-index: 100;
    background-color: transparent;

    $root: &;

    &--hoverable {
        &:hover {
            @include themes(
                (
                    'dark': (
                        'background': $dark-gray-0,
                    ),
                    'light': (
                        'background-color': $light-gray-10,
                    ),
                )
            );
        }
    }

    &--admin-background {
        @include themes(
            (
                'dark': (
                    'background': $dark-gray-1,
                ),
                'light': (
                    'background-color': $light-gray-0,
                ),
            )
        );

        &:hover {
            @include themes(
                (
                    'dark': (
                        'background': $dark-gray-1,
                    ),
                    'light': (
                        'background-color': $light-gray-0,
                    ),
                )
            );
        }
    }

    &--background {
        @include themes(
            (
                'dark': (
                    'background-color': $dark-gray-0,
                ),
                'light': (
                    'background-color': $light-gray-10,
                ),
            )
        );
    }

    &__left,
    &__right {
        display: flex;
        align-items: center;
    }

    &__left {
        margin-right: auto;
        height: rem(30px);
        position: relative;
    }

    &__right {
        margin-left: auto;
        margin-right: 0;
        user-select: none;

        &.is-compact {
            margin-right: rem(15px);
        }
    }

    &__infinite-icon {
        width: rem($logo_width);
        height: rem(40px);
        transition: opacity 300ms;

        &:hover {
            opacity: 0.6;
        }
    }

    &--active {
        top: 0;
        transition: top 300ms;
    }

    &__hint {
        $color-dark-1: rgba(#1e2b3e, 0.5);
        $color-dark-2: rgba(#113970, 0.75);
        $color-light-1: rgba(#1c2531, 0.5);
        $color-light-2: rgba(#edf1f8, 0.75);

        height: rem(20px);
        width: rem(400px);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 50%;
        line-height: 1;
        margin-left: rem(-200px);
        border-bottom-left-radius: rem(20px);
        border-bottom-right-radius: rem(20px);
        background-position: 0 0%;
        box-shadow: 0 0 rem(10px) rgba(black, 0.25);
        animation: bg-change 10s linear infinite;
        background-size: 300% 75%;
        transform: translateY(-150%);
        transition: transform 500ms ease;

        @include themes(
            (
                'dark': (
                    'background-image':
                        linear-gradient(
                            90deg,
                            $color-dark-1,
                            $color-dark-2,
                            $color-dark-1,
                            $color-dark-2
                        ),
                ),
                'light': (
                    'background-image':
                        linear-gradient(
                            90deg,
                            $color-light-1,
                            $color-light-2,
                            $color-light-1,
                            $color-light-2
                        ),
                ),
            )
        );

        &.is-visible {
            transition-delay: 400ms;
            transform: translateY(0);
        }

        &-dots {
            $size: 3px;
            $color: white;

            width: rem($size);
            height: rem($size);
            position: relative;
            background: $color;
            border-radius: 50%;

            &:after,
            &:before {
                content: '';
                position: absolute;
                top: 50%;
                border-radius: 50%;
                margin-top: rem(-($size * 0.5));
                width: rem($size);
                height: rem($size);
                background: $color;
            }

            &:before {
                left: rem(-6px);
            }

            &:after {
                right: rem(-6px);
            }
        }
    }
}

.header-flag-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    height: rem(40px);
    margin-top: rem(-20px);
    left: rem($logo_width + 15px);
    z-index: 1;
    cursor: pointer;
    transition: transform 300ms ease;
    margin-left: rem(15px);

    &:hover {
        transform: scale(1);
    }

    &--shown {
        pointer-events: auto;

        &:hover {
            transform: scale(1.1);
        }
    }

    &__icon {
        width: rem(64px);
        height: rem(40px);
    }
}

.header-gears-btn,
.header-info-btn {
    margin-left: rem(15px);
    cursor: pointer;
    display: flex;
    align-items: center;
    max-height: rem(30px);
    transform-origin: center;
    transition: transform 300ms ease;

    &:hover {
        transform: scale(1.1);
    }
}

.header-color-mode-btn {
    @include hardwareAcceleration();

    display: flex;
    align-items: center;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: rem(14px);
    line-height: 1;
    height: rem(16px);
    letter-spacing: 0.012em;
    cursor: pointer;
    justify-content: center;
    overflow: hidden;
    position: relative;
    user-select: none;
    transition: color 500ms ease;

    @include themes(
        (
            'dark': (
                'color': #fff,
            ),
            'light': (
                'color': $dark-gray-1,
            ),
        )
    );

    &__label {
        position: relative;
        z-index: 1;
        text-transform: none;
        min-width: rem(65px);

        @include mq('lg') {
            display: none;
        }
    }
}

@keyframes bg-change {
    0% {
        background-position: 0 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0 50%;
    }
}
