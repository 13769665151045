@use '@datassential/platform-ui-lib/lib/style/constants' as *;
@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@import '@styles';

.MenuExamples {
    font-family: Montserrat, sans-serif;
    font-size: rem(16px);
    line-height: rem(20px);

    $title-height: 30px;
    $title-margin-bottom: 40px;

    @include themes(
        (
            'dark': (
                'color': white,
            ),
            'light': (
                'color': $dark-gray-1,
            ),
        )
    );

    &__title {
        font-size: rem(24px);
        line-height: rem($title-height);
        font-weight: 700;
        margin-bottom: rem($title-margin-bottom);
    }

    &__content {
        padding: rem(48px) rem(75px);
        box-shadow: 0 4px 40px rgba(#000, 0.1);
        min-height: 0;
        transition: min-height 700ms;
        border-radius: rem(8px);

        &.expanded {
            min-height: calc(
                100vh - #{$platform-header-height} - #{$platform-navigation-height} -
                    #{$title-height} - #{$title-margin-bottom} - #{rem(60px)}
            );
        }

        @include themes(
            (
                'dark': (
                    'background': $dark-gray-1,
                ),
                'light': (
                    'background': white,
                ),
            )
        );
    }

    &__sub-title {
        font-size: rem(20px);
        line-height: rem(24px);
        font-weight: 700;
    }

    &__search {
        width: rem(320px);
    }

    &__row {
        margin-bottom: rem(32px);
        display: flex;
        align-items: center;

        &.spread {
            justify-content: space-between;
        }

        &.no-margin {
            margin-bottom: 0;
        }
    }

    &__row-item {
        display: flex;
        align-items: center;
    }

    &__search-button {
        margin-left: rem(20px);
        width: rem(116px);
    }

    &__label {
        font-weight: 700;
    }

    &__results {
        box-shadow: 0 4px 40px rgba(#000, 0.1);
    }

    &__results-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: rem(16) rem(24px) rem(32px);
        border-radius: rem(8px) rem(8px) 0 0;
        @include themes(
            (
                'dark': (
                    'background': $dark-gray-3,
                ),
                'light': (
                    'background-color': #f0f5f9,
                ),
            )
        );
    }

    &__results-search {
        width: rem(280px);
        position: relative;
        padding-right: rem(32px);
        @include themes(
            (
                'dark': (
                    'background': $dark-gray-2,
                    'border-color': $dark-gray-1,
                    'color': white,
                ),
            )
        );

        input {
            @include themes(
                (
                    'dark': (
                        'background': $dark-gray-2,
                        'border-color': $dark-gray-1,
                        'color': white,
                    ),
                )
            );
        }
    }

    &__results-search-icon {
        position: absolute;
        right: rem(10px);
        top: 50%;
        transform: translateY(-50%);
        width: rem(20px);
        height: rem(20px);
    }

    &__table {
        line-height: 1;
        position: relative;
    }

    &__table-loader {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        height: rem(900px);
    }

    &__table-head {
        display: flex;
        height: rem(40px);
        position: sticky;
        top: calc(#{$platform-header-height} + #{$platform-navigation-height});
        z-index: 2;
    }

    &__table-body {
        border-radius: 0 0 rem(8px) rem(8px);
        @include themes(
            (
                'dark': (
                    background: $dark-gray-2,
                ),
                'light': (
                    'background': white,
                ),
            )
        );

        .Cell {
            font-size: rem(14px);
        }
    }

    &__table-row {
        height: rem(60px);
        display: flex;
    }

    &__message {
        position: relative;
        font-size: rem(20px);
        line-height: rem(25px);
        margin: rem(24px) 0 rem(40px);
        display: flex;
        justify-content: space-between;
        align-items: center;

        b {
            font-weight: 900;

            @include themes(
                (
                    'dark': (
                        'color': orange,
                    ),
                    'light': (
                        'color': $dark-blue-1,
                    ),
                )
            );
        }

        i {
            @include themes(
                (
                    'dark': (
                        'color': orange,
                    ),
                    'light': (
                        'color': $dark-blue-1,
                    ),
                )
            );
        }
    }

    &__sub-message {
        position: absolute;
        top: 100%;
        left: 0;
        font-size: rem(12px);
        line-height: rem(15px);
        display: flex;
        align-items: center;

        a {
            @include themes(
                (
                    'light': (
                        'filter': brightness(0) saturate(100%),
                    ),
                )
            );
        }
    }

    &__table-column {
        &:nth-child(1) {
            width: 20%;
        }

        &:nth-child(2) {
            width: 55%;
        }

        &:nth-child(3) {
            width: 10%;
            padding-left: rem(25px);
        }

        &:nth-child(4) {
            width: 15%;
        }

        @include media-max(1040px) {
            &:nth-child(2) {
                width: 52%;
            }

            &:nth-child(3) {
                width: 8%;
            }

            &:nth-child(4) {
                width: 20%;
            }
        }
    }

    &__download {
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem(20px);
        width: rem(20px);
        // border: rem(2px) solid;
        border-radius: 50%;
        transition: background-color 300ms, border-color 300ms;
        background: transparent;
        fill: #fff;
        cursor: pointer;
        position: relative;

        svg {
            path {
                &:first-child {
                    @include themes(
                        (
                            'dark': (
                                'stroke': #fff,
                            ),
                            'light': (
                                'stroke': $light-gray-90,
                            ),
                        )
                    );
                }
            }
        }

        &:hover {
            background: $dark-blue-3;

            svg {
                path {
                    &:first-child {
                        stroke: $dark-blue-3;
                    }
                }
            }
        }
    }
}
