@use '@styles' as *;

.FoodProfileFlavorTooltip {
    position: absolute;
    top: calc(100% + #{rem(10px)});
    left: 50%;
    transform: translateX(-50%);
    padding: rem(10px);
    border-radius: rem(3px);
    font-family: 'Work Sans', sans-serif;
    z-index: 100;
    font-size: rem(12px);
    white-space: nowrap;
    pointer-events: none;
    opacity: 0;
    text-align: left;
    filter: drop-shadow(0 0 10px rgba(#000, 0.5));
    @include themes(
        (
            'dark': (
                'background': #283547,
            ),
            'light': (
                'background-color': #fff,
            ),
        )
    );

    &:after {
        content: '';
        display: block;
        width: rem(8px);
        height: rem(8px);
        position: absolute;
        top: rem(-4px);
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        z-index: 1;

        @include themes(
            (
                'dark': (
                    'background': #283547,
                ),
                'light': (
                    'background-color': #fff,
                ),
            )
        );
    }

    &--left {
        left: auto;
        right: 50%;
        transform: translateX(#{rem(20px)});

        &:after {
            left: auto;
            right: rem(20px);
        }
    }

    &--right {
        transform: translateX(-20px);

        &:after {
            left: rem(20px);
        }
    }

    &__title {
        font-weight: 500;
        font-size: rem(16px);
        line-height: rem(18px);
        padding-bottom: rem(8px);

        @include themes(
            (
                'dark': (
                    'color': #ffffff,
                ),
                'light': (
                    'color': $light-gray-90,
                ),
            )
        );
    }

    &__description {
        font-weight: 400;
        font-size: rem(14px);
        line-height: rem(18px);

        @include themes(
            (
                'dark': (
                    'color': rgba(#ffffff, 0.7),
                ),
                'light': (
                    'color': $light-gray-70,
                ),
            )
        );
    }
}

.FoodProfileFlavor {
    $bd-c-dark: $dark-gray-3;
    $bd-c-light: rgba(#000, 0.5);
    @include themes(
        (
            'dark': (
                'color': #fff,
            ),
            'light': (
                'color': $dark-gray-1,
            ),
        )
    );

    &__section {
        position: relative;
        display: flex;
        padding: 0 rem(20px);
        justify-content: space-between;
    }

    &__overlay {
        position: absolute;
        left: rem(10px);
        right: rem(10px);
        top: rem(-10px);
        bottom: rem(-10px);
    }

    &__column {
        flex: 0 1 calc(50% - #{rem(10px)});
        border-radius: rem(8px);
        @include themes(
            (
                'dark': (
                    'background': $dark-gray-1,
                ),
                'light': (
                    'background-color': $light-gray-10,
                ),
            )
        );
    }

    &__col {
        flex: 0 1 100%;
        border-left: rem(1px) solid;
        @include themes(
            (
                'dark': (
                    'border-color': $bd-c-dark,
                ),
                'light': (
                    'border-color': $bd-c-light,
                ),
            )
        );

        &:first-child {
            border: 0;
        }
    }

    &__row {
        position: relative;
        padding: rem(15px) rem(5px);
        min-height: rem(60px);
        border-top: rem(1px) solid;
        display: flex;
        justify-content: center;
        align-items: center;
        @include themes(
            (
                'dark': (
                    'border-color': $bd-c-dark,
                ),
                'light': (
                    'border-color': $bd-c-light,
                ),
            )
        );

        &--col {
            flex-direction: column;
            text-align: center;
        }

        &--spaces {
            justify-content: space-around;
        }

        &:first-child {
            border: 0;
        }
    }

    &__section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: rem(14px);
        border-bottom: rem(1px) solid;
        padding: rem(15px) rem(20px);
        @include themes(
            (
                'dark': (
                    'border-color': $bd-c-dark,
                ),
                'light': (
                    'border-color': $bd-c-light,
                ),
            )
        );
    }

    &__title {
        font-size: rem(24px);
        line-height: rem(24px);
        position: relative;
        flex: 0 1 50%;
    }

    &__section-content {
        display: flex;
    }

    &__sub-title {
        font-size: rem(12px);
        line-height: rem(12px);
        @include themes(
            (
                'dark': (
                    'color': rgba(#fff, 0.5),
                ),
                'light': (
                    'color': rgba($dark-gray-1, 0.5),
                ),
            )
        );
    }

    &__norm {
        position: absolute;
        display: flex;
        width: rem(30px);
        height: rem(30px);
        border-radius: rem(15px);
        bottom: 0;
        border: rem(1px) solid;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        @include themes(
            (
                'dark': (
                    'color': rgba(#fff, 0.5),
                    'border-color': rgba(#fff, 0.5),
                ),
                'light': (
                    'color': rgba($dark-gray-1, 0.5),
                    'border-color': rgba($dark-gray-1, 0.5),
                ),
            )
        );

        &:hover {
            .FoodProfileFlavorTooltip {
                transition: opacity 300ms;
                opacity: 1;
            }
        }

        &--left {
            left: rem(-20px);
        }

        &--right {
            right: rem(-20px);
        }
    }

    &__circle-chart-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__circle-chart-value {
        font-size: rem(30px);
        line-height: rem(30px);

        &--small {
            font-size: rem(24px);
            line-height: rem(24px);
        }
    }

    &__circle-chart-title {
        font-size: rem(12);
        line-height: rem(12);
        text-transform: uppercase;
        width: 200%;
        text-align: center;

        &--small {
            font-size: rem(10px);
            line-height: rem(10px);
        }
    }

    &__abs-msg {
        position: absolute;
        bottom: rem(5px);
        right: rem(10px);
        font-size: rem(14px);
        @include themes(
            (
                'dark': (
                    'color': rgba(#fff, 0.5),
                ),
                'light': (
                    'color': rgba($dark-gray-1, 0.5),
                ),
            )
        );
    }

    &__keyword-highlight {
        text-transform: uppercase;
        font-weight: 500;
    }
}

.rank-ending {
    &:after {
        content: '';
        vertical-align: super;
        font-size: rem(10px);
    }
}

.st {
    &:after {
        content: 'st';
    }
}

.nd {
    &:after {
        content: 'nd';
    }
}

.rd {
    &:after {
        content: 'rd';
    }
}

.th {
    &:after {
        content: 'th';
    }
}
