@import '@styles';

.Flag {
    display: flex;
    align-items: center;
    width: rem(80px);
    overflow: hidden;
    flex: 0 0 auto;
    padding: rem(8px);

    @include themes(
        (
            'dark': (
                color: #fff,
            ),
            'light': (
                color: $dark-gray-0,
            ),
        )
    );

    &--compact {
        width: auto;
        padding-left: 0;
        padding-right: 0;
    }

    svg {
        width: rem(40px);
        height: rem(24px);
        margin-right: rem(8px);
    }

    &--disabled {
        pointer-events: none;
        filter: grayscale(100%);
    }
}
