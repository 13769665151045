@import '@assets/core.style';

.notification-container {
    position: fixed;
    bottom: rem(70px);
    right: rem(15px);
    z-index: 120;
    width: rem(336px);
}

.notification {
    padding-top: rem(12px);
    padding-bottom: rem(12px);
    padding-right: rem(22px);
    font-family: 'Work Sans', sans-serif;
    display: flex;
    align-items: flex-start;
    font-weight: 400;
    font-size: rem(16px);
    line-height: rem(20px);
    animation: notification-appears 500ms;
    border-left: rem(2px) solid $dark-blue-1;

    @include themes(
        (
            'dark': (
                'color': #fff,
                'border-radius': rem(3px),
                'box-shadow': 0 rem(10px) rem(90px) rgba(0, 0, 0, 0.35),
                'background-color': #2c3040,
            ),
            'light': (
                'background-color': #fff,
                'box-shadow': 0 rem(8px) rem(20px) rgba(176, 195, 208, 0.24),
                'border-radius': 0 rem(8px) rem(8px) 0,
                'color': $light-gray-90,
            ),
        )
    );

    &__text {
        padding-left: rem(20px);
        display: inline-block;
        width: 88%;

        @include themes(
            (
                'light': (
                    'color': $light-gray-70,
                ),
            )
        );
    }

    &--error {
        border-left: rem(2px) solid $dark-red-1;
    }

    &--success {
        border-left-color: $dark-green-1;
    }

    &__success-circle {
        position: relative;
        width: rem(22px);
        height: rem(22px);
        border-radius: 50%;
        background: $dark-green-1;
        margin-left: rem(20px);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__error-circle {
        vertical-align: middle;
        display: inline-block;
        position: relative;
        width: rem(22px);
        height: rem(22px);
        border-radius: 50%;
        background: $dark-red-1;
        margin-left: rem(20px);

        &:after {
            content: '';
            display: inline-block;
            width: rem(10px);
            height: rem(2px);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #2c3040;
        }
    }

    & + & {
        margin-top: rem(5px);
    }
}

@keyframes notification-appears {
    0% {
        opacity: 0;
        transform: translateY(-50%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
