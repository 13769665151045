@use 'sass:math';
@import '@assets/core.style';

.venues-form {
    $root: &;
    $col-gap: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Montserrat', sans-serif;
    animation: appear 300ms ease-in;

    &__header {
        display: flex;
        padding: rem(15px) 0 0;
        gap: rem($col-gap);

        & #{$root}__item {
            margin-bottom: 0;
        }
    }

    &__footer {
        position: relative;
        display: flex;
        justify-content: flex-end;
        padding: rem(20px);
        margin-top: rem(60px);

        @include themes(
            (
                'dark': (
                    'border-color': $dark-gray-6,
                ),
                'light': (
                    'border-color': $light-gray-20,
                ),
            )
        );
    }

    &__footer-btn {
        position: absolute;
        top: 50%;
        right: 0;
        text-align: right;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: rem(16px);
        line-height: rem(24px);
        letter-spacing: 0.012em;
        cursor: pointer;
        transform: translate(0, -50%);
        transition: opacity 300ms;

        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': $light-gray-70,
                ),
            )
        );

        &:hover {
            text-decoration: none;
        }

        &--visible {
            opacity: 1;
            pointer-events: auto;
        }

        &--hidden {
            opacity: 0;
            pointer-events: none;
        }
    }

    &__footer-text {
        font-size: rem(18px);
        transition: opacity 300ms;

        @include themes(
            (
                'dark': (
                    'color': rgba(#fff, 0.5),
                ),
                'light': (
                    'color': rgba(#3a414c, 0.7),
                ),
            )
        );

        & > span {
            margin-right: rem(20px);
            @include themes(
                (
                    'dark': (
                        'color': #fff,
                    ),
                    'light': (
                        'color': #3a414c,
                    ),
                )
            );
        }

        &--visible {
            opacity: 1;
            pointer-events: auto;
        }

        &--hidden {
            opacity: 0;
            pointer-events: none;
        }
    }

    &__footer-filter {
        text-decoration: underline;
    }

    &__footer-range {
        text-align: right;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: rem(14px);
        line-height: rem(16px);
        text-decoration: none;

        @include themes(
            (
                'dark': (
                    'color': $dark-gray-8,
                ),
                'light': (
                    'color': $light-gray-40,
                ),
            )
        );

        span {
            @include themes(
                (
                    'dark': (
                        'color': #fff,
                    ),
                    'light': (
                        'color': $light-gray-90,
                    ),
                )
            );
        }

        &--visible {
            opacity: 1;
            pointer-events: auto;
        }

        &--hidden {
            opacity: 0;
            pointer-events: none;
        }
    }

    &__input {
        font-size: rem(18px);
        border-bottom: rem(1px) solid;

        @include themes(
            (
                'dark': (
                    'border-bottom-color': #fff,
                    'color': #fff,
                ),
                'light': (
                    'color': #3a414c,
                    'border-bottom-color': #3a414c,
                ),
            )
        );

        @media all and (max-width: 1440px) {
            font-size: rem(14px);
        }
    }

    &__item {
        padding: rem(6px) 0;
    }

    &__content {
        display: flex;
        height: 100%;
        padding: 0 0 rem(20px);
        gap: rem($col-gap);
    }

    &__col {
        display: flex;
        flex-direction: column;
        width: calc(calc(100% - 3 * #{rem($col-gap)}) / 4);
    }

    &__accordion-container {
        height: 100%;
        display: flex;
        flex-flow: column wrap;
        width: 100%;
    }

    &__input-header {
        width: 100%;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: rem(16px);
        line-height: rem(24px);

        @include themes(
            (
                'dark': (
                    'color': #d7e3f3,
                ),
                'light': (
                    'color': $light-gray-90,
                ),
            )
        );

        @media all and (max-width: rem(1440px)) {
            font-size: rem(14px);
            line-height: rem(14px);
        }
    }

    &__input-container {
        height: 100%;
        display: flex;
        flex-flow: column wrap;
        width: 100%;
    }

    &__dropdown-btn {
        display: block;
        margin-top: rem(7px);
        color: #fff;
        font-size: rem(16px);
        line-height: rem(18px);
        cursor: pointer;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    &--is-full {
        #{$root}__header {
            border-bottom: none;
            padding-top: 0;
            padding-right: 0;
        }

        #{$root}__content {
            padding-right: 0;
        }

        #{$root}__footer {
            border-top: none;
            margin-bottom: rem(12px);
            height: rem(60px);
            padding: 0;
            justify-content: flex-start;
            align-items: center;
        }

        #{$root}__footer-btn {
            right: auto;
            left: 0;
            text-align: left;
        }
    }
}
