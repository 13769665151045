@import '@styles';

.Subtitle {
    padding: rem(24px) 0;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: rem(18px);
    line-height: rem(24px);

    &--yellow {
        @include themes(
            (
                'dark': (
                    color: #ff9800,
                ),
                'light': (
                    color: $dark-gray-0,
                ),
            )
        );
    }

    &--white {
        @include themes(
            (
                'dark': (
                    color: #fff,
                ),
                'light': (
                    color: $dark-gray-0,
                ),
            )
        );
    }
}
