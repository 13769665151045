@import '@styles';

.SidebarHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(4px) rem(16px);
    border-right: rem(1px) solid;

    @include themes(
        (
            'dark': (
                'background-color': #232d3b,
                'border-color': #2e3e56,
            ),
            'light': (
                'background-color': $light-gray-10,
                'border-color': $light-gray-20,
                'color': $dark-gray-0,
            ),
        )
    );

    .btn {
        margin-left: rem(16px);

        svg {
            path {
                @include themes(
                    (
                        'dark': (
                            stroke: #fff,
                        ),
                        'light': (
                            stroke: $dark-gray-0,
                        ),
                    )
                );
            }
        }
    }
}
