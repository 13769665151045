@import '@styles';

.Chart {
    position: relative;

    > svg {
        width: 100%;
        height: 100%;
        border-radius: rem(8px);
    }

    &__axis {
        pointer-events: none;
    }

    &__column {
        opacity: 0;
        cursor: pointer;
        position: relative;
        //transition: opacity 300ms;

        &:hover {
            opacity: 1;
        }
    }

    &__point-back {
        opacity: 0.3;
    }

    &__curve {
        position: relative;
        z-index: 2;
    }

    &__area {
        position: relative;
        z-index: 1;
    }

    &__haiku {
        path {
            @include themes(
                (
                    'light': (
                        'opacity': '0.075',
                    ),
                )
            );
        }
    }
}
