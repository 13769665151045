@import '@assets/core.style';

.InstantChart {
    $background: $dark-gray-2;
    $border-color: $dark-gray-6;

    position: relative;
    overflow: hidden;

    @include themes(
        (
            'dark': (
                'background': $background,
            ),
            'light': (
                'background': #fff,
            ),
        )
    );

    &__row {
        padding: rem(8px) rem(24px);
        display: flex;
        flex-direction: column;

        &--right {
            align-items: flex-end;
        }
    }

    &__top-actions {
        display: flex;
        font-weight: 400;
        align-items: center;
        position: relative;
        top: rem(16px);
    }

    &__top-actions-item {
        & + & {
            margin-left: rem(40px);
        }
    }

    &__button {
        font-size: rem(14px);
        min-width: rem(136px);
        height: rem(32px);
    }

    &__section {
        position: relative;
    }

    .Select {
        &--inherited {
            font-size: rem(14px);

            @include themes(
                (
                    'dark': (
                        'background': #2e3e56,
                    ),
                    'light': (
                        'background': $light-gray-20,
                    ),
                )
            );

            .Select__input {
                cursor: pointer;

                &--opened {
                    @include themes(
                        (
                            'dark': (
                                'background': #1f2835,
                            ),
                            'light': (
                                'background': $light-gray-20,
                            ),
                        )
                    );
                }

                &:after {
                    right: rem(13px);
                }
            }
        }
    }
}

.InstantChartSection {
    $root: &;

    &__title {
        display: flex;
        align-items: center;
        padding-bottom: rem(24px);
    }

    &__row {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        & + & {
            margin-top: rem(35px);

            #{$root}__column & {
                margin-top: rem(30px);
            }
        }
    }

    &__column {
        $column-width-fluid: 36%;
        $column-width-fixed: 360px;
        $column-gap: 5%;

        width: $column-width-fluid;
        max-width: $column-width-fluid;
        font-size: rem(16px);
        line-height: 1.5;
        letter-spacing: 0.012em;
        color: #fff;
        padding-right: $column-gap;

        &--limit-z-index {
            position: relative;
            z-index: 2;
        }

        &--fixed {
            width: rem($column-width-fixed);
            max-width: 50%;
        }

        &--selects {
            height: rem(40px);
        }

        &--fluid {
            max-width: calc(100% - #{$column-width-fluid});
            width: auto;
            flex: 1;
            padding-right: 0;
        }

        &--block {
            max-width: 100%;
            width: rem($column-width-fixed * 2);
            padding-right: $column-gap;
            margin-top: rem(30px);

            &:first-child {
                margin-top: 0;
            }
        }
    }

    &__input-wrap {
        display: block;
        position: relative;
    }

    &__input {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 0 rem(8px);
        font-size: rem(16px);
        line-height: rem(24px);
        border-bottom: rem(1px) solid #4a6282;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        white-space: nowrap;

        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': $dark-gray-0,
                ),
            )
        );

        &:-internal-autofill-selected {
            box-shadow: inset 0 0 0 rem(200px) #fff;
        }

        &::placeholder {
            color: $dark-gray-9;
        }
    }

    .venues-form {
        .checkbox-input {
            &__arrow {
                position: relative;

                svg {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}
