@import '@assets/core.style';

$additional-filters-col-gap: 20px;

.additional-filters {
    $this: &;

    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: 'Montserrat', sans-serif;
    animation: appear 300ms ease-in;

    &__title {
        margin-bottom: rem(4px);
        font-style: normal;
        font-weight: 600;
        font-size: rem(22px);
        line-height: rem(24px);

        @include themes(
            (
                'dark': (
                    'color': #d7e3f3,
                ),
                'light': (
                    'color': $light-gray-90,
                ),
            )
        );

        @media all and (max-width: 1440px) {
            font-size: rem(18px);
            line-height: rem(20px);
        }
    }

    &__subtitle {
        margin-bottom: rem(24px);
        font-size: rem(14px);
        line-height: rem(16px);

        @include themes(
            (
                'dark': (
                    'color': rgba(#fff, 0.5),
                ),
                'light': (
                    'color': $light-gray-70,
                ),
            )
        );
    }

    &__wrap {
        display: flex;
        width: 100%;
        gap: rem($additional-filters-col-gap);
    }

    &--is-full {
        margin-left: 0;
        margin-top: 0;
        padding-bottom: rem(24px);
    }

    &__footer {
        font-size: rem(18px);
        margin-top: rem(15px);
        padding: rem(16px) 0;
        display: flex;
        justify-content: flex-end;

        @include themes(
            (
                'dark': (
                    color: #fff,
                ),
                'light': (
                    color: #3a414c,
                ),
            )
        );
    }
}

.additional-filter {
    flex: 1 1 100%;

    &__accent {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: rem(14px);
        line-height: rem(18px);
        white-space: nowrap;

        @include themes(
            (
                'dark': (
                    'color': #b8c6d8,
                ),
                'light': (
                    'color': $light-gray-70,
                ),
            )
        );

        @media all and (max-width: 1440px) {
            font-size: rem(14px);
            line-height: rem(16px);
        }
    }

    &__included {
        width: rem(42px);
        margin: rem(-8px) 0;
        padding: rem(8px) rem(4px);
        border-radius: rem(4px);
        text-decoration: underline;
        cursor: pointer;
        transition: background-color 300ms;

        @include themes(
            (
                'dark': (
                    'background-color': $dark-gray-3,
                ),
                'light': (
                    'background-color': $light-gray-10,
                ),
            )
        );

        &:hover {
            background: $dark-gray-4;
        }
    }

    &__highlight {
        text-transform: uppercase;
        font-weight: 700;

        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': $dark-blue-1,
                ),
            )
        );
    }

    &__input {
        margin-top: rem(25px);
    }

    &__input-counter {
        font-size: rem(14px);
        line-height: 1.1;
        font-weight: 600;
        color: $dark-gray-9;
        margin-bottom: rem(4px);
    }

    &__button {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: rem(16px);
        line-height: rem(24px);
        letter-spacing: 0.012em;
        cursor: pointer;
        text-decoration: underline;
        margin-top: rem(10px);

        @include themes(
            (
                'dark': (
                    'color': #d7e3f3,
                ),
                'light': (
                    'color': $light-gray-90,
                ),
            )
        );

        &:hover {
            text-decoration: none;
        }
    }
}
