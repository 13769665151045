@import '@assets/core.style';

.byo {
    &__top {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 0 rem(10px);
        transition: padding-top 300ms;

        &.is-large {
            padding-top: rem(120px);
        }

        &.is-medium {
            padding-top: rem(80px);
        }

        &.is-small {
            padding-top: rem(40px);
        }
    }

    &__loader {
        z-index: 5;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    &__table {
        padding: rem(20px) 0 rem(48px);
    }

    &__top-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: rem(320px);

        &--taller {
            height: rem(360px);
        }
    }

    &__top-left {
        width: calc(100% - rem(324px));
    }

    &__top-right {
        width: rem(324px);
    }

    &__chart-top {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: rem(40px);
        margin-bottom: rem(10px);

        &--has-chart {
            justify-content: space-between;
        }
    }

    &__chart {
        height: 100%;
    }

    &__sidebar {
        margin-left: rem(24px);
        height: 100%;
    }

    &__table-top {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        padding-bottom: rem(10px);

        .search-field {
            margin-right: rem(8px);
        }
    }
}

.copy-table-btn {
    margin-left: auto;
    padding: rem(8px);
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: rem(14px);
    line-height: rem(18px);
    color: #d7e3f3;
    cursor: pointer;
    transition: opacity 300ms;
    opacity: 0.5;

    &:hover {
        opacity: 1;
    }
}

.ScrollbarsCustom-ThumbX {
    /* stylelint-disable-next-line declaration-no-important */
    height: rem(7px) !important;
    /* stylelint-disable-next-line declaration-no-important */
    margin-top: rem(2px) !important;

    @include themes(
        (
            'dark': (
                'background': $dark-gray-5 !important,
            ),
            'light': (
                'background-color': $light-gray-40 !important,
            ),
        )
    );
}

.ScrollbarsCustom-ThumbY {
    /* stylelint-disable-next-line declaration-no-important */
    width: rem(7px) !important;
    @include themes(
        (
            'dark': (
                'background': $dark-gray-5 !important,
            ),
            'light': (
                'background-color': $light-gray-40 !important,
            ),
        )
    );

    .AdminContainer & {
        /* stylelint-disable-next-line declaration-no-important */
        margin-left: rem(1px) !important;
    }
}

.ByoExportMenu {
    width: rem(220px);
}
