@import '@assets/core.style';

.filters {
    position: relative;
    width: 100%;
    font-family: 'Nunito', sans-serif;
    border-radius: rem(8px);

    @include themes(
        (
            'dark': (
                'background': $dark-gray-2,
            ),
            'light': (
                'background-color': #fff,
            ),
        )
    );

    &__list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: rem(24px);

        @include media-max(1024px) {
            padding: rem(8px);
        }
    }

    &__list-item {
        width: 31%;
        margin-right: 3.3%;
        margin-bottom: rem(10px);
        height: rem(35px);
        font-size: rem(14px);
        line-height: rem(14px);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: rem(2px);
        transition: $base-background-transition, transform 300ms;

        @media all and (max-width: 1200px) {
            font-size: rem(12px);
        }

        @include themes(
            (
                'dark': (
                    'background-color': $dark-gray-3,
                    'color': #fff,
                ),
                'light': (
                    'background-color': $light-gray-10,
                    'color': $light-gray-90,
                ),
            )
        );

        &--no-mb {
            margin-bottom: 0;
        }

        &:nth-child(3n) {
            margin-right: 0;
        }

        &:hover {
            transform: scale(1.1);

            @include themes(
                (
                    'dark': (
                        'background-color': $dark-gray-4,
                    ),
                    'light': (
                        'background-color': $light-gray-20,
                    ),
                )
            );
        }

        &--selected {
            @include themes(
                (
                    'dark': (
                        'background-color': $dark-blue-1,
                    ),
                    'light': (
                        'background-color': $dark-blue-1,
                        'color': #fff,
                    ),
                )
            );

            &:hover {
                transform: scale(1.1);

                @include themes(
                    (
                        'dark': (
                            'background-color': $dark-blue-1,
                        ),
                        'light': (
                            'background-color': $dark-blue-1,
                        ),
                    )
                );
            }
        }
    }

    &__apply-btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem(40px);
        cursor: pointer;
        border-radius: 0 0 rem(8px) rem(8px);
        transition: $base-background-transition;
        pointer-events: none;

        @include themes(
            (
                'dark': (
                    'color': #fff,
                    'background-color': $dark-gray-3,
                ),
                'light': (
                    'color': $light-gray-100,
                    'background-color': $light-gray-20,
                ),
            )
        );

        &--highlighted {
            pointer-events: auto;

            @include themes(
                (
                    'dark': (
                        'color': #fff,
                        'background-color': $dark-green-3,
                    ),
                    'light': (
                        'color': #fff,
                        'background-color': $dark-green-1,
                    ),
                )
            );
        }

        &:hover {
            @include themes(
                (
                    'dark': (
                        'background-color': $dark-blue-1,
                    ),
                    'light': (
                        'background-color': $dark-blue-1,
                    ),
                )
            );
        }
    }
}
