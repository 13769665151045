@import '@assets/core.style';

.table {
    display: inline-flex;
    position: relative;
    min-width: 100%;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: rem(14px);

    $root: &;

    &__scrollbar {
        width: 100%;
        height: 100%;
        border-radius: rem(3px);
        overflow: hidden;

        @include themes(
            (
                'dark': (
                    'box-shadow': 0 rem(10px) rem(50px) rgba(0, 0, 0, 0.4),
                ),
                'light': (
                    'box-shadow': 0 rem(10px) rem(90px) rgba(0, 0, 0, 0.1),
                ),
            )
        );
    }

    &__cell {
        justify-content: center;
        text-align: center;
        max-height: rem(40px);
        font-size: rem(13px);
        padding: 0;
        font-weight: 600;

        &-currency-icon {
            padding-right: rem(5px);
        }

        &--text-left {
            text-align: left;
            justify-content: flex-start;
            padding-left: rem(18px);
        }

        &--text-right {
            text-align: right;
            justify-content: flex-end;
        }

        &--header,
        &--footer {
            z-index: 4;
            position: sticky;
        }

        &--header {
            top: 0;
            height: rem(40px);

            @include themes(
                (
                    'light': (
                        'background-color': $light-gray-20,
                    ),
                )
            );
        }

        &--footer {
            bottom: 0;
            height: rem(35px);
        }

        &--active {
            background-color: $dark-blue-1;
        }
    }

    &__settings-btn {
        position: absolute;
        left: rem(10px);
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;

        svg {
            @include themes(
                (
                    'dark': (
                        'fill': #fff,
                    ),
                    'light': (
                        'fill': #666,
                    ),
                )
            );
        }
    }

    &__col {
        display: flex;
        flex-direction: column;
        flex: 1 0 rem(115px);
        z-index: 1;
        transition: background 300ms linear;
        width: rem(115px);

        @include themes(
            (
                'dark': (
                    'background-color': #1a1c26,
                ),
                'light': (
                    'background': #fafafa,
                ),
            )
        );

        &--medium {
            width: rem(150px);
        }

        &--wide {
            min-width: rem(330px);
        }

        &--sticky {
            position: sticky;
            left: 0;
            z-index: 3;
        }
    }
}
