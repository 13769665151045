@import '@assets/core.style';

.restaurantActivity {
    max-width: rem(880px);
    width: 100%;
    border-radius: rem(3px);
    position: relative;
    min-height: rem(140px);

    $root: &;

    @include themes(
        (
            'dark': (
                'box-shadow': 0 rem(4px) rem(30px) rgba(0, 0, 0, 0.16),
            ),
            'light': (
                'box-shadow': none,
            ),
        )
    );

    &__header {
        height: rem(45px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: rem(20px);
        padding-left: rem(20px);
        transition: background-color 200ms linear;
        border-top-left-radius: rem(8px);
        border-top-right-radius: rem(8px);

        @include themes(
            (
                'dark': (
                    'background': rgba(#283547, 0.8),
                ),
                'light': (
                    'background-color': $light-gray-20,
                ),
            )
        );
    }

    &__header-left {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        flex-shrink: 1;
    }

    &__header-right {
        display: flex;
        justify-content: flex-end;
        flex-shrink: 0;
        padding-left: rem(8px);
    }

    &__descr {
        font-weight: 400;
        font-size: rem(16px);
        line-height: rem(24px);
        padding: rem(20px);
        display: flex;
        justify-content: space-between;
        transition: background-color 200ms linear, position 1s linear;
        z-index: 1;
        overflow: hidden;
        height: rem(95px);
        box-sizing: border-box;
        border-bottom-left-radius: rem(8px);
        border-bottom-right-radius: rem(8px);
        @include multilines-ellipsis(3);

        @include themes(
            (
                'dark': (
                    'background': #232d3b,
                    'color': #d7e3f3,
                ),
                'light': (
                    'background-color': $light-gray-0,
                    'color': $light-gray-70,
                ),
            )
        );

        &:hover {
            display: block;
            width: 100%;
            position: absolute;
            top: rem(45px);
            min-height: rem(95px);
            border-bottom-left-radius: rem(8px);
            border-bottom-right-radius: rem(8px);
            height: auto;
            -webkit-line-clamp: none;
            -webkit-box-orient: horizontal;
            overflow: visible;
            box-sizing: border-box;

            @include themes(
                (
                    'dark': (
                        'background': #1c2531,
                    ),
                    'light': (
                        'background-color': darken($light-gray-0, 5%),
                    ),
                )
            );
        }
    }

    &__title {
        text-transform: uppercase;
        font-weight: 600;
        font-size: rem(20px);
        line-height: rem(24px);
        transition: color 200ms linear;

        @include themes(
            (
                'dark': (
                    'color': #d7e3f3,
                ),
                'light': (
                    'color': $light-gray-90,
                ),
            )
        );

        &:hover {
            #{$root}__tooltip {
                display: flex;
            }
        }
    }

    &__date {
        font-weight: 600;
        font-size: rem(12px);
        line-height: rem(14px);
        padding-left: rem(12px);
        position: relative;
        white-space: nowrap;
        display: flex;
        align-items: center;

        @include themes(
            (
                'dark': (
                    'color': rgba(#b8c6d8, 0.5),
                ),
                'light': (
                    'color': $light-gray-70,
                ),
            )
        );

        &:before {
            content: '';
            display: inline-block;
            position: absolute;
            height: rem(4px);
            width: rem(4px);
            border-radius: 50%;
            background: rgba(#788ba3, 0.5);
            top: 50%;
            transform: translateY(-50%);
            left: rem(4px);
        }
    }

    &__text {
        @include themes(
            (
                'light': (
                    'color': #3a414c,
                ),
            )
        );
    }

    &__restaurant {
        font-weight: 600;
        font-size: rem(12px);
        line-height: rem(14px);
        display: flex;
        text-align: right;

        @include themes(
            (
                'dark': (
                    'color': rgba(#b8c6d8, 0.5),
                ),
                'light': (
                    'color': $light-gray-70,
                ),
            )
        );
    }

    &__tooltip {
        margin-left: rem(15px);
        max-width: 100%;
    }
}
