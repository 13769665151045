@import '@assets/core.style';

.low-penetration-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Nunito', sans-serif;
    font-size: rem(14px);
    line-height: rem(18px);
    letter-spacing: 0.012em;
    cursor: pointer;
    @include themes(
        (
            'dark': (
                'color': #b8c6d8,
            ),
            'light': (
                'color': $dark-gray-1,
            ),
        )
    );

    &__icon-arrow {
        margin-left: rem(12px);

        path {
            @include themes(
                (
                    'dark': (
                        'stroke': #fff,
                    ),
                    'light': (
                        'stroke': $light-gray-60,
                    ),
                )
            );
        }
    }
}
