@import '@assets/core.style';

.chart-container {
    height: 100%;
    background: #1f2835;
    box-shadow: 0 rem(4px) rem(90px) rgba(0, 0, 0, 0.2);
    border-radius: rem(8px);
    font-family: 'Work Sans', sans-serif;
    position: relative;

    @include themes(
        (
            'light': (
                'background': #fafcff,
                'box-shadow': 0 rem(8px) rem(20px) rgba(176, 195, 208, 0.24),
            ),
        )
    );

    &--no-chart {
        max-width: rem(310px);
        height: rem(45px);

        & .chart-tabs {
            // cascade instead of props drilling to ChartTabs only to compare chartsCount
            &__right {
                display: none;
            }
        }
    }

    &__button {
        position: absolute;
        right: rem(10px);
        top: rem(10px);
        z-index: 2;
        width: rem(32px);
        height: rem(32px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: rem(4px);
        background: $dark-gray-0;
        transition: transform 300ms;
        cursor: pointer;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: rem(-10px);
            bottom: rem(-10px);
            left: rem(-10px);
            right: rem(-10px);
        }

        &:hover {
            transform: scale(1.1);
        }

        @include themes(
            (
                'light': (
                    'background': $light-gray-60,
                ),
            )
        );
    }
}
