@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@import '@assets/core.style';
/*  CSS RESET START */

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* CSS RESET END */



.App {
    min-height: 100vh;
    color: #fff;
    transition: $base-background-transition;
    background: #131921;

    &__font-preload-1, &__font-preload-2, &__font-preload-3 {
        font-family: "Montserrat";
        position: absolute;
        top: rem(-100px);
    }

    &__font-preload-1 {
        font-weight: 400;
    }

    &__font-preload-2 {
        font-weight: 600;
    }

    &__font-preload-3 {
        font-weight: 700;
    }
    @include themes((
        "dark": (
            "background-color": $dark-gray-0
        ),
        "light": (
            "background-color": $light-gray-10
        )
    ));
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

button {
    background: transparent;
    border: 0;
    outline: none;
    color: inherit;
    font: inherit;
    padding: 0;
}

#portal-root {
    // position: absolute;
    // top: 0;
    // left: 0;
    // z-index: 120;
}


.loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 98;
    background-color: rgba($dark-gray-2, 0.8);

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: calc(50% - #{rem(30px)});
        left: calc(50% - #{rem(30px)});
        width: rem(60px);
        height: rem(60px);
        border-radius: 50%;
        margin: 0 auto;
        border-left: rem(3px) solid royalblue;
        animation: spin 1500ms ease infinite;
    }
}

#feedly-mini {
    display: none;
}

@keyframes spin {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

.fade-enter {
    opacity: 0.01;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

body {
    //min-width: rem(1024px);

    @include themes((
        "dark": (
            "background": $dark-gray-0
        ),
        "light": (
            "background": $light-gray-10
        )
    ));
}

.match {
    background: #f00;
}

.appears {

    &-enter {
        opacity: 0 !important;

        &-active {
            opacity: 1 !important;
            transition: all 600ms 200ms !important;
        }
    }

    &-exit {
        opacity: 1 !important;

        &-active {
            opacity: 0 !important;
            transition: all 600ms 200ms !important;
        }
    }
}

.appears-long {

    &-enter {
        opacity: 0 !important;

        &-active {
            opacity: 1 !important;
            transition: all 1000ms 100ms !important;
        }
    }

    &-exit {
        opacity: 1 !important;

        &-active {
            opacity: 0 !important;
            transition: all 1000ms !important;
        }
    }
}

@keyframes appears {
    from {
        opacity: 0;
        transform: translateY(rem(20px));
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@for $i from 1 to 10 {
    .appears#{$i} {

        &-enter {
            opacity: 0 !important;
            transform: translateY(rem(30px)) !important;

            &-active {
                opacity: 1 !important;
                transform: translateY(rem(0px)) !important;
                transition: all 600ms #{200*$i}ms !important;
            }
        }

        &-exit {
            opacity: 1 !important;

            &-active {
                opacity: 0 !important;
                transition: all 600ms #{200*(11 - $i)}ms !important;
            }
        }
    }
}

@for $i from 1 to 10 {
    .appears-x-#{$i} {

        &-enter {
            opacity: 0 !important;
            transform: translateX(rem(20px)) !important;

            &-active {
                opacity: 1 !important;
                transform: translateX(rem(0px)) !important;
                transition: all 400ms #{100*$i}ms !important;
            }
        }

        &-exit {
            opacity: 1 !important;
            transform: translateX(rem(0px)) !important;

            &-active {
                opacity: 0 !important;
                transform: translateX(rem(20px)) !important;
                transition: all 400ms #{100*$i}ms !important;
            }
        }
    }
}

@for $i from 1 to 10 {
    .appears-xx-#{$i} {

        &-enter {
            opacity: 0 !important;
            transform: translateX(rem(-20px)) !important;

            &-active {
                opacity: 1 !important;
                transform: translateX(rem(0px)) !important;
                transition: all 400ms #{100*$i}ms !important;
            }
        }

        &-exit {
            opacity: 1 !important;
            transform: translateX(rem(0px)) !important;

            &-active {
                opacity: 0 !important;
                transform: translateX(rem(-20px)) !important;
                transition: all 400ms #{100*$i}ms !important;
            }
        }
    }
}


html {
    font-size: $root-size;

    @media (min-width: $root-responsive-mq) {
        font-size: size($root-size);
    }
}

.Canada-2020-interpolated-label {
    display: inline-block;
    width: rem(70px);
    height: rem(14px);
    position: relative;
    font-family: 'montserrat', sans-serif;

    &__inner {
        position: absolute;
        left: 0;
        bottom: 0;
        transform: scaleX(0.85);
        transform-origin: 0 0;
    }
}

.clickable {
    *:not(.clickable) {
        pointer-events: none;
    }
}

@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.InputError {
    font-size: rem(12px);
    color: $dark-red-1;
    padding: rem(4px) 0;
}

.tag-label {
    @extend %tag-keyword;
    $size: 6px;

    &:after {
        content: '';
        display: inline-block;
        margin-left: rem(6px);
        width: rem($size);
        height: rem($size);
        border-radius: 50%;
        background: currentColor;
    }

    &.is-legend {
        display: flex;
        align-items: center;
        padding: rem(3px) rem(8px);
        border-radius: rem(4px);
        line-height: rem(20px);

        @include themes(
            (
                'dark': (
                    'background-color': rgba(#000, 0.25),
                    'color': $body-text,
                ),
                'light': (
                    'background-color': #fff,
                    'color': $light-gray-70,
                ),
            )
        );
    }
}
