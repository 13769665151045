@import '@styles';

.Icon {
    display: inherit;

    svg {
        width: 100%;
        height: 100%;
    }

    &--large {
        width: rem(32px);
        height: rem(32px);
    }

    &--regular {
        width: rem(24px);
        height: rem(24px);
    }

    &--small {
        width: rem(16px);
        height: rem(16px);
    }

    &--flag {
        width: rem(40px);
        height: rem(24px);
    }

    &--map {
        width: rem(336);
        height: rem(232);
    }
}
