@import '@assets/core.style';

.page-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: rem(50px);
    padding: 0 0 0 rem(15px);
    font-family: 'Work Sans', sans-serif;
    transition: $base-background-transition;
    z-index: 100;

    $root: &;

    @include themes(
        (
            'dark': (
                'background-color': $dark-gray-3,
                'box-shadow': 0 rem(4px) rem(20px) rgba(0, 0, 0, 0.1),
            ),
            'light': (
                'background': #ffffff,
                'box-shadow': 0 rem(-8px) rem(20px) rgba(176, 195, 208, 0.24),
            ),
        )
    );

    &__filter-btn {
        cursor: pointer;
    }

    &__icon {
        width: rem(120px);
        height: rem(25px);
    }

    &__text {
        margin-left: rem(15px);
        font-size: rem(14px);
        //color: #fff;

        // rem(375px) max restaurants menu title + rem(30px) horizontal footer paddings === rem(405px)
        // rem(120px) FILTER gradient icon + rem(40px) margin after FILTER === rem(160px)
        // and + rem(20px) to add some space
        max-width: calc(((100vw - #{rem(405px)}) / 2) - rem(180px));

        @include themes(
            (
                'dark': (
                    'color': $dark-gray-7,
                ),
                'light': (
                    'color': $light-gray-70,
                ),
            )
        );

        &--blue {
            @include themes(
                (
                    'dark': (
                        'color': $dark-blue-1,
                    ),
                    'light': (
                        'color': $dark-blue-1,
                    ),
                )
            );
        }
    }

    &__restaurants {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: rem(18px);
        line-height: rem(24px);
        cursor: pointer;
        text-transform: uppercase;
        transition: opacity 150ms ease;
        backface-visibility: hidden;
        display: flex;
        align-items: center;

        @include themes(
            (
                'dark': (
                    'opacity': 0.3,
                ),
                'light': (
                    'opacity': 0.8,
                    'color': $light-gray-70,
                ),
            )
        );

        @media all and (max-width: 1200px) {
            font-size: rem(20px);
        }

        &:hover {
            opacity: 1;
        }
    }

    &__icon-arrow {
        display: inline-flex;
        margin-left: rem(4px);
        padding: rem(1px);

        path {
            stroke: currentColor;
        }

        &.is-open {
            transform: rotate(180deg);
        }
    }

    &__btn-container {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        margin-left: auto;
        padding: 0 rem(16px);

        @include themes(
            (
                'dark': (
                    'background-color': #2d3e56,
                ),
                'light': (
                    'background-color': #fff,
                ),
            )
        );
    }

    &__btn-wr {
        display: flex;
        //padding-left: rem(16px);
        height: 100%;
        flex-direction: row-reverse;
    }

    &__link {
        position: relative;
        z-index: 10;
        display: block;
        font-size: rem(16px);
        line-height: rem(18px);
        cursor: pointer;
        transition: opacity 150ms ease;

        @include themes(
            (
                'dark': (
                    'color': $dark-gray-8,
                ),
                'light': (
                    'color': $light-gray-70,
                ),
            )
        );

        &:hover {
            @include themes(
                (
                    'dark': (
                        'color': #fff,
                    ),
                    'light': (
                        'color': $light-gray-70,
                        'opacity': 0.8,
                    ),
                )
            );
        }

        &--clicked {
            &:before,
            &:after {
                right: calc(100% + rem(100px));
                opacity: 0;
            }
        }
    }

    &__btn-list {
        z-index: 9;
        right: rem(100px);
        white-space: nowrap;
        opacity: 1;
        display: flex;
        height: 100%;
        transition: all 250ms ease;
        //margin-right: rem(40px);
    }

    &__list-item {
        display: block;
        margin-right: rem(16px);
        font-size: rem(16px);
        line-height: rem(18px);
        cursor: pointer;
        transition: opacity 150ms ease;

        @include themes(
            (
                'dark': (
                    'color': $dark-gray-8,
                ),
                'light': (
                    'color': $light-gray-70,
                ),
            )
        );

        &:hover {
            @include themes(
                (
                    'dark': (
                        'color': #fff,
                    ),
                    'light': (
                        'color': $light-gray-70,
                        'opacity': 0.8,
                    ),
                )
            );
        }
    }

    &__dropdown-restaurants,
    &__dropdown-menu-button {
        position: absolute;
        left: 50%;
        bottom: rem(105px);
        width: rem(400px);
        transform: translate(-50%, rem(10px));

        &.enter {
            opacity: 0;
            transform: translate(-50%, rem(20px));
            z-index: 100;
        }

        &.enter-active {
            opacity: 1;
            transform: translate(-50%, rem(10px));
            transition: all 300ms;
        }

        &.enter-done {
            opacity: 1;
        }

        &.exit {
            opacity: 0;
            transition: opacity 150ms;
        }
    }

    &__dropdown-menu-button {
        $trs-light: color 300ms linear, $base-background-transition;

        top: -100%;
        height: rem(40px);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 rem(35px);
        border-bottom: rem(1px) solid;
        font-family: 'Nunito', sans-serif;
        font-size: rem(16px);
        cursor: pointer;
        transition: background-color 300ms ease;
        border-radius: rem(8px);

        @include themes(
            (
                'dark': (
                    'background-color': #212430,
                    'border-color': rgba(0, 0, 0, 0.3),
                    'color': #fff,
                ),
                'light': (
                    'background': #fff,
                    'border-color': $light-gray-10,
                    'color': $light-gray-90,
                    'transition': $trs-light,
                ),
            )
        );

        &:hover {
            @include themes(
                (
                    'dark': (
                        'background-color': $dark-blue-1,
                    ),
                    'light': (
                        'background-color': $dark-blue-1,
                        'color': #fff,
                    ),
                )
            );
        }
    }
}
