@import '@styles';

.Keyword {
    &__header {
        display: flex;
    }

    &__content {
        display: flex;
        height: calc(100vh - rem(170px));
    }
}
