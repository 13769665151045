@import '@assets/core.style';

.CanvasChart {
    position: relative;
    border-radius: rem(8px);

    $root: &;

    &__haiku-wrap {
        position: absolute;
        top: rem(5px);
        transform: translateX(-50%);
    }

    &__haiku-logo {
        max-width: 20%;
        opacity: 0.4;
        position: absolute;
        left: 75%;
        bottom: rem(60px);
        width: rem(179px);
        height: rem(52px);
        pointer-events: none;
        @include themes(
            (
                'dark': (
                    'opacity': 0.4,
                ),
                'light': (
                    'opacity': 0.1,
                ),
            )
        );
    }

    &__haiku-popup {
        opacity: 0;
        visibility: hidden;
        transition: opacity 300ms ease;
        position: absolute;
        top: rem(5px);
        left: calc(100% + rem(2px));
        width: rem(290px);
        padding: rem(5px);
        border-radius: rem(3px);
        font-family: 'Work Sans', sans-serif;
        line-height: rem(18px);

        @include themes(
            (
                'dark': (
                    'background-color': #242734,
                    'box-shadow': 0 rem(10px) rem(90px) rgba(0, 0, 0, 0.35),
                    'color': #fff,
                ),
                'light': (
                    'background': #fff,
                    'box-shadow': 0 rem(4px) rem(10px) rgba(0, 0, 0, 0.05),
                    'color': #3a414c,
                ),
            )
        );

        &:before {
            content: '';
            display: block;
            width: rem(8px);
            height: rem(8px);
            border-radius: rem(1px);
            position: absolute;
            top: rem(10px);
            left: rem(1px);
            transform: translateX(-50%) rotate(45deg);

            @include themes(
                (
                    'dark': (
                        'background-color': #242734,
                    ),
                    'light': (
                        'background-color': #fff,
                    ),
                )
            );
        }
    }

    &__haiku-popup-title {
        font-weight: 500;
        font-size: rem(14px);
    }

    &__haiku-popup-description {
        font-size: rem(12px);

        @include themes(
            (
                'dark': (
                    'opacity': 0.7,
                ),
                'light': (
                    'opacity': 1,
                ),
            )
        );
    }
}
