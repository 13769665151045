@import '@assets/core.style';

.KeywordsList {
    width: rem(242px);
    overflow: hidden;
    box-shadow: 0 rem(4px) rem(30px) rgba(0, 0, 0, 0.16);
    border-radius: rem(4px);
    display: none;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;

    $root: &;

    @include themes(
        (
            'dark': (
                'background': $dark-gray-1,
                'color': #fff,
            ),
            'light': (
                'background': #fff,
                'color': $dark-gray-1,
            ),
        )
    );

    &__item {
        position: relative;
        padding-left: rem(16px);
        padding-top: rem(8px);
        padding-bottom: rem(8px);
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: rem(14px);
        line-height: rem(16px);
        cursor: pointer;
        transition: background-color 200ms linear;

        &:hover {
            @include themes(
                (
                    'dark': (
                        'color': $dark-blue-1,
                        'background': $dark-gray-3,
                    ),
                    'light': (
                        'color': #fff,
                        'background': $dark-blue-1,
                    ),
                )
            );
            #{$root}__remove-keyword {
                opacity: 1;
            }
        }
    }

    &__remove-keyword {
        position: absolute;
        top: 50%;
        right: rem(8px);
        height: rem(10px);
        width: rem(10px);
        opacity: 0;
        transform: translateY(-50%);
        transition: background-color 150ms ease, opacity 300ms;
        cursor: pointer;

        &:hover {
            transition: transform 200ms ease;
            transform: translateY(-50%) rotate(90deg);
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            height: rem(2px);
            width: rem(10px);
            border-radius: rem(3px);
            transform-origin: center;
            transition: background-color 150ms ease;
            z-index: 12121;

            @include themes(
                (
                    'dark': (
                        'background-color': $dark-blue-1,
                    ),
                    'light': (
                        'background-color': #fff,
                    ),
                )
            );
        }

        &:before {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
}
