@use '@styles' as *;

.NewTooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: rem(14px);
    line-height: 1.2;
    font-weight: 300;
    padding: rem(4px) rem(8px);
    min-width: rem(50px);
    border-radius: rem(4px);
    filter: drop-shadow(0 rem(4px) rem(6px) rgba(0, 0, 0, 0.5));
    pointer-events: none;
    white-space: normal;
    display: none;

    @include themes(
        (
            'dark': (
                'background': #283547,
                'color': #fff,
            ),
            'light': (
                'background': #fff,
                'color': #283547,
            ),
        )
    );

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: calc(100% - 1px);
        border-width: 5px 7px;
        border-style: solid;
        border-color: transparent;
    }

    &--left {
        left: 0;
        transform: none;

        &:after {
            left: 8px;
        }
    }

    &--right {
        left: auto;
        right: 0;
        transform: none;

        &:after {
            right: 8px;
        }
    }

    &--center {
        &:after {
            left: calc(50% - 7px);
        }
    }

    &--above {
        &:after {
            @include themes(
                (
                    'dark': (
                        'border-top-color': #283547,
                    ),
                    'light': (
                        'border-top-color': #fff,
                    ),
                )
            );
        }
    }

    &--below {
        bottom: auto;
        top: 100%;

        &:after {
            top: auto;
            bottom: 100%;
            @include themes(
                (
                    'dark': (
                        'border-bottom-color': #283547,
                    ),
                    'light': (
                        'border-bottom-color': #fff,
                    ),
                )
            );
        }
    }
}
