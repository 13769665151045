@import '@assets/core.style';

.ExportButton {
    width: rem(95px);
    height: rem(30px);
    margin-left: rem(16px);
    border-radius: rem(50px);
    background-color: $dark-blue-1;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: rem(18px);
    line-height: rem(24px);
    color: #fff;
    cursor: pointer;
    box-sizing: border-box;
    transition: background-color 300ms ease;

    $root: &;

    &:hover {
        background-color: $dark-green-3;
    }

    &--disabled {
        background: #b8c6d8;
        position: relative;

        &:hover {
            background: #b8c6d8;
            & #{$root}__tooltip {
                display: block;
            }
        }
    }

    &__tooltip {
        display: none;
        position: absolute;
        top: rem(-50px);
        right: rem(-10px);
        padding-top: rem(10px);
        padding-bottom: rem(10px);
        height: rem(32px);
        width: rem(265px);
        background: #283547;
        border-radius: rem(4px);
        color: #ffffff;
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: rem(14px);
        line-height: rem(16px);

        &:after {
            content: '';
            display: block;
            width: rem(10px);
            height: rem(10px);
            position: absolute;
            background: #283547;
            transform: rotate(45deg);
            bottom: rem(-5px);
            right: 20%;
        }
    }
}
