@use '@datassential/platform-ui-lib/lib/style/templates' as *;
@use '@datassential/platform-ui-lib/lib/style/utils' as *;

.PlatformHeader {
    z-index: 90;

    &__nav-item {
        cursor: pointer;
        @include header-nav-item();
    }

    &__nav-icon {
        width: rem(12px);
        height: rem(12px);
        margin-top: rem(2px);
        margin-left: rem(8px);
    }

    &__search-placeholder {
        height: rem(100px);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    &__search-item {
        display: block;
    }

    &__search-item-title {
        vertical-align: middle;
    }

    &__search-item-sub-title {
        font-size: 80%;
        vertical-align: middle;
    }

    &__search-action {
        &.is-medium {
            width: rem(55px);
        }

        &.is-small {
            width: rem(42px);
        }
    }
}
