@import '@assets/core.style';

.search-field {
    position: relative;
    width: rem(380px);
    height: 100%;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    transition: transform 500ms ease, height 500ms ease, border-color 150ms ease;

    $root: &;

    @media all and (max-width: 1440px) {
        width: 40%;
    }

    &--static {
        @media all and (max-width: 1440px) {
            width: rem(380px);
        }
    }

    &--fluid {
        width: 100%;
    }

    &--contrast {
        #{$root}__input {
            @include themes(
                (
                    'dark': (
                        'background-color': $dark-gray-2,
                    ),
                    'light': (
                        'border': rem(1px) solid $dark-gray-6,
                    ),
                )
            );

            &::placeholder {
                @include themes(
                    (
                        'dark': (
                            'color': #d7e3f3,
                        ),
                        'light': (
                            'color': $light-gray-80,
                        ),
                    )
                );
            }
        }
    }

    &__error {
        position: absolute;
        top: rem(-16px);
        right: 0;
        font-size: rem(12px);
        color: $dark-red-1;
        opacity: 0;
        visibility: hidden;
        transition: opacity 150ms ease;
        width: rem(300px);
        padding-left: rem(15px);
        text-align: right;

        &--shown {
            opacity: 1;
            visibility: visible;
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: rem(7px);
        transform: translateY(-50%);
        opacity: 0.5;
        transition: opacity 150ms ease;
    }

    &__input {
        width: 100%;
        height: 100%;
        border: 0;
        padding: rem(8px) rem(16px);
        border-radius: rem(8px);
        font-size: rem(22px);
        line-height: 1.1;
        outline: none;
        background-image: linear-gradient(
            135deg,
            transparent 0%,
            rgba($dark-gray-5, 0.4) 50%,
            transparent 60%
        );
        animation: cell-loading 5s infinite;
        background-size: 500%;
        background-repeat: no-repeat;

        @include themes(
            (
                'dark': (
                    'background-color': $dark-gray-2,
                    'color': #d7e3f3,
                ),
                'light': (
                    'background': #fff,
                    'box-shadow': 0 rem(8px) rem(10px) rgba(176, 195, 208, 0.24),
                    'color': $light-gray-90,
                ),
            )
        );

        &::placeholder {
            opacity: 0.4;

            @include themes(
                (
                    'dark': (
                        'color': #d7e3f3,
                    ),
                    'light': (
                        'color': $light-gray-40,
                    ),
                )
            );
        }

        &:focus {
            & ~ #{$root}__icon {
                opacity: 0;
            }
        }
    }

    &__clear {
        position: absolute;
        top: 50%;
        right: rem(8px);
        width: rem(24px);
        height: rem(24px);
        transform: translateY(-50%);
        cursor: pointer;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            height: rem(2px);
            width: rem(18px);
            border-radius: rem(3px);

            @include themes(
                (
                    'dark': (
                        'background-color': #fff,
                    ),
                    'light': (
                        'background-color': $dark-gray-1,
                    ),
                )
            );
        }

        &:before {
            transform: translate(-50%, -50%) rotate(-45deg);
        }

        &:after {
            transform: translate(-50%, -50%) rotate(45deg);
        }
    }
}
