@import '@assets/core.style';

.attributes {
    height: 100%;
    font-family: 'Nunito', sans-serif;
    font-size: rem(18px);
    animation: appear 300ms ease-in;

    $root: &;
    $col-gap: 20px;

    @media all and (max-width: 1440px) {
        font-size: rem(13px);
    }

    &__header {
        display: flex;
        padding: rem(15px) 0 0;
        gap: rem($col-gap);

        @include themes(
            (
                'dark': (
                    'border-color': $dark-gray-6,
                ),
                'light': (
                    'border-color': $light-gray-20,
                ),
            )
        );
    }

    &__header-item {
        font-size: rem(16px);
        line-height: rem(24px);

        @include themes(
            (
                'light': (
                    'font-weight': 600,
                    'color': $light-gray-90,
                ),
            )
        );
    }

    &__content {
        position: relative;
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding: 0;
        gap: rem($col-gap);

        & #{$root}__ethnic {
            margin-left: rem(24px);
        }
    }

    &__col {
        display: flex;
        flex-direction: column;
        width: calc(calc(100% - 2 * #{rem($col-gap)}) / 3);

        @include media-max(1440px) {
            &-1 {
                width: 25%;
                padding-right: rem(20px);
            }

            &-2 {
                width: 30%;
                padding-right: rem(20px);
            }

            &-3 {
                width: 30%;
                padding-right: rem(20px);
            }
        }

        @include media-max(1140px) {
            &-1 {
                width: 30%;
            }

            &-2 {
                width: 35%;
            }

            &-3 {
                width: 35%;
            }
        }
    }

    &__top {
        margin-top: rem(60px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: rem(35px);
        border-bottom: rem(1px) solid;

        @include themes(
            (
                'dark': (
                    'border-color': #363942,
                ),
                'light': (
                    'border-color': rgba(#363942, 0.5),
                ),
            )
        );
    }

    &__ethnic-msg {
        height: rem(24px);
        font-family: 'Work Sans', sans-serif;
        font-size: rem(15px);
        line-height: 1;
        transition: $base-background-transition;

        @include themes(
            (
                'dark': (
                    'color': rgba(255, 255, 255, 0.5),
                ),
                'light': (
                    'color': rgba(#3a414c, 0.5),
                ),
            )
        );

        @media all and (max-width: 1440px) {
            font-size: rem(12px);
        }
    }

    &__dropdown-btn {
        display: block;
        margin-top: rem(13px);
        color: $body-text;
        font-size: rem(16px);
        line-height: rem(18px);
        cursor: pointer;

        @media all and (max-width: 1440px) {
            font-size: rem(12px);
            line-height: rem(14px);
        }

        &--underline {
            text-decoration: underline;
            transition: color 300ms;

            &:hover {
                color: #fff;
            }
        }

        &:hover {
            text-decoration: none;
        }
    }

    &__input-container {
        margin-top: rem(32px);
        width: rem(270px);
        margin-right: rem(60px);

        &--wide {
            margin-top: rem(10px);
            width: rem(300px);
            margin-right: 0;
        }
    }

    &__text {
        position: relative;

        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': #3a414c,
                ),
            )
        );

        &--underline {
            text-decoration: underline;
            cursor: pointer;
            max-width: rem(320px);
            font-size: rem(18px);
            line-height: rem(20px);

            @media all and (max-width: 1440px) {
                font-size: rem(13px);
                line-height: normal;
            }
        }
    }

    &__radio-form {
        display: flex;
        flex-direction: column;
    }

    &__radio {
        margin: 0 0 rem(24px) 0;
        justify-content: space-between;
    }

    &__bot {
        display: flex;
        padding-top: rem(35px);
    }

    &__btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;

        @include themes(
            (
                'light': (
                    'color': #3a414c,
                ),
            )
        );

        &--right {
            right: 0;
            left: auto;
        }

        &:hover {
            #{$root}__arrow {
                transform: translateX(#{rem(20px)});
                opacity: 0;
                transition: opacity 250ms ease, transform 250ms ease;

                &--revert {
                    transform: translateX(#{rem(-20px)}) rotate(180deg);
                }
            }
        }
    }

    &__more-filters {
        width: 100%;
        height: 100%;
        display: flex;
    }

    &__arrow {
        margin-left: rem(12px);
        transition: opacity 250ms ease, transform 250ms ease;
        transform-origin: center;
        width: rem(8px);
        height: rem(12px);

        @include themes(
            (
                'dark': (
                    'fill': #fff,
                ),
                'light': (
                    'fill': #3a414c,
                ),
            )
        );

        @media all and (max-width: 1440px) {
            width: rem(6px);
            height: rem(9px);
        }

        &--revert {
            transform: rotate(180deg);
            margin-left: 0;
            margin-right: rem(12px);
        }

        &--absolute {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            margin: 0;

            &.rotated {
                transform: translateY(-50%) rotate(90deg);
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        border-top: rem(1px) solid;
        padding: rem(30px) rem(56px);
        position: relative;
        z-index: -1;

        @include themes(
            (
                'dark': (
                    'border-color': $dark-gray-6,
                ),
                'light': (
                    'border-color': rgba(#363942, 0.5),
                    'color': #3a414c,
                ),
            )
        );
    }

    &__footer-text {
        font-size: rem(18px);

        @include themes(
            (
                'dark': (
                    color: #fff,
                ),
                'light': (
                    color: #3a414c,
                ),
            )
        );

        @media all and (max-width: 1440px) {
            font-size: rem(14px);
        }
    }

    &__input {
        font-size: rem(22px);
        border-bottom: rem(1px) solid;

        @include themes(
            (
                'dark': (
                    'border-color': #fff,
                ),
                'light': (
                    'border-color': #3a414c,
                    'color': #3a414c,
                ),
            )
        );

        @media all and (max-width: 1440px) {
            font-size: rem(14px);
        }
    }

    &--is-full {
        #{$root}__header {
            border-bottom: none;
        }

        #{$root}__header,
        #{$root}__footer {
            padding-right: 0;
        }

        #{$root}__content {
            padding-bottom: 0;
            padding-right: 0;
        }
    }

    &__checkbox {
        padding: rem(6px) 0;
    }
}

.attributes-btn {
    &-enter,
    &-exit-done {
        transform: translateX(#{rem(30px)});
        opacity: 0;
    }

    &-enter-active {
        transition: transform 300ms ease, opacity 300ms ease;
        transform: translateX(0);
        opacity: 1;
    }

    &-enter-done,
    &-exit {
        opacity: 1;
        transform: translateX(0);
    }

    &-exit-active {
        transition: transform 300ms ease, opacity 300ms ease;
        opacity: 0;
        transform: translateX(#{rem(30px)});
    }
}

.attributes-filters {
    &-enter,
    &-exit-done {
        transform: translateX(#{rem(30px)});
        opacity: 0;
    }

    &-enter-active {
        transition: transform 300ms ease, opacity 300ms ease;
        transform: translateX(0);
        opacity: 1;
    }

    &-enter-done,
    &-exit {
        opacity: 1;
        transform: translateX(0);
    }

    &-exit-active {
        transition: transform 300ms ease, opacity 300ms ease;
        opacity: 0;
        transform: translateX(#{rem(30px)});
    }
}

.categories-dropdown {
    position: absolute;
    top: calc(100% + #{rem(10px)});
    left: 0;
    width: rem(300px);
    height: rem(130px);
    border-radius: rem(2px);
    background-color: #242734;
    padding: rem(15px) rem(10px);
    z-index: 102;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media all and (max-width: 1440px) {
        height: rem(90px);
        padding: rem(8px);
    }

    &__item {
        text-align: left;
        max-width: rem(355px);
        color: rgba(#fff, 0.5);
        font-size: rem(18px);
        line-height: rem(20px);
        transition: color 200ms ease;
        cursor: pointer;

        &:hover {
            color: #fff;
        }

        &.hidden {
            display: none;
        }

        @media all and (max-width: 1440px) {
            font-size: rem(13px);
            line-height: normal;
        }
    }

    &-enter {
        opacity: 0;
    }

    &-enter-active {
        opacity: 1;
        transition: opacity 200ms;
    }

    &-enter-done {
        opacity: 1;
    }

    &-exit {
        opacity: 0;
        transition: opacity 150ms;
    }
}
