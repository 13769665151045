@use 'sass:math';

//variables
$base-background-transition: background 300ms linear;
$admin-loader-index: 99;

// colors
$dark-gray-0: #131921;
$dark-gray-1: #1c2531;
$dark-gray-2: #232d3b;
$dark-gray-3: #283547;
$dark-gray-4: #34455c;
$dark-gray-5: #40546f;
$dark-gray-6: #4a6282;
$dark-gray-7: #bec0c4;
$dark-gray-8: #b8c6d8;
$dark-gray-9: #788ba3;
$dark-gray-10: #98a1ad;
$dark-red-1: #f55353;
$dark-blue-1: #4794ff;
$dark-blue-2: #698af5;
$dark-blue-3: #2480ff;
$dark-orange-1: #ff9800;
$dark-green-1: #25b7a5;
$dark-green-2: #0aa648;
$dark-green-3: #009688;
$dark-green-4: #59e0d0;
$light-gray-100: #1a2027;
$light-gray-90: #1c2531;
$light-gray-80: #313940;
$light-gray-70: #464f56;
$light-gray-60: #5b6671;
$light-gray-50: #788390;
$light-gray-40: #98a1ad;
$light-gray-30: #b5bdc7;
$light-gray-20: #d2d8e2;
$light-gray-10: #edf1f8;
$light-gray-0: #f2f6fa;
$body-text: #d7e3f3;
$darken: rgba(#000, 0.1);

// mixins

@mixin lightTheme() {
    .theme-light & {
        @content;
    }
}

@mixin themes($local) {
    $darkProps: map-get($local, dark);
    $lightProps: map-get($local, light);

    .theme-dark & {
        @each $prop, $value in $darkProps {
            #{$prop}: #{$value};
        }
    }

    .theme-light & {
        @each $prop, $value in $lightProps {
            #{$prop}: #{$value};
        }
    }
}

// Media queries
$breakpoints: (
    'xss': 420px,
    'xs': 568px,
    'sm': 768px,
    'md': 992px,
    'lg': 1200px,
    'xls': 1440px,
    'xl': 1600px,
    'xxl': 1900px,
);

@mixin mq($width, $type: max) {
    @if map-has-key($breakpoints, $width) {
        $width: map-get($breakpoints, $width);

        @if ($type == max) {
            $width: $width - 1px;
        }

        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}

@mixin media-min($media-min, $property: width) {
    @media only screen and (min-#{$property}: $media-min) {
        @content;
    }
}

@mixin media-max($media-max, $property: width) {
    @media only screen and (max-#{$property}: $media-max) {
        @content;
    }
}

@mixin media($media-min, $media-max, $property: width) {
    @media only screen and (min-#{$property}: $media-min) and (max-#{$property}: $media-max) {
        @content;
    }
}

@mixin timer($item, $duration, $size, $color, $hover: running) {
    #{$item} {
        width: $size;
        height: $size;

        .pie {
            width: 50%;
            height: 100%;
            transform-origin: 100% 50%;
            position: absolute;

            @include themes(
                (
                    'dark': (
                        'background': $color,
                    ),
                    'light': (
                        'background-color': $light-gray-20,
                    ),
                )
            );
        }

        .spinner {
            border-radius: 100% 0 0 100% / 50% 0 0 50%;
            z-index: 200;
            border-right: none;
            animation: rota $duration + s linear infinite;
        }

        &:hover {
            .spinner,
            .filler,
            .mask {
                animation-play-state: $hover;
            }
        }

        .filler {
            border-radius: 0 100% 100% 0 / 0 50% 50% 0;
            left: 50%;
            opacity: 0;
            z-index: 100;
            animation: opa $duration + s steps(1, end) reverse infinite;
            border-left: none;
        }

        .mask {
            width: 50%;
            height: 100%;
            position: absolute;
            background: inherit;
            opacity: 1;
            z-index: 300;
            animation: opa $duration + s steps(1, end) infinite;
        }
    }

    @keyframes rota {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes opa {
        0% {
            opacity: 1;
        }

        50%,
        100% {
            opacity: 0;
        }
    }
}

@mixin hardwareAcceleration() {
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
}

// functions

@function size_tablet($font-size) {
    @return (math.div($font-size, 1024px)) * 100vw;
}

@mixin truncate($width: 100%) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: $width;
}

@mixin multilines-ellipsis($lines: 3) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
}

$root-size: 16px;
$root-responsive-mq: 1920px;

@function stripUnit($value) {
    @return math.div($value, $value * 0 + 1);
}

@function rem($pxValue) {
    //@return $pxValue;
    @return #{math.div(stripUnit($pxValue), stripUnit($root-size))}rem;
}

@function size($font-size, $breakpoint: $root-responsive-mq) {
    @return math.div($font-size, $breakpoint) * 100vw;
}

%keyword {
    font-weight: 300;
}

%tag-keyword {
    font-weight: 900;
}
