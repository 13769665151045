@import '@styles';

.HiddenCountriesPopup {
    position: absolute;
    width: rem(430px);
    background: #131921;
    box-shadow: 0 rem(4px) rem(20px) rgba(0, 0, 0, 0.1);
    border-radius: rem(8px);
    transform: translateY(-20%);
    padding: rem(15px);
    z-index: 1;

    &__title {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: rem(16px);
        line-height: rem(24px);
        color: #fff;
        padding-left: rem(8px);
    }

    &__flags {
        display: flex;
        flex-wrap: wrap;
    }

    &:before {
        content: '';
        width: rem(24px);
        height: rem(24px);
        background: #131921;
        position: absolute;
        transform: rotate(45deg);
        left: rem(-10px);
        top: 20%;
        z-index: 0;
    }
}
