@import '@styles';

.AdminFiltersContainer {
    padding-top: rem(8px);

    &__title {
        display: flex;
        align-items: center;
        padding-bottom: rem(24px);
    }

    &__row {
        &--has-border {
            padding-top: rem(24px);
            border-top: rem(1px) solid;

            @include themes(
                (
                    'dark': (
                        'border-color': $dark-gray-6,
                    ),
                    'light': (
                        'border-color': $light-gray-20,
                    ),
                )
            );
        }
    }

    &__columns {
        display: flex;
        position: relative;
        justify-content: space-between;
        padding-bottom: rem(24px);

        @include themes(
            (
                'light': (
                    'border-color': $light-gray-20,
                ),
            )
        );
    }

    &__column {
        max-width: 36%;
        width: 36%;
        padding-right: 5%;
    }

    &__sub-row {
        padding-bottom: rem(36px);
    }

    &__sub-title {
        margin-bottom: rem(8px);
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: rem(16px);
        line-height: rem(24px);

        @include themes(
            (
                'dark': (
                    'color': $body-text,
                ),
                'light': (
                    'color': $light-gray-90,
                ),
            )
        );

        @media all and (max-width: 1440px) {
            font-size: rem(18px);
            line-height: rem(20px);
        }
    }

    &__additional-filters {
        font-size: rem(14px);
        line-height: rem(24px);
        color: rgba(255, 255, 255, 0.5);

        @media all and (max-width: 1440px) {
            font-size: rem(14px);
            line-height: rem(16px);
        }

        &--large {
            font-size: rem(18px);

            @include themes(
                (
                    'dark': (
                        'color': #fff,
                    ),
                    'light': (
                        'color': #3a414c,
                    ),
                )
            );
        }
    }

    .filters-auto-complete {
        z-index: $admin-loader-index - 2;
    }
}
