@import '@assets/core.style';

.tabs {
    display: flex;
    flex-direction: column;
    margin: rem(8px) 0;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: rem(14px);
    position: relative;

    &__title {
        text-transform: uppercase;
        font-size: rem(10px);
        margin-bottom: rem(4px);

        @include themes(
            (
                'dark': (
                    'color': $body-text,
                ),
                'light': (
                    'color': $light-gray-40,
                ),
            )
        );
    }

    .TooltipTabs {
        white-space: nowrap;
        left: 50%;
        transform: translate(-50%, -100%);

        &:after {
            left: 50%;
        }
    }

    &__nav {
        display: flex;
        align-items: center;
    }

    &__item {
        position: relative;
        font-weight: 400;
        font-size: rem(15px);
        line-height: rem(18px);
        cursor: pointer;
        transition: color 300ms;

        @include media-max(1400px) {
            font-size: rem(13px);
        }

        @include themes(
            (
                'dark': (
                    'color': $body-text,
                ),
                'light': (
                    'color': $light-gray-90,
                ),
            )
        );

        &:hover {
            @include themes(
                (
                    'dark': (
                        'color': #fff,
                    ),
                    'light': (
                        'color': $dark-blue-1,
                    ),
                )
            );
        }

        &:after {
            content: '';
            position: absolute;
            bottom: rem(-8px);
            left: 0;
            width: 0;
            height: rem(2px);
            background-color: currentColor;
            transition: width 300ms;
        }

        &--active {
            font-weight: 600;

            @include themes(
                (
                    'dark': (
                        'color': #fff,
                    ),
                    'light': (
                        'color': $dark-blue-3,
                    ),
                )
            );

            &:after {
                width: 100%;
            }
        }

        &:not(:first-child) {
            $indent: 12px;

            margin-left: rem($indent * 2);

            &:before {
                content: '';
                position: absolute;
                left: rem(-$indent);
                top: 50%;
                transform: translateY(-50%);
                width: rem(1px);
                height: rem(20px);
                @include themes(
                    (
                        'dark': (
                            'background': rgba(#fff, 0.5),
                        ),
                        'light': (
                            'background': rgba(#000, 0.2),
                        ),
                    )
                );
            }
        }
    }
}
