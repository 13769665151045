@import '@styles';

.manage-countries {
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem(-24px);
}

.manage-country {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 33%;
    padding: rem(24px);

    &--disabled {
        pointer-events: none;
        filter: grayscale(100%);
    }

    .Flag {
        width: rem(100px);
        padding: 0 rem(24px) 0 0;
    }

    .Select {
        min-width: rem(200px);
        padding: 0;

        &__input {
            &:after {
                top: rem(4px);
            }
        }

        &__input-dropdown {
            min-width: rem(140px);
        }
    }
}
