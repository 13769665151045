@import '@assets/core.style';

.LoadByoSettingsContainer {
    $base: &;

    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: column;
    height: 80vh;
    width: rem(1400px);
    max-width: 95vw;
    margin: 10vh auto;
    border-radius: rem(4px);
    font-family: Nunito, sans-serif;

    @include themes(
        (
            'dark': (
                'background': $dark-gray-2,
            ),
            'light': (
                'background': #fff,
                'box-shadow': 0 rem(8px) rem(20px) rgba(176, 195, 208, 0.24),
            ),
        )
    );

    &__search-wr {
        width: rem(330px);
        position: absolute;
        z-index: 1;
        top: rem(-67px);
        padding-top: rem(20px);

        button {
            &:before,
            &:after {
                background-color: #fff;
            }
        }

        .search {
            @include themes(
                (
                    'light': (
                        'border-color': $light-gray-20,
                    ),
                )
            );

            &__input {
                @include themes(
                    (
                        'light': (
                            'color': $light-gray-20,
                        ),
                    )
                );

                &::placeholder {
                    @include themes(
                        (
                            'light': (
                                'color': $light-gray-20,
                            ),
                        )
                    );
                }
            }
        }
    }

    &__buttons-wrap {
        visibility: hidden;
        display: flex;
        align-items: center;
    }

    &__button {
        margin-left: rem(16px);
        cursor: pointer;
        transition: transform 300ms, background-color 300ms;

        &:hover {
            transform: scale(1.1);
        }

        &--edit {
            width: rem(64px);
            height: rem(24px);
            background-color: $dark-blue-1;
            border-radius: rem(48px);
            font-weight: 600;
            font-size: rem(14px);
            line-height: rem(16px);
            color: #fff;

            &:hover {
                background-color: $dark-green-3;
            }
        }
    }

    &__close-icon {
        path {
            @include themes(
                (
                    'light': (
                        'stroke': $light-gray-90,
                    ),
                )
            );
        }
    }

    &__row {
        display: flex;
        height: 100%;

        &:hover {
            #{$base}__column {
                @include themes(
                    (
                        'dark': (
                            'background-color': $dark-gray-3,
                        ),
                        'light': (
                            'background-color': $light-gray-10,
                        ),
                    )
                );
            }
            #{$base}__buttons-wrap {
                visibility: visible;
            }
        }
    }

    &__column {
        @include themes(
            (
                'light': (
                    'border-radius': 0,
                ),
            )
        );

        &.Cell--head {
            @include themes(
                (
                    'light': (
                        'background-color': $light-gray-20,
                    ),
                )
            );
        }

        &:nth-child(1) {
            width: 25%;
            border-top-left-radius: rem(8px);

            &.Cell--head {
                @include themes(
                    (
                        'light': (
                            'border-top-left-radius': rem(4px),
                        ),
                    )
                );
            }
        }

        &:nth-child(2) {
            width: 45%;
        }

        &:nth-child(3) {
            width: 10%;
        }

        &:nth-child(4) {
            width: 20%;
            border-top-right-radius: rem(8px);

            &.Cell--head {
                @include themes(
                    (
                        'light': (
                            'border-top-right-radius': rem(4px),
                        ),
                    )
                );
            }
        }
    }
}
