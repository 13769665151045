@use '@styles' as *;

.PermissionOverlay {
    $root: &;

    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    backdrop-filter: blur(5px);
    z-index: 2;
    @include themes(
        (
            'dark': (
                'background': rgba($dark-gray-2, 0.3),
            ),
            'light': (
                'background': rgba($light-gray-20, 0.3),
            ),
        )
    );

    &--row {
        #{$root}__content {
            flex-direction: row;
            width: 100%;
            height: 100%;
            padding: 0 rem(20px);
        }

        #{$root}__icon {
            margin: 0;
        }

        #{$root}__description {
            margin-bottom: 0;
            @include media-max(1300px) {
                font-size: rem(12px);
                line-height: rem(14px);
            }
        }

        #{$root}__title {
            font-size: rem(16px);
            line-height: rem(16px);
            @include media-max(1300px) {
                font-size: rem(14px);
                line-height: rem(16px);
            }
        }

        #{$root}__text {
            margin: 0 rem(20px);
        }
    }

    &--narrow {
        #{$root}__text {
            @include media-max(1400px) {
                margin: 0 rem(10px);
            }
        }

        #{$root}__title {
            @include media-max(1600px) {
                font-size: rem(14px);
                line-height: rem(16px);
            }
            @include media-max(1400px) {
                font-size: rem(12px);
                line-height: rem(14px);
            }
        }

        #{$root}__description {
            @include media-max(1600px) {
                font-size: rem(12px);
                line-height: rem(14px);
            }

            @include media-max(1600px) {
                font-size: rem(10px);
                line-height: rem(12px);
            }
        }

        #{$root}__button {
            @include media-max(1600px) {
                min-width: rem(120px);
                padding: 0 rem(10px);
                font-size: rem(14px);
            }
            @include media-max(1400px) {
                min-width: 0;
                font-size: rem(12px);
                height: rem(24px);
            }
        }
    }

    &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
        height: 220px;
        padding: rem(5px) rem(60px);
        border-radius: rem(4px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        white-space: nowrap;
        @include themes(
            (
                'dark': (
                    'background': $dark-gray-2,
                ),
                'light': (
                    'background': $light-gray-20,
                ),
            )
        );
    }

    &__icon {
        margin-bottom: rem(20px);

        * {
            @include themes(
                (
                    'dark': (
                        'fill': #fff,
                    ),
                    'light': (
                        'fill': $dark-gray-1,
                    ),
                )
            );
        }
    }

    &__title {
        color: #fff;
        font-size: rem(16px);
        line-height: rem(20px);
        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': $dark-gray-1,
                ),
            )
        );
    }

    &__description {
        font-size: rem(14px);
        line-height: rem(18px);
        margin-bottom: rem(20px);
        @include themes(
            (
                'dark': (
                    'color': $light-gray-20,
                ),
                'light': (
                    'color': $dark-gray-3,
                ),
            )
        );

    }

    &__button {
        background: #26b7a5;
        color: #ffffff;
        height: rem(34px);
        padding: 0 rem(15px);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        border-radius: rem(17px);
        font-size: rem(18px);
        min-width: rem(140px);
        cursor: pointer;
        transition: background 300ms;
        flex-shrink: 0;

        &:hover {
            background: darken(#26b7a5, 10%);
        }

        &--inactive,
        &:disabled {
            pointer-events: none;
            background: #aaa;
            //opacity: 0.7;
        }
    }
}
