@use '@datassential/platform-ui-lib/lib/style/constants' as *;
@import '@assets/core.style';

.FiltersContainer {
    $padding: 32px;

    max-height: 96vh;
    min-width: rem(1050px);
    position: relative;
    overflow-x: hidden;

    &.Popup {
        padding: 0;
    }

    &__sub-header {
        padding: rem(16px) 0;
        border-bottom: rem(1px) solid;
        margin-bottom: rem(16px);
        flex: 1 0 auto;
        display: flex;
        justify-content: flex-end;

        @include themes(
            (
                'dark': (
                    'border-color': $dark-gray-6,
                    'color': #fff,
                ),
                'light': (
                    'color': $light-gray-90,
                    'border-color': $light-gray-20,
                ),
            )
        );
    }

    &__select-btn {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: rem(14px);
        line-height: rem(16px);
        color: $dark-gray-10;
        cursor: pointer;
        user-select: none;

        &--active {
            @include themes(
                (
                    'dark': (
                        color: #ffffff,
                    ),
                    'light': (
                        color: $light-gray-90,
                    ),
                )
            );
        }
    }

    &__header {
        display: flex;
        padding: rem(16px) rem($padding);
        position: sticky;
        top: 0;
        z-index: 4;

        @include themes(
            (
                'dark': (
                    'background': #2e3c4f,
                ),
                'light': (
                    'background': #fff,
                ),
            )
        );
    }

    &__description {
        display: flex;
        flex-direction: column;
        font-family: 'Montserrat', sans-serif;
        font-size: rem(14px);
        line-height: rem(17px);
        padding: 0 rem(32px);

        @include themes(
            (
                'dark': (
                    'color': #fff,
                    'background': #2e3c4f,
                ),
                'light': (
                    'color': $dark-gray-1,
                    'background': #fff,
                ),
            )
        );

        span {
            color: $platform-color-main;
            font-weight: 700;
        }
    }

    &__tabs-tooltip {
        position: absolute;
        top: 0;
        right: 0;
        max-width: 50%;
        padding: rem(16px) rem($padding) rem(8px) rem(8px);
        pointer-events: none;
        opacity: 0;
    }

    &__tabs-tooltip-title {
        font-size: rem(16px);
        line-height: rem(20px);
        font-weight: 700;
    }

    &__content {
        padding: rem(16px) rem($padding);
        height: rem(500px);
        overflow-x: hidden;
    }

    &__footer {
        position: sticky;
        bottom: 0;
        display: flex;
        justify-content: flex-end;
        padding: rem(16px) rem($padding);
        z-index: 4;

        @include themes(
            (
                'dark': (
                    'background': $dark-gray-3,
                ),
                'light': (
                    'background': $light-gray-0,
                ),
            )
        );
    }

    &__footer-button {
        margin-left: rem(20px);

        &.naked {
            @include themes(
                (
                    'dark': (
                        'color': white,
                    ),
                    'light': (
                        'color': $dark-gray-1,
                    ),
                )
            );
        }
    }

    .tabs {
        margin-right: rem(10px);

        &:nth-child(n + 2) {
            border-left: rem(1px) solid;
            padding-left: rem(10px);

            @include themes(
                (
                    'dark': (
                        'border-color': rgba(#fff, 0.5),
                    ),
                    'light': (
                        'border-color': rgba(#000, 0.2),
                    ),
                )
            );
        }

        &__title {
            font-size: rem(12px);
        }

        &__item {
            font-size: rem(18px);
        }
    }

    .is-place {
        &:hover {
            & ~ .is-place {
                opacity: 1;
                transition: opacity 700ms;
            }
        }
    }

    .is-food {
        &:hover {
            & ~ .is-food {
                opacity: 1;
                transition: opacity 700ms;
            }
        }
    }
}

.FiltersWidget {
    border-radius: rem(8px);
    padding: rem(8px) rem(16px);

    &.small {
        padding: rem(8px);
    }

    &,
    &__header,
    &__content {
        @include themes(
            (
                'dark': (
                    'background': #171e28,
                ),
                'light': (
                    'background': $light-gray-0,
                ),
            )
        );
    }

    &__header {
        padding: rem(8px) rem(16px);
        justify-content: center;
        border-radius: rem(8px) rem(8px) 0 0;
        border-bottom: rem(1px) solid;
        @include themes(
            (
                'dark': (
                    'border-color': $dark-gray-6,
                ),
                'light': (
                    'border-color': $light-gray-20,
                ),
            )
        );
    }

    &__content {
        padding: rem(16px);
        border-radius: 0 0 rem(8px) rem(8px);
    }
}
