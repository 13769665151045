@use 'sass:math';
@use '@styles' as *;

$border_style: rem(1px) solid $dark-gray-4;

@keyframes cell-loading {
    from {
        background-position: 100% 0;
    }

    to {
        background-position: -50% 0;
    }
}

.CellNew {
    padding: 0 rem(24px);
    display: flex;
    align-items: center;
    border-bottom: $border_style;
    width: 100%;
    position: relative;
    height: rem(50px);
    font-size: inherit;
    transition: background-color 300ms;
    background: $dark-gray-2;
    color: $body-text;

    $root: &;

    @include themes(
        (
            'light': (
                'background-color': #fff,
                'color': $light-gray-70,
                'border-color': $light-gray-10,
            ),
        )
    );

    &--half {
        height: 50%;
    }

    &--no-p {
        padding: 0;
    }

    &--center {
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    &--right {
        justify-content: flex-end;
        align-items: center;
        text-align: right;
    }

    &--bg-lighter {
        @include themes(
            (
                'dark': (
                    'background': $dark-gray-5,
                ),
                'light': (
                    'background-color': $light-gray-30,
                    'border': none,
                ),
            )
        );
    }

    &--bg-dark {
        @include themes(
            (
                'dark': (
                    'background': darken($dark-gray-5, 18%),
                ),
                'light': (
                    'background-color': $light-gray-0,
                ),
            )
        );
    }

    &--bg-head {
        @include themes(
            (
                'dark': (
                    'background': $dark-gray-3,
                ),
                'light': (
                    'background-color': $light-gray-0,
                    'color': $light-gray-90,
                ),
            )
        );
        &#{$root}--bg-dark {
            @include themes(
                            (
                                    'dark': (
                                            'background': darken($dark-gray-5, 18%),
                                    ),
                                    'light': (
                                            'background-color': $light-gray-0,
                                    ),
                            )
            );
        }
    }

    &--bg-darken {
        @include themes(
            (
                'dark': (
                    'background': $dark-gray-4,
                ),
                'light': (
                    'background-color': $light-gray-40,
                ),
            )
        );
    }

    &:after {
        display: block;
        position: absolute;
        bottom: rem(-1px);
        width: rem(24px);
        height: rem(1px);

        @include themes(
            (
                'dark': (
                    'background': $dark-gray-2,
                ),
                'light': (
                    'background-color': #fff,
                ),
            )
        );
    }
}
