@use 'sass:math';

@import '@assets/core.style.scss';

.ComparePopup-wr {
    position: relative;
    height: 100vh;
    padding: rem(24px);
    z-index: 103;
}

.ComparePopup {
    width: 100%;
    height: 100%;
    padding: rem(24px);
    box-shadow: 0 rem(4px) rem(20px) rgba(0, 0, 0, 0.1);
    border-radius: rem(8px);
    overflow: auto;

    $root: &;

    @include themes(
        (
            'dark': (
                'background': #1c2531,
            ),
            'light': (
                'background': #fff,
                'color': $dark-gray-0,
            ),
        )
    );

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: rem(16px);
    }

    &__title {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: rem(24px);
        line-height: rem(32px);

        @include themes(
            (
                'dark': (
                    color: #fff,
                ),
                'light': (
                    color: $dark-gray-0,
                ),
            )
        );
    }

    &__close {
        .btn {
            width: rem(128px);
            padding: rem(4px) rem(8px);
            font-family: 'Nunito', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: rem(16px);
            line-height: rem(24px);
            letter-spacing: 0.012em;
        }
    }

    &__heading {
        padding-bottom: rem(24px);
    }

    &__name {
        text-transform: uppercase;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: rem(24px);
        line-height: rem(32px);
        @include themes(
            (
                'dark': (
                    color: #fff,
                ),
                'light': (
                    color: $dark-gray-0,
                ),
            )
        );
    }
}

.compare-table {
    display: flex;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-size: rem(16px);
    line-height: rem(24px);
    letter-spacing: 0.012em;

    @include themes(
        (
            'dark': (
                color: #b8c6d8,
            ),
            'light': (
                color: $dark-gray-0,
            ),
        )
    );

    &__col {
        &--1 {
            width: percentage(math.div(528, 1872));
            display: flex;
            align-items: center;
            padding-left: rem(5px);
            border-radius: rem(8px);
        }

        &--2 {
            width: percentage(math.div(640, 1872));
            margin-left: rem(16px);
            display: flex;
            align-items: center;
            padding-top: rem(10px);
            padding-bottom: rem(10px);
            border-radius: rem(8px);
            min-height: rem(44px);
            padding-left: rem(5px);

            @include themes(
                (
                    'dark': (
                        'background-color': #232d3b,
                    ),
                    'light': (
                        'background-color': $light-gray-10,
                    ),
                )
            );
        }

        &--isDifferent {
            @include themes(
                (
                    'dark': (
                        'background': #4a6282,
                    ),
                    'light': (
                        'background': $light-gray-20,
                        'color': $dark-gray-0,
                    ),
                )
            );
        }
    }

    &__row {
        padding: rem(8px) rem(16px);
        padding-left: 0;
        display: flex;
    }

    &__section {
        margin-bottom: rem(16px);
        width: 100%;
    }

    .Subtitle {
        padding: rem(16px);
    }

    &__top {
        //height: rem(186px);
        height: 100%;
        width: 100%;

        .settings-dropdown {
            &__input-dropdown {
                top: rem(72px);
            }
        }
    }

    &__countries {
        display: flex;
        flex-wrap: wrap;
        padding: 0 rem(8px) rem(24px);
        //border-bottom: rem(1px) solid #4A6282;
    }

    &__wrap {
        margin: rem(16px) 0;
        padding: rem(48px) 0 0;
    }

    &__cell {
        padding: rem(8px) rem(16px);
        border-bottom: rem(1px) solid #4a6282;
    }

    .settings-dropdown {
        margin: 0 rem(16px);
        padding: 0;
    }
}

.hasDifference {
    background-color: #283547;
}
