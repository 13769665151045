@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@import '@assets/core.style';

.tiles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: transform 300ms linear, opacity 300ms linear,
        background-color 300ms linear, box-shadow 300ms linear;
    height: calc(100vh - #{rem(248px)});
    padding: rem(8px);
    border-radius: rem(8px);
    max-height: calc(100vh - #{rem(298px)});

    @include themes(
        (
            'dark': (
                'background-color': $dark-gray-5,
                'box-shadow': 0 rem(10px) rem(10px) rem(-5px)
                    rgba(0, 0, 0, 0.24),
            ),
            'light': (
                'background-color': #fff,
                'box-shadow': 0 rem(10px) rem(10px) rem(-5px)
                    rgba(176, 195, 208, 0.24),
            ),
        )
    );

    &__inner {
        position: relative;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        height: calc(100vh - rem(264px));
        opacity: 1;
        transform-origin: 50% 0%;
        align-content: flex-start;
    }

    &--zoomed-1 {
        transform: scale(1) translate(0, 0) translateZ(0) rotateX(0deg);
        z-index: 3;
    }

    &--zoomed-2 {
        transform: scale(0.98) translate(0, rem(24px)) translateZ(0)
            rotateX(0deg);
        z-index: 2;
        @include themes(
            (
                'dark': (
                    'background-color': $dark-gray-4,
                ),
                'light': (
                    'background-color': #fff,
                ),
            )
        );
    }

    &--zoomed-3 {
        transform: scale(0.96) translate(0, rem(48px)) translateZ(0)
            rotateX(0deg);
        z-index: 1;

        @include themes(
            (
                'dark': (
                    'background-color': $dark-gray-3,
                ),
                'light': (
                    'background-color': #fff,
                ),
            )
        );
    }

    &--zoomed-out {
        transform: scale(1) translate(0, 0) translateZ(rem(500px))
            rotateX(30deg);
        pointer-events: none;
        opacity: 0;
        z-index: 4;
    }
}

.tile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(25% - rem(16px));
    height: calc(33% - rem(16px));
    margin: rem(8px);
    border-radius: rem(4px);
    padding: rem(16px);
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    cursor: pointer;
    transition: background-color 300ms, color 300ms ease;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    font-size: 10%;

    $root: &;

    @include themes(
        (
            'dark': (
                'background-color': $dark-gray-2,
                'color': $body-text,
            ),
            'light': (
                'background-color': $light-gray-10,
                'color': $light-gray-90,
            ),
        )
    );

    &__fill {
        position: absolute;
        width: 50rem;
        height: 50rem;
        margin-top: -25rem;
        margin-left: -25rem;
        transform: scale(0);
        //opacity: 0;
        transition: transform 300ms linear, opacity 600ms linear;
        border-radius: 50%;
        z-index: 1;

        @include themes(
            (
                'dark': (
                    'background-color': $dark-green-1,
                ),
                'light': (
                    'background-color': $dark-green-4,
                ),
            )
        );
    }

    &__text {
        position: relative;
        z-index: 2;
        font-size: rem(24px);
        line-height: rem(32px);
        font-weight: 600;
    }

    &:hover {
        #{$root}__fill {
            //opacity: 1;
            transform: scale(1);
        }
    }
}
