@import '@styles';

.AdminTable {
    $root: &;
    $radius: rem(8px);
    $column-size: (
        'small': 70px,
        'regular': 95px,
        'medium': 150px,
        'large': 225px,
        'large-extra': 300px,
        'fluid': 100%,
    );

    position: relative;
    box-shadow: 0 rem(4px) rem(20px) rgba(0, 0, 0, 0.1);
    border-radius: rem(8px);

    @include themes(
        (
            'dark': (
                'background-color': $dark-gray-2,
            ),
            'light': (
                'background-color': $light-gray-10,
            ),
        )
    );

    &--no-animation {
        #{$root}__col {
            transition: none;
        }
    }

    &--fluid {
        $header: rem(50px);

        height: 100%;

        #{$root}__header {
            height: $header;
        }
        #{$root}__body {
            height: calc(100% - #{$header});
        }
    }

    &__header {
        border-radius: $radius $radius 0 0;

        @include themes(
            (
                'dark': (
                    'background-color': #283547,
                ),
                'light': (
                    'background-color': $light-gray-20,
                ),
            )
        );
    }

    &__header-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: rem(4px);
        top: 50%;
        transform: translateY(-50%);

        svg {
            path,
            rect {
                @include themes(
                    (
                        'dark': (
                            fill: #fff,
                        ),
                        'light': (
                            fill: $dark-gray-0,
                        ),
                    )
                );
            }
        }
    }

    &__header-label {
        position: relative;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        white-space: nowrap;
        padding-left: rem(20px);
        transform: translateX(rem(-20px));
        transition: transform 250ms ease-out;
    }

    &__header-dropdown {
        $width: calc(100% + rem(60px));

        position: relative;
        left: rem(-30px);
        top: 0;
        z-index: 1;
        max-width: $width;
        min-width: $width;
        height: 100%;
        display: flex;
        align-items: center;
        line-height: 1.2;

        .Select {
            &__input {
                &:after {
                    top: 50%;
                    margin-top: rem(-12px);
                    right: rem(12px);
                }
            }
        }
    }

    &__header-placeholder {
        padding-left: rem(24px);
    }

    &__counter {
        width: rem(70px);
        position: absolute;
        right: 0;
    }

    &__row {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        transition: background-color 250ms ease;

        &--hoverable {
            &:hover {
                #{$root}__col {
                    cursor: pointer;

                    @include themes(
                        (
                            'dark': (
                                'background': darken(#2e3e56, 5%),
                            ),
                            'light': (
                                'background': darken(#fff, 5%),
                            ),
                        )
                    );
                }
            }
        }

        &--header {
            // pointer-events: none;
            user-select: none;

            #{$root}__col {
                border-bottom: none;

                &:first-child {
                    border-top-left-radius: $radius;
                }

                &:last-child {
                    border-top-right-radius: $radius;
                }
            }
        }

        &.is-selected {
            #{$root}__col {
                @include themes(
                    (
                        'dark': (
                            'background': #2e3e56,
                        ),
                        'light': (
                            'background': darken(#fff, 10%),
                        ),
                    )
                );
            }
        }

        &--expanded {
            background: #2e3e56;

            #{$root}__col {
                cursor: pointer;
            }
        }

        &--sub-expanded {
            background: darken(#2e3e56, 5%);
        }

        &:last-child {
            #{$root}__col {
                border-bottom-color: transparent;
            }
        }

        &.is-dragging {
            background: darken(#2e3e56, 10%);
            font-family: 'Nunito', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: rem(16px);
            line-height: rem(24px);
            letter-spacing: 0.012em;
            box-shadow: inset 0 0 0 rem(1px) #34455c;
        }

        &:hover,
        &.is-dragging {
            #{$root}__draggable {
                transform: translateX(0);
                opacity: 1;
            }
        }

        .has-dragging-row & {
            #{$root}__draggable {
                transform: translateX(-100%);
                opacity: 0;
            }
        }
    }

    &__col {
        $col: &;

        flex: 0 1 auto;
        font-size: rem(14px);
        line-height: rem(16px);
        background-color: transparent;
        padding: 0 rem(30px);
        position: relative;
        cursor: default;

        &--break-word {
            word-break: break-word;
        }

        @for $i from 2 to 10 {
            $width: rem($i * map-get($column-size, 'regular'));
            &--col#{$i} {
                max-width: $width;
                min-width: $width;
                flex-shrink: 0;
            }
        }

        &#{$col}--empty {
            width: 0;
            min-width: 0;
            padding: 0;
        }

        &--justify {
            justify-content: space-around;
        }

        @each $name, $size in $column-size {
            &--#{$name} {
                @if ($name != 'fluid') {
                    flex-shrink: 0;
                    width: rem($size);
                    max-width: rem($size);
                } @else {
                    width: $size;
                    min-width: rem(100px);
                }

                @if ($name == 'small' or $name == 'regular') {
                    min-width: rem($size);
                }
            }
        }

        &--max-half {
            max-width: 50%;
        }

        &--no-p {
            padding: 0;
        }

        &--no-pr {
            padding-right: 0;
        }

        &--no-pl {
            padding-left: 0;
        }

        &--center {
            justify-content: center;
        }
    }

    &__label {
        width: rem(20px);
        height: rem(20px);
        min-width: rem(20px);
        min-height: rem(20px);
        border-radius: 50%;
        margin-right: rem(8px);

        &--No {
            @include themes(
                (
                    'dark': (
                        'background-color': #393939,
                    ),
                    'light': (
                        'background-color': #e8e8e8,
                    ),
                )
            );
        }

        &--Yes {
            background-color: #25b7a5;
        }
    }

    &__truncated {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        white-space: nowrap;
    }

    &__btns {
        justify-content: space-around;
        opacity: 0;
        width: 100%;
        pointer-events: none;
        transition: opacity 300ms;
    }

    &__draggable {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        height: 100%;
        width: rem(25px);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 250ms linear, opacity 250ms linear;

        path {
            @include themes(
                (
                    'dark': (
                        stroke: #fff,
                    ),
                    'light': (
                        stroke: $dark-gray-0,
                    ),
                )
            );
        }
    }

    &__btn {
        cursor: pointer;
        margin: 0 rem(10px);
        display: flex;
        align-items: center;

        path {
            @include themes(
                (
                    'light': (
                        stroke: $dark-gray-0,
                    ),
                )
            );
        }

        &:hover {
            transform: scale(1.2);
            pointer-events: auto;

            path {
                stroke: #fff;
            }
        }

        &[disabled] {
            opacity: 0.25;
            cursor: default;

            &:hover {
                transform: scale(1);
            }
        }

        &.is-danger {
            &:hover {
                path {
                    stroke: $dark-red-1;
                }
            }
        }
    }

    &__body {
        $height: 59vh;

        height: $height;
        overflow: auto;

        &--is-fluid {
            max-height: $height;
            height: auto;
        }

        &--small {
            height: $height * 0.5;
        }

        & > .ScrollbarsCustom {
            .ScrollbarsCustom-Wrapper {
                width: 100%;
            }
        }
    }

    &__toggle {
        text-decoration: underline;
        user-select: none;
        cursor: pointer;
        white-space: nowrap;

        @include themes(
            (
                'dark': (
                    'color': $dark-blue-1,
                ),
                'light': (
                    'color': $dark-gray-0,
                ),
            )
        );

        &:hover,
        &.is-open,
        &.is-filled {
            @include themes(
                (
                    'dark': (
                        'color': $body-text,
                    ),
                    'light': (
                        'color': $dark-gray-0,
                    ),
                )
            );
        }

        &.is-filled {
            &:hover {
                @include themes(
                    (
                        'dark': (
                            'color': $dark-blue-1,
                        ),
                        'light': (
                            'color': $dark-gray-0,
                        ),
                    )
                );
            }
        }
    }

    &__require-symbol {
        color: $dark-red-1;
    }

    &__cell-input {
        $border-width: rem(1px);

        @include truncate(100%);

        height: 100%;
        width: 100%;
        padding: 0;
        border-bottom: $border-width solid transparent;
        transform: translateY($border-width);
        transition: border-color 300ms linear;
        cursor: pointer;
        font-family: inherit;
        font-size: inherit;
        color: inherit;
        font-weight: inherit;

        @include themes(
            (
                'dark': (
                    color: $body-text,
                ),
                'light': (
                    color: $dark-gray-0,
                ),
            )
        );

        &:focus {
            cursor: text;
        }

        &:focus,
        &:hover {
            @include themes(
                (
                    'dark': (
                        'border-color': $body-text,
                    ),
                    'light': (
                        'border-color': $dark-gray-0,
                    ),
                )
            );
        }

        &::placeholder {
            @include themes(
                (
                    'dark': (
                        color: $dark-gray-9,
                    ),
                    'light': (
                        color: $dark-gray-9,
                    ),
                )
            );
        }
    }

    .SpaceSpinner {
        &__overlay {
            border-radius: $radius;
        }
    }

    .Cell {
        &:has(#{$root}__label--No) {
            @include themes(
                (
                    'dark': (
                        'color': rgba(#fff, 0.2),
                    ),
                    'light': (
                        'color': rgba($light-gray-70, 0.2),
                    ),
                )
            );
        }
    }
}
