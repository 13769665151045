@use '@datassential/platform-ui-lib/lib/style/constants.scss' as *;
@import '@assets/core.style';

.toggle-input {
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    transition: opacity 300ms;

    $root: &;

    &--checked {
        #{$root}__btn {
            transition: $base-background-transition;

            @include themes(
                (
                    'dark': (
                        'background-color': $platform-color-main,
                    ),
                    'light': (
                        'background-color': $platform-color-main,
                    ),
                )
            );

            &:hover {
                background-color: rgba($platform-color-main, 0.8);
            }
        }
        #{$root}__btn-inner {
            transform: translateX(rem(16px));
        }
    }

    &--disabled {
        pointer-events: none;
        opacity: 0.2;

        #{$root}__btn {
            background-color: $dark-gray-3;
        }

        #{$root}__btn-inner {
            background-color: $dark-gray-9;
        }
    }

    &__label {
        width: calc(100% - rem(35px));
        padding-right: rem(10px);
        font-size: rem(14px);
        transition: $base-background-transition;
        opacity: 0.4;

        #{$root}--checked & {
            opacity: 1;
        }

        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': $light-gray-70,
                ),
            )
        );
    }

    &__btn {
        width: rem(35px);
        height: rem(19px);
        border-radius: rem(15px);
        box-shadow: inset 0 0 rem(3px) rgba(0, 0, 0, 0.06);
        cursor: pointer;
        transition: background-color 300ms ease;
        padding: rem(3px);

        @include themes(
            (
                'dark': (
                    'background-color': rgba(#636363, 0.3),
                ),
                'light': (
                    'background': $light-gray-40,
                ),
            )
        );

        &:hover {
            background-color: rgba(#636363, 0.7);
        }
    }

    &__btn-inner {
        border-radius: 50%;
        width: rem(13px);
        height: rem(13px);
        background-color: #fff;
        pointer-events: none;
        transition: transform 300ms ease;
    }
}
