@use '@datassential/platform-ui-lib/lib/style/constants' as *;
@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@import '@assets/core.style';

.Home {
    height: calc(
        100vh - #{$platform-navigation-height} - #{$platform-header-height}
    );
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Nunito', sans-serif;

    $root: &;

    &__widget {
        width: 100%;

        &.is-chart {
            flex: 0 1 100%;
        }

        &.is-table {
            flex: 0 0 rem(490px);
        }
    }

    &__slider-title {
        font-size: rem(24px);
        line-height: rem(30px);
        font-family: 'Nunito', sans-serif;
        font-weight: 700;
        padding: 0 rem(16px);
    }

    &__table-body {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__table-row {
        display: flex;
        width: 100%;
    }

    &__table-cell {
        font-size: rem(13px);

        &.is-accent {
            @include themes(
                (
                    'dark': (
                        'background': $dark-gray-4,
                    ),
                    'light': (
                        'background-color': white,
                    ),
                )
            );
        }

        &.CellNew--bg-head {
            font-weight: 700;
        }

        &:first-child {
            flex: 0 1 100%;
            padding-left: rem(15px);
            padding-right: rem(10px);
        }

        &:nth-child(n + 2) {
            flex: 0 0 rem(90px);
        }

        &.bdrs-1 {
            border-top-left-radius: rem(8px);
        }

        &.bdrs-2 {
            border-top-right-radius: rem(8px);
        }

        &.bdrs-3 {
            #{$root}__table-row:last-child & {
                border-bottom-left-radius: rem(8px);
            }
        }

        &.bdrs-4 {
            #{$root}__table-row:last-child & {
                border-bottom-right-radius: rem(8px);
            }
        }
    }

    &__table-chart {
        height: rem(40px);
        width: rem(40px);
        flex: 0 0 rem(40px);
        margin-right: rem(10px);
        position: relative;

        canvas {
            position: absolute;
            top: 10%;
            left: 0;
            max-width: 100%;
            max-height: 80%;
        }
    }
}

.HomeWidget {
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        flex: 0 0 rem(50px);
        align-items: center;
        border-radius: rem(8px) rem(8px) 0 0;
        @include themes(
            (
                'dark': (
                    'background-color': $dark-gray-3,
                    'color': #fff,
                ),
                'light': (
                    'background-color': $light-gray-0,
                    'color': $light-gray-90,
                ),
            )
        );
    }

    &__content {
        position: relative;
        display: flex;
        flex: 0 1 100%;
        border-radius: 0 0 rem(8px) rem(8px);
        @include themes(
            (
                'dark': (
                    'background-color': $dark-gray-3,
                    'color': #fff,
                ),
                'light': (
                    'background-color': $light-gray-0,
                    'color': #3a414c,
                ),
            )
        );
    }
}

.HomeSlider {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    &__slide {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0 rem(70px);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__slide-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
    }

    &__slide-content {
        position: relative;
        max-width: rem(1200px);
        width: 100%;
        z-index: 2;
    }

    &__slide-content-inner {
        display: flex;
        gap: rem(10px);
        width: 100%;
    }

    &__nav {
        width: rem(50px);
        height: rem(50px);
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-shadow: 0 0 rem(10px) 0 #000;

        @include themes(
            (
                'dark': (
                    'background-color': $dark-gray-3,
                    'color': #fff,
                ),
                'light': (
                    'background-color': $light-gray-40,
                    'color': #3a414c,
                ),
            )
        );

        &.is-left {
            right: calc(100% + #{rem(10px)});
        }

        &.is-right {
            left: calc(100% + #{rem(10px)});
        }
    }

    &__nav-icon {
        height: rem(24px);
        width: rem(24px);
    }

    &__nav-progress {
        --size: 58px;
        --half-size: calc(var(--size) / 2);
        --stroke-width: 4px;
        --radius: calc((var(--size) - var(--stroke-width)) / 2);
        --circumference: calc(var(--radius) * pi * 2);
        --dash: calc((var(--progress) * var(--circumference)) / 100);

        animation: progress-animation 10s linear 0s 1 forwards;
        z-index: 3;
        position: absolute;
        top: rem(-4px);
        left: rem(-4px);
        width: rem(58px);
        height: rem(58px);
        pointer-events: none;

        circle {
            cx: var(--half-size);
            cy: var(--half-size);
            r: var(--radius);
            stroke-width: var(--stroke-width);
            fill: none;
            stroke-linecap: round;
            transform: rotate(-90deg);
            transform-origin: var(--half-size) var(--half-size);
            stroke-dasharray: var(--dash)
                calc(var(--circumference) - var(--dash));
            transition: stroke-dasharray 0.1s linear 0s;
            stroke: #fff;
        }
    }
}

.HomeSlider-enter {
    opacity: 0;
    z-index: 10;
}

.HomeSlider-enter-active {
    opacity: 1;
    transition: opacity 500ms 300ms;
}

.HomeSlider-enter-done {
    opacity: 1;
}

.HomeSlider-exit {
    opacity: 0;
    transition: opacity 500ms;
}

@property --progress {
    syntax: '<number>';
    inherits: false;
    initial-value: 0;
}

@keyframes progress-animation {
    from {
        --progress: 0;
    }

    to {
        --progress: 100;
    }
}
