@import '@styles';

.AdminFilters {
    position: relative;

    &__title {
        padding-bottom: rem(20px);
    }

    &__section {
        position: relative;
        display: flex;
        padding-bottom: rem(40px);
    }

    &__column {
        display: flex;
        flex-direction: column;
        flex-basis: 20%;

        &.p20 {
            padding-right: rem(20px);
        }

        &.size-l {
            flex-basis: 25%;
        }

        &.size-xl {
            flex-basis: 30%;
        }
    }

    &__dropdown {
        &-enter,
        &-exit-done {
            opacity: 0;
            visibility: hidden;
        }

        &-enter-active {
            opacity: 1;
            visibility: visible;
            transition: opacity 300ms 300ms ease;
        }

        &-enter-done,
        &-exit {
            opacity: 1;
            visibility: visible;
        }

        &-exit-active {
            opacity: 0;
            transition: opacity 300ms ease;
        }
    }

    .dropdown {
        width: 100%;
    }

    .checkbox-input__arrow {
        margin-left: rem(16px);
    }
}
