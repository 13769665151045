@import '@styles';

.MultiSelectButtons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: rem(8px) 0;

    $root: &;

    .checkbox-input {
        display: inline-flex;
        padding: 0;

        &__label {
            line-height: rem(16px);
            min-height: rem(16px);
        }
    }

    &__header {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-bottom: rem(16px);
        position: relative;
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: rem(16px);
    }

    &__counter {
        color: #4794ff;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        font-size: rem(12px);
        line-height: rem(12px);
        position: absolute;
        top: 100%;
        transform: translateY(-120%);
        left: 0;
    }

    &__dropdown-icon {
        width: rem(20px);
        height: rem(20px);
        flex: 0 0 rem(20px);
        margin-left: rem(8px);
        transition: transform 300ms;

        #{$root}.is-expanded & {
            transform: rotate(90deg);
        }

        path {
            stroke: currentColor;
        }
    }
}
