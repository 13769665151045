@import '@assets/core.style';

.Accordion {
    border-radius: rem(2px);
    max-height: rem(32px);
    flex-grow: 1;
    transition: max-height 300ms ease;

    $root: &;

    &--opened {
        max-height: 100%;

        #{$root}__scrollbar-wrap {
            max-height: calc(100% - rem(32px));
        }
    }

    & > .checkbox-input {
        padding: rem(8px) 0 rem(8px) rem(15px);
        height: rem(32px);

        @media all and (max-width: 1440px) {
            padding: 0 0 0 rem(15px);
        }
    }

    &__scrollbar-wrap {
        transform: translateY(#{rem(-2px)});
        height: 100%;
        width: 100%;
        max-height: 0;
        flex-grow: 1;
        transition: max-height 300ms ease;
    }

    &__item {
        padding: 0 rem(10px) rem(16px) rem(36px);

        &:first-child {
            padding-top: rem(8px);
        }

        &:last-child {
            padding-bottom: rem(8px);
        }
    }
}
