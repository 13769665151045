@import '@assets/core.style';

.NoMatches {
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(60px);
    padding: 0 rem(24px);
    font-weight: 800;
    font-size: rem(32px);
    line-height: rem(40px);
    text-align: center;
    min-height: 100%;

    @include themes(
        (
            'dark': (
                'color': #fff,
            ),
            'light': (
                'color': $light-gray-40,
            ),
        )
    );
}
