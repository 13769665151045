@import '@styles';

.TextField {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;

    &__input {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 0 rem(8px);
        font-size: rem(16px);
        line-height: 1.25;
        border-bottom: rem(1px) solid;
        text-transform: uppercase;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        white-space: nowrap;
        transition: border-color 300ms linear;

        @include themes(
            (
                'dark': (
                    'color': #fff,
                    'border-color': #4a6282,
                ),
                'light': (
                    'color': $dark-gray-0,
                    'border-color': $light-gray-20,
                ),
            )
        );

        &:-internal-autofill-selected {
            box-shadow: inset 0 0 0 rem(200px) #fff;
        }

        &::placeholder {
            @include themes(
                (
                    'dark': (
                        'color': $dark-gray-5,
                    ),
                    'light': (
                        'color': $light-gray-40,
                    ),
                )
            );
        }
    }

    &__input-label {
        display: block;
        font-size: rem(16px);
        line-height: rem(16px);
        pointer-events: none;
        position: absolute;
        left: 0;
        top: 0;
        transition: all 300ms ease;
        font-family: 'Nunito', sans-serif;
        font-weight: 600;
        letter-spacing: 0.012em;

        @include themes(
            (
                'dark': (
                    'color': $dark-gray-5,
                ),
                'light': (
                    'color': $light-gray-40,
                ),
            )
        );

        &.is-upper {
            transform: translateX(-10%) translateY(-120%) scale(0.8);
        }

        &.is-required {
            &:after {
                content: '*';
                color: $dark-red-1;
            }
        }
    }
}
