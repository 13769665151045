@import '@assets/core.style';

.tooltip {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    box-shadow: 0 rem(2px) rem(4px) rgba(0, 0, 0, 0.08);
    backface-visibility: hidden;
    pointer-events: none;
    transition: transform 50ms ease;

    @include themes(
        (
            'light': (
                'background': #fff,
            ),
        )
    );

    &__currency {
        padding-right: rem(3px);

        svg {
            height: rem(10px);
        }
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        border-radius: rem(3px);
        width: rem(12px);
        height: rem(12px);
        transition: transform 150ms ease;

        @include themes(
            (
                'dark': (
                    'background-color': $dark-gray-3,
                ),
                'light': (
                    'background': #fff,
                ),
            )
        );
    }

    &--left {
        &:before {
            transform: translate(50%, -50%) rotate(-45deg);
            right: 0;
        }
    }

    &--right {
        &:before {
            left: 0;
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }

    &__header {
        position: relative;
        border-radius: rem(3px) rem(3px) 0 0;
        height: rem(20px);
        display: flex;
        align-items: center;

        @include themes(
            (
                'dark': (
                    'background-color': $dark-gray-4,
                ),
                'light': (
                    'background': #fff,
                ),
            )
        );
    }

    &__data {
        border-radius: 0 0 rem(3px) rem(3px);

        @include themes(
            (
                'light': (
                    'background': #fff,
                ),
            )
        );
    }

    &__header-text {
        font-family: 'Poppins', sans-serif;
        font-size: rem(12px);
        opacity: 0.5;
        padding-left: rem(10px);
        display: flex;
        align-items: center;

        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'background': #fff,
                    'color': #3a414c,
                ),
            )
        );
    }

    &__list-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: rem(25px);
        padding: rem(8px) rem(8px) rem(8px) rem(24px);
        font-family: 'Nunito', sans-serif;
        font-size: rem(12px);
        line-height: rem(18px);
        text-transform: lowercase;
        position: relative;

        @include themes(
            (
                'dark': (
                    'background-color': $dark-gray-3,
                    'color': #fff,
                ),
                'light': (
                    'background': #fff,
                    'color': #3a414c,
                ),
            )
        );

        &:last-child {
            border-radius: 0 0 rem(3px) rem(3px);
        }
    }

    &__list-item-value {
        //width: rem(60px);
        padding-left: rem(10px);
        text-align: right;
        text-transform: none;
    }

    &__circle {
        background-color: currentColor;
        //box-shadow: 0 0 0 rem(3px) rgba(currentColor, .2);
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 0;
        width: rem(6px);
        height: rem(6px);
        transform: translate(rem(10px), -50%);

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: rem(-3px);
            left: rem(-3px);
            width: rem(12px);
            height: rem(12px);
            border-radius: 50%;
            background-color: currentColor;
            opacity: 0.2;
        }
    }
}
