@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@import '@assets/core.style';

.foodProfileList {
    font-family: 'Work Sans', sans-serif;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-left: rem(90px);
    padding-right: rem(90px);
    padding-top: 30px;


    @include media-max(1140px) {
        padding: rem(30px) rem(20px) rem(40px);
    }

    @include media-max(1024px) {
        justify-content: space-between;
    }

    &__filter-container {
        width: rem(400px);
    }

    &__search_box {
        position: relative;
        width: 100%;
        border-bottom: rem(1px) solid #4a6282;
        height: rem(36px);
        margin-top: rem(10px);
    }

    &__searc-icon {
        position: absolute;
        right: rem(3px);
        top: 50%;
        transform: translateY(-50%);
        width: rem(17px);
        height: rem(17px);

        svg {
            path {
                @include themes(
                    (
                        'dark': (
                            'stroke': #3a4b61,
                        ),
                        'light': (
                            'stroke': $light-gray-40,
                        ),
                    )
                );
            }
        }
    }

    &__search {
        font-family: 'Work Sans', sans-serif;
        border: none;
        width: 95%;
        height: 100%;
        background: transparent;
        transition: border-color 300ms linear;
        cursor: pointer;
        font-size: rem(20px);

        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': #3a414c,
                ),
            )
        );

        &::placeholder {
            color: #3a4b61;
            font-weight: 400;
            font-size: rem(20px);
            line-height: rem(24px);
        }

        &:focus {
            outline: none;

            @include themes(
                (
                    'light': (
                        'border-color': #3a414c,
                    ),
                )
            );
        }
    }

    &__search-panel {
        width: 100%;
        margin-top: rem(20px);
    }
}
