@import '@styles';

.Popup {
    position: absolute;
    top: 50%;
    left: 50%;
    width: rem(608px);
    padding: rem(24px);
    box-shadow: 0 rem(4px) rem(20px) rgba(0, 0, 0, 0.1);
    border-radius: rem(8px);
    transform: translate(-50%, -50%);

    $root: &;

    @include themes(
        (
            'dark': (
                'background': #2E3C4F,
            ),
            'light': (
                'background': #fff,
                'color': $dark-gray-0,
            ),
        )
    );

    &__wrapper {
        position: relative;
        height: 100vh;
        z-index: 103;
        transition: transform 250ms ease, opacity 250ms ease,
            visibility 250ms ease;

        &.is-hidden {
            opacity: 0;
            visibility: hidden;
            transform: translateY(rem(10px));
        }
    }

    &--large {
        width: rem(1300px);
        max-width: 95vw;
    }

    &--manage {
        width: rem(1081px);
    }

    &--countries {
        width: rem(760px);
    }

    &--confirm {
        width: rem(608px);
    }

    &--reports {
        width: rem(1376px);
        max-width: 90%;
    }

    &__title {
        padding-bottom: rem(24px);
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: rem(24px);
        line-height: rem(32px);

        @include themes(
            (
                'dark': (
                    color: #fff,
                ),
                'light': (
                    color: $dark-gray-0,
                ),
            )
        );

        &--small {
            font-size: rem(18px);
        }
    }

    &__content {
        padding-bottom: rem(24px);
    }

    &__btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-top: rem(24px);
    }

    &__btn {
        display: flex;
        margin-left: rem(8px);
    }

    &__text {
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: rem(16px);
        line-height: rem(24px);
        letter-spacing: 0.012em;
        color: $dark-gray-9;

        &.has-gap {
            margin-top: rem(-15px);
            margin-bottom: rem(15px);
        }

        span {
            color: #fff;

            @include themes(
                (
                    'dark': (
                        color: #fff,
                    ),
                    'light': (
                        color: $dark-gray-0,
                    ),
                )
            );
        }
    }

    &__dropdown {
        padding: rem(24px) 0 rem(48px);
    }
}
