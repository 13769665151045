@import '@styles';

.Tags {
    animation: appear 300ms ease-in;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100%;

    &__header {
        position: relative;
        display: flex;
        align-items: center;
        padding: rem(16px) 0;
        z-index: 3;
    }

    &__selected-items {
        display: flex;
        min-height: rem(65px);
        flex-direction: column;
        animation: appear 300ms ease-in;
    }

    &__selected-items-content {
        display: flex;
        flex-wrap: wrap;
    }

    &__search {
        width: rem(300px);
        flex-shrink: 0;
    }

    &__search-item {
        cursor: pointer;
    }

    &__search-dropdown {
        max-height: rem(300px);
        overflow-x: hidden;
    }

    .FiltersContainer__sub-header {
        margin-bottom: 0;
        border: 0;
        flex-grow: 0;
    }

    &__content {
        flex-grow: 1;
        padding: rem(16px) rem(32px);
    }

    &__columns {
        display: flex;
        gap: 3.5%;
    }

    &__column {
        display: flex;
        flex-direction: column;
        flex: 0 1 31%;
    }

    .MultiSelectButtons__header {
        position: sticky;
        top: 0;
        z-index: 2;

        @include themes(
            (
                'dark': (
                    'background': #171e28,
                ),
                'light': (
                    'background': $light-gray-0,
                ),
            )
        );

        &:before {
            content: '';
            display: block;
            position: absolute;
            bottom: 100%;
            left: 0;
            right: 0;
            height: rem(20px);

            @include themes(
                (
                    'dark': (
                        'background': #171e28,
                    ),
                    'light': (
                        'background': $light-gray-0,
                    ),
                )
            );
        }
    }
}
