@import '@styles';

.SortableTable {
    $root: &;

    &__list {
        min-height: 100%;
    }

    &__item {
        position: relative;

        & + & {
            box-shadow: inset 0 rem(1px) 0 #34455c;
        }

        &:hover {
            #{$root}__draggable {
                transform: translateX(0);
                opacity: 1;
            }
        }
    }

    .has-dragging-row & {
        #{$root}__draggable {
            &:not(&.is-dragging) {
                transform: translateX(-100%);
                opacity: 0;
            }
        }
    }

    &__draggable {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        height: 100%;
        width: rem(30px);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 250ms linear, opacity 250ms linear;

        &:not(.is-dragging) {
            transform: translateX(-100%);
            opacity: 0;
        }

        svg {
            path {
                @include themes(
                    (
                        'dark': (
                            stroke: #fff,
                        ),
                        'light': (
                            stroke: $dark-gray-0,
                        ),
                    )
                );
            }
        }
    }
}
