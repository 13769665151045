@use '@datassential/platform-ui-lib/lib/style/constants' as *;
@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@import '@assets/core.style';

.FoodProfileItemList {
    width: 100%;
    margin-left: rem(30px);
    transition: background-color 200ms linear;
    border-radius: rem(2px);

    $root: &;

    @include media-max(1280px) {
        margin-left: rem(20px);
    }

    &__header {
        height: rem(45px);
        display: flex;
        align-items: center;
        padding-left: rem(10px);
        justify-content: space-between;
        transition: background-color 200ms linear, color 200ms linear;
        border-top-left-radius: rem(2px);
        border-top-right-radius: rem(2px);

        @include themes(
            (
                'light': (
                    'background-color': transparent,
                ),
            )
        );
    }

    &__wrap {
        width: 100%;
        padding-top: rem(8px);
        height: calc(
            100vh - #{$platform-header-height} - #{$platform-navigation-height} -
                #{rem(100px)}
        );

        @include themes(
            (
                'dark': (
                    'background': #232d3b,
                ),
                'light': (
                    'background-color': #fff,
                    'box-shadow': 0 rem(8px) rem(20px) rgba(176, 195, 208, 0.24),
                    'border-radius': rem(8px),
                ),
            )
        );
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
    }

    &__link {
        text-decoration: none;
        border: rem(2px) solid transparent;
        width: rem(240px);
        //flex: 1 0 rem(240px);
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        padding-right: rem(10px);
        padding-left: rem(5px);
    }

    &__title {
        font-weight: 600;
        font-size: rem(16px);
        line-height: rem(18px);
        transition: color 200ms linear;

        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': $light-gray-100,
                ),
            )
        );
    }

    &__subtitle {
        font-weight: 400;
        font-size: rem(12px);
        line-height: rem(14px);
        transition: color 200ms linear;

        @include themes(
            (
                'dark': (
                    'color': rgba(#fff, 0.4),
                ),
                'light': (
                    'color': $light-gray-70,
                ),
            )
        );
    }

    &__tag-label {
        font-size: rem(14px);
    }

    &__search-icon {
        margin-right: rem(10px);
        cursor: pointer;
        transition: fill 200ms linear;

        @include themes(
            (
                'dark': (
                    'fill': #fff,
                ),
                'light': (
                    'fill': #3a414c,
                ),
            )
        );

        &:hover {
            .FoodProfileItemList__search {
                outline: none;
                border-color: #fff;
                opacity: 1;
            }
        }
    }
}
