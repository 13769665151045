@import '@styles';

.Select {
    position: relative;
    padding: 0 rem(24px) rem(24px) 0;
    color: #fff;

    $root: &;
    $dropdown-index: 1;

    &:last-child {
        padding-right: 0;
    }

    &--disabled {
        opacity: 0.25;
        cursor: default;
    }

    &--wide {
        #{$root}__input {
            width: 100%;

            &--opened {
                border-color: #fff;
            }
        }
    }

    &--inherited {
        padding-bottom: 0;
        height: 100%;
        width: 100%;
        color: inherit;

        #{$root}__input {
            max-width: 100%;
            min-width: 100%;
            border-bottom: none;
            height: 100%;
            display: flex;
            align-items: center;
            background: inherit;
            padding-left: rem(24px);
            padding-right: rem(24px);

            &--opened {
                @include themes(
                    (
                        'dark': (
                            'background-color': $dark-gray-2,
                        ),
                        'light': (
                            'background-color': $light-gray-10,
                        ),
                    )
                );
            }
        }

        #{$root}__input-dropdown {
            max-width: 100%;
            min-width: 100%;
        }

        #{$root}__row {
            padding-top: 0;
            padding-bottom: 0;
            min-height: rem(40px);
        }
    }

    &__title {
        padding-bottom: rem(8px);
        color: #b8c6d8;
    }

    &__top {
        display: flex;
        align-items: center;
        padding: rem(8px) rem(16px);
        border-bottom: rem(1px) solid #4a6282;
        background-color: #1c2531;

        @include themes(
            (
                'dark': (
                    'background-color': #1f2835,
                    'color': #788ba3,
                ),
                'light': (
                    'background-color': #fff,
                    'color': $dark-gray-0,
                ),
            )
        );

        input {
            //margin-left: rem(12px);
            font-size: rem(16px);
            line-height: rem(24px);
            color: #fff;

            &::placeholder {
                color: #788ba3;
            }
        }

        path {
            //stroke: #788BA3;
            stroke: #fff;
        }
    }

    &__input {
        position: relative;
        width: rem(224px);
        padding: rem(8px) 0;
        border-bottom: rem(1px) solid #4a6282;
        transition: border-color 300ms;

        .checkbox-input__label-cut {
            width: rem(177px);
        }

        &:after {
            content: '';
            position: absolute;
            right: rem(8px);
            display: inline-block;
            border: solid;
            border-width: 0 rem(2px) rem(2px) 0;
            padding: rem(4px);
            transform: translate(0, 50%) rotate(45deg);
            top: 20%;
            transition: transform 300ms;

            @include themes(
                (
                    'dark': (
                        'border-color': #fff,
                    ),
                    'light': (
                        'border-color': $dark-gray-0,
                    ),
                )
            );
        }

        &--opened {
            border-color: #4794ff;

            &:after {
                transform: translate(0, 100%) rotate(-135deg);
            }
        }
    }

    &__input-value {
        position: relative;
        width: 100%;

        &.has-modifier {
            &:before {
                content: '';
                position: absolute;
                left: rem(-4px);
                top: 0;
                z-index: 1;
                width: rem(8px);
                height: rem(8px);
                background: $dark-orange-1;
                border-radius: 50%;
            }
        }
    }

    &__input-value-title {
        &--has-value {
            position: absolute;
            left: -1.25vw;
            top: rem(-40px);
            width: 100%;
        }
    }

    &__input-dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        //opacity: 0;
        min-width: rem(224px);
        max-width: rem(320px);
        max-height: rem(296px);
        box-shadow: 0 rem(8px) rem(20px) rgba(0, 0, 0, 0.4);
        border-radius: rem(8px);
        overflow: auto;
        transition: transform 500ms, opacity 300ms;
        z-index: $dropdown-index;

        @include themes(
            (
                'dark': (
                    'background-color': #1f2835,
                    'color': #788ba3,
                ),
                'light': (
                    'background-color': #fff,
                    'color': $dark-gray-0,
                ),
            )
        );

        &.has-search {
            top: calc(100% + rem(58px));
            border-top-left-radius: 0;
            border-top-right-radius: 0;

            .checkbox-input__label {
                margin-left: rem(12px);
            }
        }

        &--wide {
            top: rem(56px);
            width: 100%;
            max-width: 100%;
            padding: rem(8px) 0;
        }

        &--show-up {
            top: auto;
            bottom: 60%;

            &.has-search {
                top: auto;
                bottom: calc(100% + rem(58px));
                border-radius: rem(8px) rem(8px) 0 0;
            }
        }
    }

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: rem(8px) rem(16px);
        cursor: pointer;
        overflow: hidden;

        &:hover {
            @include themes(
                (
                    'dark': (
                        'background-color': #283547,
                    ),
                    'light': (
                        'background-color': $dark-blue-1,
                    ),
                )
            );

            #{$root}__btns {
                opacity: 1;
            }

            /* @TODO: remove !important from CheckboxInput */

            /* @TODO: set max inherited styles for modifier --inherited */
            .checkbox-input {
                .checkbox-input {
                    &__fake-check {
                        @include themes(
                            (
                                'light': (
                                    'border-color': #fff !important,
                                ),
                            )
                        );
                    }

                    &__fake-check-small {
                        &--radio {
                            @include themes(
                                (
                                    'light': (
                                        'background': #fff,
                                    ),
                                )
                            );
                        }
                    }

                    &__label {
                        @include themes(
                            (
                                'light': (
                                    'color': #fff,
                                ),
                            )
                        );
                    }
                }
            }
        }

        .radio-btn,
        .checkbox {
            width: 100%;
        }
    }

    &__btns {
        display: flex;
        align-items: center;
        opacity: 0;
        transition: opacity 300ms;
    }

    &__search {
        height: rem(48px);
        position: absolute;
        top: calc(100% + rem(10px));
        left: 0;
        width: 100%;
        z-index: $dropdown-index;

        &--show-up {
            top: auto;
            bottom: calc(100% + rem(10px));
        }

        &-label {
            position: relative;
            height: 100%;
            display: block;
        }

        &-icon {
            position: absolute;
            left: rem(14px);
            top: 0;
            height: 100%;
            width: rem(20px);
            display: flex;
            align-items: center;
            justify-content: center;

            path {
                stroke: #4a6282;
                stroke-width: 1px;
            }
        }

        &-input {
            font-size: rem(16px);
            font-family: 'Nunito', sans-serif;
            color: #788ba3;
            font-weight: 600;
            width: 100%;
            height: 100%;
            padding-left: rem(48px);
            padding-right: rem(15px);
            border-bottom: rem(1px) solid #4a6282;
            border-top-left-radius: rem(8px);
            border-top-right-radius: rem(8px);

            @include themes(
                (
                    'dark': (
                        'background': #1f2835,
                    ),
                    'light': (
                        'background': #fff,
                    ),
                )
            );

            &::placeholder {
                transition: $base-background-transition;

                @include themes(
                    (
                        'dark': (
                            'color': rgba(#b8c6d8, 0.5),
                        ),
                        'light': (
                            'color': $light-gray-40,
                        ),
                    )
                );
            }

            &--show-up {
                border-radius: 0 0 rem(8px) rem(8px);
                border-top: rem(1px) solid #4a6282;
                border-bottom: none;
            }
        }
    }

    &__btn {
        display: flex;
        margin-left: rem(8px);
        padding: rem(4px);
        cursor: pointer;

        path {
            transition: stroke 300ms;
        }

        &:hover {
            path {
                stroke: #4794ff;
            }
        }
    }

    &__button {
        &.naked {
            @include themes(
                (
                    'dark': (
                        'color': #fff,
                    ),
                    'light': (
                        'color': $light-gray-90,
                    ),
                )
            );
        }
    }
}
