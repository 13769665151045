@import '@assets/core.style';

.CircularProgressbarWidthContent {
    font-family: 'Work Sans', sans-serif;
    width: rem(304px);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: rem(8px);
    position: relative;
    min-height: 100%;

    $root: &;

    @include themes(
        (
            'light': (
                'color': rgba(#0d0f15, 0.7),
            ),
        )
    );

    &__wrap {
        width: rem(304px);
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        border-radius: rem(8px);
        height: 100%;

        @include themes(
            (
                'dark': (
                    'background': #232d3b,
                    'box-shadow': 0 rem(10px) rem(90px) rgba(0, 0, 0, 0.35),
                ),
                'light': (
                    'background': #ffffff,
                    'box-shadow': 0 rem(4px) rem(20px) rgba(0, 0, 0, 0.1),
                ),
            )
        );
    }

    &__circle {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 1 100%;
    }

    &__info-block {
        width: 100%;
        margin-top: auto;
    }

    &__title,
    &__info {
        height: rem(40px);
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }

    &__title {
        font-size: rem(24px);
        line-height: rem(18px);
        padding-left: rem(5px);

        @include themes(
            (
                'dark': (
                    'background': transparent,
                    'color': #fff,
                ),
                'light': (
                    'color': rgba(#3a414c, 0.7),
                    'background': #fff,
                ),
            )
        );
    }

    &__info {
        padding-left: rem(20px);
        padding-right: rem(20px);

        &-title,
        &-value {
            font-size: rem(16px);
            line-height: rem(22px);

            @include themes(
                (
                    'light': (
                        'color': $light-gray-90,
                    ),
                )
            );

            &:last-child {
                width: rem(60px);
                height: rem(25px);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: rem(4px);

                @include themes(
                    (
                        'dark': (
                            'background': #2c303e,
                        ),
                        'light': (
                            'background': $light-gray-10,
                        ),
                    )
                );
            }
        }

        &:last-child {
            color: #fff;

            @include themes(
                (
                    'dark': (
                        'background': #283547,
                    ),
                    'light': (
                        'background': $light-gray-10,
                    ),
                )
            );

            #{$root}__info-value {
                &:last-child {
                    background-color: $dark-blue-1;
                }

                @include themes(
                    (
                        'light': (
                            'color': #fff,
                        ),
                    )
                );
            }

            #{$root}__info-title {
                @include themes(
                    (
                        'light': (
                            'font-weight': 600,
                            'color': $light-gray-90,
                        ),
                    )
                );
            }
        }

        &:nth-last-child(n + 2) {
            @include themes(
                (
                    'dark': (
                        'border-top': rem(1px) solid rgba(#0d0f15, 0.5),
                    ),
                    'light': (
                        'border-top': rem(1px) solid $light-gray-10,
                    ),
                )
            );
        }
    }

    &__filter-description {
        width: 100%;
        font-size: rem(12px);
        padding-top: rem(8px);
        text-align: left;
    }
}
