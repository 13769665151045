@import '@styles';

.modal {
    width: 100vw;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    font-family: 'Work Sans', sans-serif;
    z-index: 99;

    &--over-footer {
        z-index: 101;

        & + & {
            z-index: 102;
        }
    }
}

.transition-enter,
.transition-overlayed-enter {
    opacity: 0;
    transform: translateY(rem(20px));
    z-index: 100;
}

.transition-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 300ms;
}

.transition-overlayed-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 300ms;
}

.transition-enter-done,
.transition-overlayed-enter-done {
    opacity: 1;
}

.transition-exit,
.transition-overlayed-exit {
    opacity: 0;
    transition: opacity 150ms;
}
