@import '@assets/core.style';

.TooltipTabs {
    position: absolute;
    top: rem(-10px);
    left: 0;
    width: auto;
    padding: rem(8px);
    box-shadow: 0 rem(4px) rem(30px) rgba(0, 0, 0, 0.16);
    border-radius: rem(4px);
    font-family: 'Nunito', sans-serif;
    font-size: rem(12px);
    line-height: rem(16px);
    transform: translate(0, -100%);
    z-index: 99;
    opacity: 0;
    pointer-events: none;
    transition: opacity 300ms;

    @include themes(
        (
            'dark': (
                'color': rgba(255, 255, 255, 0.7),
                'background-color': $dark-gray-3,
            ),
            'light': (
                'background-color': #fff,
                'color': $light-gray-70,
            ),
        )
    );

    &:after {
        content: '';
        display: block;
        width: rem(16px);
        height: rem(16px);
        position: absolute;
        background: $dark-gray-3;
        bottom: rem(-8px);
        left: rem(24px);
        z-index: 100;
        transform: translateX(-50%) rotate(45deg);
    }

    &:before,
    &:after {
        @include themes(
            (
                'light': (
                    'background-color': #fff,
                ),
            )
        );
    }

    &--shown {
        opacity: 1;
        pointer-events: auto;
    }

    &--small {
        left: 50%;
        font-size: rem(14px);
        transform: translate(-50%, -100%);

        &:after {
            width: rem(8px);
            height: rem(8px);
            bottom: rem(-4px);
            left: 50%;
        }
    }
}
