@import '@assets/core.style';

.country-menu {
    position: absolute;
    padding: rem(16px) rem(8px);
    border-radius: rem(8px);
    font-family: 'Poppins', sans-serif;
    z-index: 99;

    $root: &;

    @include themes(
        (
            'dark': (
                'background-color': rgba(52, 69, 92, 0.9),
            ),
            'light': (
                'background-color': rgba(255, 255, 255, 0.7),
                'box-shadow': 0 0 rem(20px) rgba(176, 195, 208, 0.45),
                'backdrop-filter': blur(rem(20px)),
            ),
        )
    );

    &__title {
        text-align: center;
        font-size: rem(24px);
        font-weight: 500;
        line-height: rem(32px);

        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': $light-gray-90,
                ),
            )
        );
    }

    &__grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: rem(8px);
    }

    &__country-wrapper {
        width: rem(130px);
        margin: 0 rem(16px);
    }

    &__country {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: rem(16px) rem(8px);
        cursor: pointer;

        &--disabled {
            pointer-events: none;

            #{$root}__country-name {
                @include themes(
                    (
                        'light': (
                            'color': $light-gray-40,
                        ),
                    )
                );
            }
        }

        &:hover {
            #{$root}__country-icon {
                transform: scale(1.1);
            }
        }
    }

    &__country-icon {
        position: static;
        width: rem(108px);
        height: rem(72px);
        max-width: 100%;
        backface-visibility: hidden;
        transform: scale(1);
        transition: transform 300ms ease;
        transform-origin: center;
    }

    &__country-name {
        margin-top: rem(8px);
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: rem(14px);
        line-height: rem(16px);

        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': $light-gray-90,
                ),
            )
        );
    }
}
