@import '@assets/core.style';

@keyframes orbit1 {
    from {
        transform: translateZ(rem(1px)) rotate3d(2, 1, 0, 0deg) rotate(0deg);
    }

    to {
        transform: translateZ(rem(1px)) rotate3d(2, 1, 0, -360deg)
            rotate(720deg);
    }
}

@keyframes orbit2 {
    from {
        transform: translateZ(rem(1px)) rotate3d(-2, 1, 0, 0deg) rotate(0deg);
    }

    to {
        transform: translateZ(rem(1px)) rotate3d(-2, 1, 0, 360deg)
            rotate(720deg);
    }
}

@keyframes orbit3 {
    from {
        transform: translateZ(rem(1px)) rotate3d(0, 1, 0, 0deg) rotate(0deg);
    }

    to {
        transform: translateZ(rem(1px)) rotate3d(0, 1, 0, 360deg) rotate(720deg);
    }
}

@keyframes orbit4 {
    from {
        transform: rotate3d(1, 1, 0, 0deg);
    }

    to {
        transform: rotate3d(1, 4, 0, 180deg);
    }
}

.SpaceSpinner {
    $root: &;

    &__container {
        width: rem(150px);
        height: rem(150px);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;

        @include themes(
            (
                'dark': (
                    'background-color': rgba($dark-gray-2, 0.8),
                ),
                'light': (
                    'background-color': rgba($light-gray-20, 0.8),
                ),
            )
        );

        &--transparent {
            @include themes(
                (
                    'dark': (
                        'background-color': transparent,
                    ),
                    'light': (
                        'background-color': transparent,
                    ),
                )
            );
        }

        &.is-fixed {
            position: fixed;
            z-index: $admin-loader-index;
        }

        &.is-nested {
            z-index: 10;
        }
    }

    &__sphere,
    &__orbit {
        border-radius: 50%;
    }

    &__sphere {
        width: 40%;
        height: 40%;
        background-image: linear-gradient(
            45deg,
            rgba($dark-gray-5, 0.2) 20%,
            rgba($dark-gray-5, 0.5)
        );
        transform: translateZ(rem(1px));
        position: relative;

        #{$root}--white & {
            background-image: linear-gradient(
                45deg,
                rgba(#fff, 0.5) 20%,
                rgba(#fff, 0.8)
            );
        }

        span,
        &:before,
        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 50%;
            border: rem(1px) solid rgba($dark-gray-1, 0.9);
            box-sizing: border-box;
            animation: orbit1 infinite 3s linear;
        }

        &:before {
            animation: orbit2 infinite 3s linear 400ms;
        }

        &:after {
            animation: orbit3 infinite 3s linear 800ms;
        }
    }

    &__orbit {
        position: absolute;
        width: 70%;
        height: 70%;
        border: rem(2px) solid rgba($dark-gray-5, 0.4);

        #{$root}--white & {
            border-color: rgba(#fff, 0.7);
        }
    }

    &__orbit1 {
        animation: orbit1 infinite 3s linear;
    }

    &__orbit2 {
        width: 80%;
        height: 80%;
        animation: orbit2 infinite 3s linear 400ms;
    }

    &__orbit3 {
        width: 75%;
        height: 75%;
        animation: orbit3 infinite 3s linear 800ms;
    }

    //loader for IE
    &__spin {
        &-wrap {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }

        width: rem(140px);
        height: rem(140px);
        border-top: rem(2px) solid #ff0036;
        border-radius: rem(100px);
        position: relative;
        animation: spin 1.7s linear infinite;

        &:after,
        &:before {
            position: absolute;
            content: '';
        }

        &:after {
            width: rem(150px);
            height: rem(150px);
            border-radius: rem(110px);
            top: rem(-7px);
            left: rem(-7px);
            border-right: rem(2px) solid #ffe500;
            animation: spin 3s linear infinite;
        }

        &:before {
            width: rem(130px);
            height: rem(130px);
            border-radius: rem(90px);
            top: rem(3px);
            left: rem(3px);
            border-left: rem(2px) solid #00defa;
            animation: spin 2.5s linear infinite;
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
