@import '@assets/core.style';

.TableHeaderCell {
    position: relative;
    cursor: pointer;
    padding: 0 rem(2px);
    height: 100%;
    display: flex;
    align-items: center;

    &.is-vertical {
        flex-direction: column;
        justify-content: flex-start;
    }

    &__label {
        position: relative;
        padding: 0 rem(2px);
        height: calc(50% - #{rem(5px)});
        margin-top: rem(5px);
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
    }

    &__dd-thumb {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0 rem(2px) rem(5px);
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
    }

    &__dd-thumb-inner {
        position: relative;
        padding: 0 rem(16px);
    }

    &__dd-icon {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%) rotate(-90deg);
        transform-origin: 50% 50%;
        transition: transform 300ms;

        &--opened {
            transform: translateY(-50%) rotate(0deg);
        }

        path {
            @include themes(
                (
                    'light': (
                        'stroke': $light-gray-90,
                    ),
                )
            );
        }
    }

    &__dropdown {
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 10;

        @include themes(
            (
                'dark': (
                    'background-color': $dark-gray-4,
                ),
                'light': (
                    'background-color': $light-gray-0,
                ),
            )
        );
    }

    &__dropdown-item {
        padding: rem(5px);
    }

    &__wr {
        padding-left: rem(13px);
        padding-right: rem(13px);
        height: 100%;
        display: flex;
        justify-content: center;
        width: 100%;
    }

    &__icon {
        position: absolute;
        left: rem(-14px);
        top: 50%;
        margin-top: rem(-1px);
        transform: translateY(-50%);

        path,
        rect {
            @include themes(
                (
                    'light': (
                        'fill': $light-gray-90,
                        'stroke': $light-gray-90,
                    ),
                )
            );
        }
    }
}
