@import '@styles';

.RestaurantsMenuManage {
    $gap: 12px;

    color: #fff;

    &__header {
        height: rem(30px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: rem($gap * 3);
        position: relative;
        z-index: 20;

        &--small-space {
            margin-bottom: rem($gap);
        }
    }

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & + & {
            margin-top: rem($gap * 2);
        }

        &--left {
            justify-content: flex-start;
        }
    }

    &__title {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: rem(18px);
        line-height: 1.4;

        &--small {
            font-size: rem(14px);
            text-transform: uppercase;
        }

        @include themes(
            (
                'dark': (
                    color: #fff,
                ),
                'light': (
                    color: $dark-gray-0,
                ),
            )
        );
    }

    &__backward {
        cursor: pointer;
        display: flex;
        align-items: center;

        &-icon {
            margin-right: rem($gap * 0.5);

            path {
                @include themes(
                    (
                        'dark': (
                            'stroke': #fff,
                        ),
                        'light': (
                            'stroke': $dark-gray-0,
                        ),
                    )
                );
            }
        }

        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': $dark-gray-0,
                ),
            )
        );
    }

    &__name-field {
        width: 50%;
    }

    &__checkbox-group {
        display: flex;

        & > * {
            margin-left: rem($gap);
        }

        &--left {
            margin-right: auto;
        }
    }

    &__restaurants-add {
        min-width: rem(165px);
        height: rem(35px);
        font-size: rem(16px);
        margin-left: rem(32px);
    }

    &__search {
        width: 60%;
        height: rem(40px);
        margin-top: rem(-18px);

        // TODO: add modifier prop to Search
        .search,
        .search__label,
        .search__input {
            height: 100%;
        }
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        margin-top: rem($gap * 2);
    }

    &__table {
        height: calc(100vh - #{rem(350px)});
        max-height: rem(350px);
        width: 100%;

        &--large {
            height: calc(100vh - #{rem(250px)});
            max-height: rem(450px);
        }
    }

    &__filters-info {
        display: flex;
        font-family: 'Nunito', sans-serif;
        font-size: rem(16px);
    }

    &__counter {
        color: $dark-gray-9;
        font-weight: 600;
        margin-right: rem(15px);
        cursor: default;
    }

    &__clear {
        color: $dark-blue-3;
        cursor: pointer;
        user-select: none;

        &:hover {
            @include themes(
                (
                    'dark': (
                        'color': #fff,
                    ),
                    'light': (
                        'color': $dark-gray-0,
                    ),
                )
            );
        }
    }

    &__actions {
        display: flex;
    }
}
