@import '@assets/core.style';

.keyword-settings {
    position: relative;
    width: rem(464px);
    padding: rem(16px);
    border-radius: rem(8px);
    transform: translateY(-50%);
    z-index: 101;

    $root: &;

    @include themes(
        (
            'dark': (
                'background-color': $dark-gray-3,
                'box-shadow': 0 rem(4px) rem(60px) rgba(0, 0, 0, 0.1),
            ),
            'light': (
                'background-color': #fff,
                'box-shadow': 0 rem(8px) rem(20px) rgba(176, 195, 208, 0.24),
            ),
        )
    );

    &__gear {
        display: block;
        width: rem(12px);
        height: rem(12px);
        left: rem(-18px);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
    }

    &__top {
        display: flex;
        flex-direction: column;
    }

    &__top-title {
        font-size: rem(16px);
        line-height: rem(18px);

        @include themes(
            (
                'dark': (
                    'color': #788ba3,
                ),
                'light': (
                    'color': $light-gray-90,
                ),
            )
        );

        span {
            @include themes(
                (
                    'dark': (
                        'color': #fff,
                    ),
                    'light': (
                        'color': $dark-gray-1,
                    ),
                )
            );
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
    }

    &__bot {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        margin-top: rem(8px);
        overflow: hidden;
    }

    &__cancel {
        margin-right: rem(15px);

        @include themes(
            (
                'dark': (
                    color: #788ba3,
                ),
                'light': (
                    color: $light-gray-40,
                ),
            )
        );
    }

    &__apply {
        @include themes(
            (
                'dark': (
                    color: #fff,
                ),
                'light': (
                    color: $light-gray-90,
                ),
            )
        );
    }

    &__cancel,
    &__apply {
        width: auto;
        height: auto;
        background-color: transparent;
        text-transform: uppercase;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: rem(16px);
        line-height: rem(20px);

        &:hover {
            background-color: transparent;

            @include themes(
                (
                    'dark': (
                        color: #fff,
                    ),
                    'light': (
                        color: $light-gray-100,
                    ),
                )
            );
        }
    }

    &__include,
    &__exclude {
        width: 50%;
        padding: rem(8px) 0;
    }

    &__exclude {
        padding-left: rem(8px);
    }

    &__include {
        padding-right: rem(8px);
    }

    &__title {
        padding: rem(6px) 0;
        font-size: rem(14px);
        line-height: rem(18px);
        font-weight: 400;

        @include themes(
            (
                'dark': (
                    color: #fff,
                ),
                'light': (
                    color: $light-gray-90,
                ),
            )
        );

        span {
            margin-left: rem(3px);
        }

        &--green {
            @include themes(
                (
                    'dark': (
                        'color': #27ae60,
                    ),
                )
            );
        }

        &--yellow {
            @include themes(
                (
                    'dark': (
                        'color': #f2c94c,
                    ),
                )
            );
        }

        &--red {
            @include themes(
                (
                    'dark': (
                        'color': #c3272f,
                    ),
                )
            );
        }
    }

    &__input-wrap {
        width: 100%;
        padding-top: rem(8px);
    }
}

.gear-btn {
    $root: &;
    $opacity: 0.2;

    width: rem(40px);
    height: 100%;
    border: solid;
    border-width: 0 rem(1px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;

    $root: &;

    transition: background-color 300ms;

    @include themes(
        (
            'dark': (
                'border-color': $dark-gray-4,
            ),
            'light': (
                'border-color': $light-gray-10,
            ),
        )
    );

    &:disabled {
        pointer-events: none;

        #{$root}__icon {
            opacity: $opacity;
        }
    }

    &:hover {
        @include themes(
            (
                'dark': (
                    'background': $dark-gray-5,
                ),
                'light': (
                    'background-color': $light-gray-10,
                ),
            )
        );
    }

    &__icon {
        width: rem(12px);
        height: rem(12px);
        opacity: $opacity;
        transition: opacity 250ms ease;

        @include themes(
            (
                'dark': (
                    fill: #fff,
                ),
                'light': (
                    fill: $light-gray-40,
                ),
            )
        );

        &--active {
            opacity: 1;
        }
    }
}

.keyword-settings-btn {
    position: absolute;
    right: rem(70px);
    top: 50%;
    padding: rem(2px);
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    width: rem(30px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: background-color 300ms, opacity 150ms ease;
    z-index: 3;

    @include themes(
        (
            'dark': (
                'background': $dark-gray-3,
            ),
            'light': (
                'background-color': $light-gray-10,
            ),
        )
    );

    &:hover {
        @include themes(
            (
                'dark': (
                    'background': $dark-gray-4,
                ),
                'light': (
                    'background-color': $light-gray-10,
                ),
            )
        );
    }

    &__dot {
        width: rem(3px);
        height: rem(3px);
        border-radius: 50%;
        margin-bottom: rem(4px);

        @include themes(
            (
                'dark': (
                    'background-color': #fff,
                ),
                'light': (
                    'background': $light-gray-90,
                ),
            )
        );

        &:last-child {
            margin-bottom: 0;
        }
    }
}
