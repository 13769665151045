@use 'sass:math';
@import '@styles';

input,
textarea {
    background-color: transparent;
    border: none;
    outline: none;
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: rem(32px);
    padding: rem(8px);
    border-radius: rem(48px);
    font-size: rem(14px);
    line-height: rem(16px);
    cursor: pointer;
    transition: background-color 300ms, color 300ms;
    user-select: none;
    color: #fff;

    &:hover {
        color: #4794ff;
    }

    &.btn--filled {
        min-width: rem(136px);
        background-color: #4794ff;

        &:hover {
            background-color: #25b7a5;
            color: #fff;
        }
    }

    &.btn--bordered {
        background-color: transparent;
        padding: rem(8px) rem(16px);
        border: rem(1px) solid;

        @include themes(
            (
                'dark': (
                    'border-color': #fff,
                ),
                'light': (
                    'border-color': $dark-gray-0,
                    'color': $dark-gray-0,
                ),
            )
        );

        &:hover {
            @include themes(
                (
                    'dark': (
                        'background-color': #fff,
                        'color': #131921,
                    ),
                    'light': (
                        'background-color': $dark-gray-0,
                        'color': #fff,
                    ),
                )
            );
        }
    }

    &.btn--round {
        min-width: auto;
        width: rem(32px);
        border-radius: 50%;
    }
}

/* TODO: remove `.admin-tool` in edit/add page */
.admin-tool {
    padding: rem(80px) 0 0;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: rem(16px);
    line-height: rem(24px);
    letter-spacing: 0.012em;

    $root: &;

    &__error {
        position: absolute;
        font-size: rem(12px);
        color: #f55353;
        left: 0;
        bottom: rem(-37px);
    }

    &__content {
        padding: 0 rem(16px);
    }

    &__wrapper {
        width: calc(100% - #{math.percentage(math.div(409, 1920))});
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        height: calc(100vh - rem(80px));
    }

    &__top-title {
        width: 100%;
        height: rem(80px);
        padding: rem(8px) rem(24px);

        @include themes(
            (
                'dark': (
                    'background-color': #232d3b,
                ),
                'light': (
                    'background-color': $light-gray-10,
                ),
            )
        );
    }

    &__title {
        padding: rem(16px) 0;
        font-family: 'Poppins', sans-serif;
        font-size: rem(24px);
        line-height: rem(32px);

        @include themes(
            (
                'dark': (
                    'color': #fff,
                ),
                'light': (
                    'color': $dark-gray-0,
                ),
            )
        );
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }

    &__input-wrap {
        display: block;
        position: relative;
    }

    &__input {
        display: flex;
        align-items: center;
        width: rem(376px);
        padding: 0 0 rem(8px);
        font-size: rem(16px);
        line-height: rem(24px);
        border-bottom: rem(1px) solid;

        @include themes(
            (
                'dark': (
                    'color': #fff,
                    'border-color': #4a6282,
                ),
                'light': (
                    'color': $dark-gray-0,
                    'border-color': $light-gray-20,
                ),
            )
        );

        &:-internal-autofill-selected {
            box-shadow: inset 0 0 0 rem(200px) #fff;
        }
    }

    &__input-label {
        display: block;
        font-size: rem(16px);
        line-height: rem(16px);
        color: #788ba3;
        pointer-events: none;
    }

    &__btns {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn {
            margin-left: rem(32px);

            &--link {
                @include themes(
                    (
                        'dark': (
                            'color': #fff,
                        ),
                        'light': (
                            'color': $dark-gray-0,
                        ),
                    )
                );

                &:hover {
                    color: $dark-blue-1;
                }
            }
        }
    }

    &__sidebar {
        min-width: rem(328px);
        width: math.percentage(math.div(409, 1920));
        height: 100%;
    }

    &__btn--disabled {
        pointer-events: none;
        @include themes(
            (
                'dark': (
                    'background': #283547!important,
                    'color': #4a6282,
                ),
                'light': (
                    'background': rgba($dark-blue-1, 0.5),
                ),
            )
        );
    }
}

.checkbox {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 rem(44px) 0 0;
    user-select: none;

    $root: &;

    &--select-all {
        #{$root}__fake {
            border-color: #fff;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: rem(8px);
                height: rem(8px);
                margin: rem(3px);
                background-color: #4794ff;
                border-radius: rem(2px);
            }
        }
    }

    &__fake {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: rem(16px);
        height: rem(16px);
        border: rem(1px) solid #4a6282;
        border-radius: rem(2px);
        text-align: center;
        transition: background-color 300ms, border-color 300ms;
        z-index: 1;

        &:after {
            content: '\2713';
            position: absolute;
            top: 0;
            left: 0;
            line-height: rem(16px);
            opacity: 0;
            color: #fff;
            transition: opacity 300ms;
        }
    }

    &__real {
        position: absolute;
        top: rem(4px);
        left: 0;
        opacity: 0;
        z-index: 2;

        &:checked {
            + #{$root}__fake {
                background-color: #4794ff;
                border-color: #4794ff;

                &:after {
                    opacity: 1;
                    z-index: 1;
                }

                + #{$root}__label {
                    color: #fff;
                }
            }
        }
    }

    &__label {
        margin-left: rem(8px);
        transition: color 300ms;
    }
}

.radio-btn {
    position: relative;
    display: flex;
    align-items: center;
    margin: rem(4px);
    user-select: none;
    cursor: pointer;

    $root: &;

    &__fake {
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(16px);
        height: rem(16px);
        border: rem(1px) solid #4a6282;
        border-radius: rem(16px);
        text-align: center;
        transition: border-color 300ms;
        z-index: 1;

        &:after {
            content: '';
            display: inline-block;
            vertical-align: top;
            width: rem(8px);
            height: rem(8px);
            background-color: #4794ff;
            border-radius: 50%;
            opacity: 0;
            transition: opacity 300ms;
        }
    }

    &__real {
        position: absolute;
        top: rem(4px);
        left: 0;
        opacity: 0;
        z-index: 2;

        &:checked {
            background-color: #283547;

            + #{$root}__fake {
                border-color: #4794ff;

                &:after {
                    opacity: 1;
                }
                + #{$root}__label {
                    color: #fff;
                }
            }
        }
    }

    &__label {
        margin-left: rem(12px);
        color: #788ba3;
    }
}

.row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.col-50 {
    width: 50%;
    padding-left: rem(24px);

    &:first-child {
        padding-left: 0;
    }
}
